import {action, computed, makeObservable, observable} from 'mobx';
import {themes, ThemeType} from '#config';

class Theme {
  private _current: ThemeType = themes.light;

  public constructor() {
    makeObservable<typeof this, '_current'>(this, {
      _current: observable.ref,
      current: computed,
      setTheme: action.bound,
    });
  }

  public get current() {
    return this._current;
  }

  public setTheme(theme: ThemeType) {
    this._current = theme;
  }
}

export const theme = new Theme();

(globalThis as any).theme = theme;