// noinspection ES6PreferShortImport

import {RouteBase} from '#includes/RouteBase';

export enum Route {
  Home = 1,

  SignUp,
  SignIn,
  SignOut,

  AdminFiles,
  AdminUsers,

  AuthorLevels,
  AuthorSubjects,
  AuthorSections,
  AuthorTopics,
  AuthorParagraphs,
  AuthorParagraph,
  AuthorViewer,

  StudentLevels,
  StudentParagraph,

  NotFound,
}

export const routes = {
  [Route.Home]: new RouteBase('/'),

  [Route.SignUp]: new RouteBase('/sign-up'),
  [Route.SignIn]: new RouteBase('/sign-in'),
  [Route.SignOut]: new RouteBase('/sign-out'),

  [Route.AdminFiles]: new RouteBase('/admin/files'),
  [Route.AdminUsers]: new RouteBase('/admin/users'),

  [Route.AuthorLevels]: new RouteBase('/author/levels'),
  [Route.AuthorSubjects]: new RouteBase('/author/levels/:levelId', ['levelId']),
  [Route.AuthorSections]: new RouteBase('/author/subjects/:subjectId', ['subjectId']),
  [Route.AuthorTopics]: new RouteBase('/author/sections/:sectionId', ['sectionId']),
  [Route.AuthorParagraphs]: new RouteBase('/author/topics/:topicId', ['topicId']),
  [Route.AuthorParagraph]: new RouteBase('/author/paragraphs/:paragraphId', ['paragraphId']),
  [Route.AuthorViewer]: new RouteBase('/author/paragraphs/:paragraphId/viewer', ['paragraphId']),

  [Route.StudentLevels]: new RouteBase('/levels'),
  [Route.StudentParagraph]: new RouteBase('/paragraphs/:paragraphId', ['paragraphId']),

  [Route.NotFound]: new RouteBase('/*'),
} as const;

// noinspection JSUnusedLocalSymbols
const security: Record<Route, RouteBase<string, any>> = routes;

(globalThis as any).routes = routes;