import {BaseView, FormView} from '#includes/content/editor/Views';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';

export abstract class BaseForm<
  Children extends BaseView[],
  Data extends any,
> extends BaseView<FormView<Children>['ckView']> {
  protected readonly form: FormView<Children>;
  public readonly setSubmitHandler;
  public readonly setCancelHandler;
  public readonly focus;

  protected constructor(
    locale: Locale,
    className: Keyword,
    cancelCallback: () => void,
  ) {
    const form = FormView.create<Children>(
      locale,
      className,
      cancelCallback,
    );

    super(locale, form.ckView);

    this.form = form;
    this.setSubmitHandler = this.form.setSubmitHandler.bind(this.form);
    this.setCancelHandler = this.form.setCancelHandler.bind(this.form);
    this.focus = this.form.focusFirstInput.bind(this.form);
  }

  public abstract getData(): Data;

  public setData(data: Data): void {
    this.form.updateFocusTracker();
  }
}