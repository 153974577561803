import {css} from '@emotion/react';
import {Button, Container, Link, List, ListProps} from '#components/atoms';
import {palette} from '#config';
import {observer} from 'mobx-react-lite';
import {Fragment, useMemo} from 'react';
import {theme} from '#store';
import {getEm} from '#includes/utils';

export type NamedEntityTreeType = {
  id: number,
  name: string,
  child?: NamedEntityTreeType[],
};

type EntityTreePropsMin<Entity extends NamedEntityTreeType> = {
  depth?: number,
  list: Entity[],
  urlFactory?: (depth: number, id: number) => string | undefined,
  onClick?: (depth: number, id: number) => void,
};

type EntityTreeProps<Entity extends NamedEntityTreeType> =
  Omit<ListProps, keyof EntityTreePropsMin<Entity> | 'children'>
  & EntityTreePropsMin<Entity>;

const styles = {
  base: () => css({
    alignItems: 'flex-start',
    width: '100%',
  }),
  child: () => css({
    padding: `${getEm(7)} ${getEm(3)} ${getEm(7)} ${getEm(20)} `,
  }),
  childContainer: () => css({
    // '&:hover': {
    //   boxShadow: '0 0 200px #0002 inset',
    // }
  }),
  item: () => css({
    color: palette.black,
    width: '100%',
  }),
};

export const NamedEntityTree = observer(<Entity extends NamedEntityTreeType>(
  props: EntityTreeProps<Entity>,
) => {
  const {
    depth = 0,
    list,
    urlFactory,
    onClick,
    ...otherProps
  } = props;

  type NormalizedEntity = NamedEntityTreeType & {
    link?: string,
    clickHandler?: () => void,
  };
  const entities = useMemo<NormalizedEntity[]>(() => {
    return list.map(entity => {
      const isVoidChild = entity.child && entity.child.length === 0;

      return {
        ...entity,
        child: isVoidChild ? undefined : entity.child,
        link: isVoidChild ? undefined : (urlFactory && urlFactory(depth, entity.id)),
        clickHandler: isVoidChild ? undefined : onClick && (() => onClick(depth, entity.id)),
      };
    });
  }, [urlFactory, depth, list]);

  return (
    <List css={styles.base()} {...otherProps}>
      {entities.map(({id, name, child, link, clickHandler}) => (
        <Fragment key={id}>
          {child ? (
            <>
              <Container
                css={[styles.item(), styles.childContainer()]}
                printing={'outlined'}
                color={'lightGray'}
              >
                {name}
                <NamedEntityTree
                  css={styles.child()}
                  depth={depth + 1}
                  list={child}
                  urlFactory={urlFactory}
                  onClick={onClick}
                />
              </Container>
            </>
          ) : link ? (
            <Link
              css={styles.item()}
              printing={'outlined'}
              color={theme.current.baseColor}
              to={link}
              onClick={clickHandler}
            >
              {name}
            </Link>
          ) : clickHandler ? (
            <Button
              css={styles.item()}
              printing={'outlined'}
              onClick={clickHandler}
            >
              {name}
            </Button>
          ) : (
            <Container
              css={styles.item()}
              printing={'outlined'}
              color={'lightGray'}
            >
              {name}
            </Container>
          )}
        </Fragment>
      ))}
    </List>
  );
});