import {StyleBase} from '#includes/content/editor/plugins/utils';
import {css} from '@emotion/react';

export const Style: StyleBase = {
  general: css`

  `,
  viewer: css`

  `,
  editor: css`

  `,
};