import {modelConverter} from '#includes/content';
import {Keyword} from '#includes/content/editor/Keyword';
import {ParagraphData} from '#global';
import {hash32} from '#includes/hash32';
import {stringsConverters} from './stringsConverters';
import {modelClasses} from '#includes/content/editor/plugins';
import {ConverterError} from '#includes/error';

export function clearContent(
  editorContent: NonNullable<ParagraphData['content']>,
): NonNullable<ParagraphData['content']> | Error {
  const clearedStringsContent = stringsConverters.reduce(
    (content, converter) => converter.clearEditorContent(content),
    editorContent,
  );

  const contentBody = new DOMParser()
    .parseFromString(clearedStringsContent, 'text/html')
    .body;

  const metaElements = contentBody.getElementsByClassName(Keyword.metaClass);
  for (const metaElement of metaElements) {
    const helpElement = metaElement.firstChild;
    if (!(helpElement instanceof HTMLDivElement)) return new ConverterError(
      clearContent.name,
      'helpElement не является Div',
      {helpElement},
    );

    helpElement.innerHTML = String(hash32(helpElement.innerHTML));

    const tagsElement = metaElement.lastChild;
    if (!(tagsElement instanceof HTMLDivElement)) return new ConverterError(
      clearContent.name,
      'tagsElement не является Div',
      {tagsElement},
    );

    tagsElement.innerHTML = String(hash32(tagsElement.innerHTML));
  }

  for (const [modelName, className] of modelClasses) {
    const containers = contentBody.getElementsByClassName(className);
    for (const container of containers) {
      const data = modelConverter[modelName].toData(container);
      if (data instanceof Error) return data;
    }
  }

  return contentBody.innerHTML;
}