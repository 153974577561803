import {Plugin} from '@ckeditor/ckeditor5-core';
import {toWidget, viewToModelPositionOutsideModelElement, Widget} from '@ckeditor/ckeditor5-widget';
import {DowncastWriter, ViewContainerElement, ViewElement} from '@ckeditor/ckeditor5-engine';
import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {isProduction, urls} from '#config';

export class Editing extends Plugin {
  public static get requires() {
    return [Widget];
  }

  public init() {
    this.defineSchema();
    this.defineConverters();
    this.defineChildCheck();

    this.editor.commands.add(
      Keyword.audioCommand,
      new Command(this.editor),
    );

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(
        this.editor.model,
        viewElement => {
          return viewElement.hasClass(Keyword.audioClass);
        },
      ),
    );
  }

  private defineChildCheck(): void {
    // const schema = this.editor.model.schema;
    //
    // schema.addChildCheck((context, childDefinition) => {
    //   if (childDefinition.name !== Keyword.audioModel) return;
    //
    //   return;
    // });
  }

  private defineSchema() {
    const schema = this.editor.model.schema;

    schema.register(Keyword.audioModel, {
      inheritAllFrom: '$blockObject',
      allowAttributes: ['data'],
    });
  }

  private defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'div',
        classes: [Keyword.audioClass],
      },
      model: (element, {writer}) => {
        const audio = element.getChild(0);
        if (!(audio instanceof ViewElement)) return null;

        const src = audio.getAttribute('src') || '';
        const data = src
          .replace(/.*media\/(.+)$/, '$1');

        return writer.createElement(Keyword.audioModel, {data});
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: Keyword.audioModel,
      view: (element, {writer}) => {
        const data = element.getAttribute('data');
        if (typeof data !== 'string') return null;

        const container = this.createContainer(writer, data);

        container._setAttribute('ondblclick', 'Ck_Audio_Ui.showUI(this)');

        return toWidget(container, writer);
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: Keyword.audioModel,
      view: (element, {writer}) => {
        const data = element.getAttribute('data');
        if (typeof data !== 'string') return null;

        return this.createContainer(writer, data);
      },
    });
  }

  private createContainer(
    writer: DowncastWriter,
    data: string,
  ): ViewContainerElement {
    return writer.createContainerElement('div', {
      class: Keyword.audioClass,
    }, [
      writer.createContainerElement('audio', {
        src: `${isProduction ? '' : urls.backend}/media/${data}`,
        controls: 'controls',
      }),
    ], {
      renderUnsafeAttributes: ['ondblclick'],
    });
  }
}