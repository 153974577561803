import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Link, List, Space, TextBox} from '#components/atoms';
import {loader, notifications} from '#store';
import {css} from '@emotion/react';
import {Main, SignForm} from '#components/molecules';
import {palette, Route, routes} from '#config';
import {useNavigate} from 'react-router-dom';
import {useAxios} from '#hooks';
import {ApiRoute} from '#includes/ApiRoute';
import {getEm} from '#includes/utils';

const styles = {
  base: () => css({
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  input100: () => css({
    width: '100%',
  }),
  already: () => css({
    color: palette.gray.dark,
    fontSize: getEm(14),
    marginTop: getEm(20),
  }),
  alreadyLink: () => css({
    display: 'inline',
    color: palette.blue.base,
  }),
};

export const SignUp = observer(() => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [patronymic, setPatronymic] = useState<string>('');

  const apiSignUp = useAxios(ApiRoute.signUp, {
    success: () => {
      notifications.addInfo('Вы успешно зарегистрировались!');
      navigate(routes[Route.SignIn].createUrl());
    },
    fail: error => notifications.addError(error),
  });

  const submitHandler = async () => {
    loader.add('SignUp');
    await apiSignUp.request(null, {
      lastName: lastName,
      firstName: firstName,
      patronymic: patronymic ? patronymic : undefined,
      email: email,
      password: password,
    });
    loader.remove('SignUp');
  };

  return (
    <Main css={styles.base()} view={{header: false, footer: false, autoWidth: true}}>
      <SignForm
        submit={'Зарегистрироваться'}
        disabled={apiSignUp.isLoading}
        onSubmit={submitHandler}
      >
        <List>
          <List horizontal={true}>
            <TextBox
              label={'Фамилия'}
              value={lastName}
              onInput={e => setLastName(e.currentTarget.value)}
            />
            <TextBox
              label={'Имя'}
              value={firstName}
              onInput={e => setFirstName(e.currentTarget.value)}
            />
            <TextBox
              label={'Отчество'}
              value={patronymic}
              onInput={e => setPatronymic(e.currentTarget.value)}
            />
          </List>
          <TextBox
            css={styles.input100()}
            label={'Email'}
            type={'email'}
            value={email}
            onInput={e => setEmail(e.currentTarget.value)}
          />
          <TextBox
            css={styles.input100()}
            label={'Пароль'}
            type={'password'}
            autocomplete={'new-password'}
            value={password}
            onInput={e => setPassword(e.currentTarget.value)}
          />
        </List>
      </SignForm>
      <div css={styles.already()}>
        Уже есть аккаунт?
        <Space/>
        <Link css={styles.alreadyLink()} printing={'custom'} to={Route.SignIn}>
          Войти
        </Link>
      </div>
    </Main>
  );
});