import {
  forwardRef,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {css} from '@emotion/react';
import {Button, FlexSeparator} from '#components/atoms';
import {getEm} from '#includes/utils';
import {observer} from 'mobx-react-lite';

type BookPropsMin = {
  children: ReactElement | ReactElement[],
  onPageChange?: (page: number, lastPage: number) => void,
};

type BookProps =
  Omit<HTMLAttributes<HTMLElement>, keyof BookPropsMin>
  & BookPropsMin;

const styles = {
  base: () => css({
    display: 'flex',
    flexDirection: 'column',
  }),
  pages: (currentPage: number) => css({
    flexGrow: 1,
    [`& > *:not(:nth-of-type(${currentPage + 1}))`]: {
      display: 'none',
    },
  }),
  buttons: () => css({
    marginTop: getEm(20),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

export const Book = observer<BookProps, HTMLDivElement>(forwardRef((props, ref) => {
  const {
    children,
    onPageChange,
    ...otherProps
  } = props;

  const [page, setPage] = useState<number>(0);

  const pages = useMemo(
    () => Array.isArray(children) ? children : [children],
    [children],
  );

  const lastPage = useMemo(() => pages.length - 1, [pages]);

  const nextHandler = useCallback(() => {
    window.scrollTo(window.scrollX, 0);
    setPage(page => page === lastPage ? 0 : page + 1);
  }, []);

  const prevHandler = useCallback(() => {
    window.scrollTo(window.scrollX, 0);
    setPage(page => page - 1);
  }, []);

  useEffect(() => {
    onPageChange && onPageChange(page, lastPage);
  }, [page, lastPage]);

  return (
    <div css={styles.base()} ref={ref} {...otherProps}>
      <div css={styles.pages(page)}>
        {pages}
      </div>
      <div css={styles.buttons()}>
        {page === 0 ? (
          <FlexSeparator/>
        ) : (
          <Button onClick={prevHandler}>
            Назад
          </Button>
        )}
        <Button onClick={nextHandler}>
          {page === lastPage ? 'Начало' : 'Дальше'}
        </Button>
      </div>
    </div>
  );
}));
