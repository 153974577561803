import type {Locale} from '@ckeditor/ckeditor5-utils';
import {InputView, ItemView, ListView, SwitchView} from '#includes/content/editor/Views';
import {Keyword} from '#includes/content/editor/Keyword';
import {SwitchDatum} from './SwitchDatum';

type ItemChildren = [InputView, SwitchView];

export class SwitchListView extends ListView<ItemChildren, SwitchDatum> {
  private readonly switchHandler: (
    item: ItemView<ItemChildren>,
    enabled: boolean,
  ) => void;

  private constructor(
    locale: Locale,
    switchHandler: (
      item: ItemView<ItemChildren>,
      enabled: boolean,
    ) => void,
    className?: string | string[],
  ) {
    super(locale, className);

    this.switchHandler = switchHandler;
  }

  public static create(
    locale: Locale,
    switchHandler: (
      item: ItemView<ItemChildren>,
      enabled: boolean,
    ) => void,
    className?: string | string[],
  ) {
    return new SwitchListView(
      locale,
      switchHandler,
      className,
    );
  }

  //

  protected itemChildrenCreation(
    item: ItemView<ItemChildren>,
    datum?: SwitchDatum,
  ): ItemChildren {
    const inputView = InputView.create(
      this.locale,
      [],
      Keyword.formInputLabel,
    );
    datum && inputView.setValue(datum.value);

    const switchView = SwitchView.create(
      this.locale,
      [],
      Keyword.formIsCorrectLabel,
    );
    datum && switchView.setEnabled(datum.enabled);
    switchView.setHandler(() => {
      this.switchHandler(item, !switchView.getEnabled());
    });

    return [
      inputView,
      switchView,
    ];
  }

  protected checkEmpty(): void {
    const length = this.getChildrenLength();
    if (length === 0) return void this.setItems([new SwitchDatum()]);
    if (length === 1) return void this.addChild(this.createItem(new SwitchDatum()));
  }
}