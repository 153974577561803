import type {Locale} from '@ckeditor/ckeditor5-utils';
import {submitHandler} from '@ckeditor/ckeditor5-ui';
import {Keyword} from '#includes/content/editor/Keyword';
import type {BaseView} from '#includes/content/editor/Views';
import {ContainerBaseView} from '../_ContainerBase';
import {RenderedCkView} from './_RenderedCkView';

export class FormBaseView<
  Children extends BaseView[]
> extends ContainerBaseView<'form', RenderedCkView<HTMLFormElement>, Children> {
  public readonly setRenderHandler;
  public readonly setDestroyHandler;

  private constructor(locale: Locale, className: string | string[]) {
    super(
      locale,
      'form',
      new RenderedCkView<HTMLFormElement>(locale),
      [Keyword.formClass, className].flat(),
    );

    this.setRenderHandler = this.ckView.addRenderHandler.bind(this.ckView);
    this.setDestroyHandler = this.ckView.addDestroyHandler.bind(this.ckView);

    this.ckView.addRenderHandler(() => {
      submitHandler({
        view: this.ckView,
      });
    });
  }

  public static create<
    Children extends BaseView[],
  >(
    locale: Locale,
    className: string | string[],
  ): FormBaseView<Children> {
    return new FormBaseView<Children>(
      locale,
      className,
    );
  }
}