import {toWidget, viewToModelPositionOutsideModelElement, Widget} from '@ckeditor/ckeditor5-widget';
import {Plugin} from '@ckeditor/ckeditor5-core';
import {ViewElement, ViewNode, ViewText} from '@ckeditor/ckeditor5-engine';
import {modelConverter} from '#includes/content';
import {Keyword} from '#includes/content/editor/Keyword';
import DowncastHelpers from '@ckeditor/ckeditor5-engine/src/conversion/downcasthelpers';
import UpcastHelpers from '@ckeditor/ckeditor5-engine/src/conversion/upcasthelpers';
import {
  SchemaCompiledItemDefinition,
  SchemaContext,
} from '@ckeditor/ckeditor5-engine/src/model/schema';
import {Mode, TextOrFileDatum} from '#includes/content/editor/Views';
import {AnswerModelName} from '#global';
import ModelElement from '@ckeditor/ckeditor5-engine/src/model/element';
import {DowncastConversionApi} from '@ckeditor/ckeditor5-engine/src/conversion/downcastdispatcher';
import {CommandBase, NodeName} from '#includes/content/editor/plugins/utils';
import {ConverterError} from '#includes/error';

type Downcast = Omit<DowncastHelpers, 'elementToElement'> & {
  elementToElement: (config: {
    model: AnswerModelName,
    view: (
      element: ModelElement,
      conversionApi: DowncastConversionApi,
    ) => ViewElement | null
  }) => DowncastHelpers
};

type CommandsMap = {
  [name in Keyword]?: ClassByInstance<CommandBase>
};

export abstract class EditingBase<Data> extends Plugin {
  public static get requires() {
    return [Widget];
  }

  protected abstract readonly modelName: AnswerModelName;
  protected abstract readonly modelElement: NodeName;
  protected abstract readonly isInline: boolean;
  protected abstract readonly modelClass: Keyword;
  protected abstract readonly commandsMap: CommandsMap;
  protected abstract readonly uiName: string;

  protected abstract childCheck(
    context: SchemaContext,
    definition: SchemaCompiledItemDefinition,
  ): false | void;

  protected abstract getIsEmpty(
    data: Data,
  ): boolean;

  public init() {
    const editor = this.editor;
    const conversion = this.editor.conversion;

    this.defineSchema();
    this.upcast(conversion.for('upcast'));
    this.downcast(conversion.for('editingDowncast'), true);
    this.downcast(conversion.for('dataDowncast'), false);
    editor.model.schema.addChildCheck(this.childCheck.bind(this));
    this.defineCommands();
    this.defineEditingMapper();
  }

  //

  protected childCheckInExercise(
    context: SchemaContext,
    definition: SchemaCompiledItemDefinition,
  ): false | void {
    if (definition.name !== this.modelName) return;

    const length = context.length;
    if (length <= 2) return false;

    for (let i = 2; i < length; i++)
      if (context.getItem(i).name === Keyword.panelModel)
        return;

    return false;
  }

  protected getInnerText(
    node: ViewElement,
  ): string | null {
    if (node.childCount !== 1) return null;

    const text = node.getChild(0);
    if (!(text instanceof ViewText)) return null;

    return text.data;
  }

  protected getNestedTexts(
    node: ViewElement,
    nestedElementsName: string,
  ): string[] | null {
    const answers: string[] = [];

    const nodes = node.getChildren();
    for (const node of nodes) {
      if (!(node instanceof ViewElement) || node.name !== nestedElementsName) return null;

      const string = this.getInnerText(node);
      if (string === null) return null;

      answers.push(string);
    }

    return answers;
  }

  protected getInnerTextOrFileData(
    element: ViewNode,
  ): TextOrFileDatum[] | null {
    if (!(element instanceof ViewElement) || element.name !== 'div') return null;

    const values: TextOrFileDatum[] = [];

    const spans = element.getChildren();
    for (const span of spans) {
      if (!(span instanceof ViewElement) || span.name !== 'span') return null;

      const value = this.getInnerText(span);
      if (value === null) return null;

      const isFile = span.hasClass(Keyword.fileModeClass);
      const mode = isFile ? Mode.file : Mode.text;
      value && values.push(new TextOrFileDatum(mode, value));
    }

    return values.length === 0 ? null : values;
  }

  //

  private defineSchema(): void {
    this.editor.model.schema.register(this.modelName, {
      inheritAllFrom: this.isInline ? '$inlineObject' : '$blockObject',
      allowAttributes: ['data'],
    });
  }

  private upcast(
    upcast: UpcastHelpers,
  ): void {
    upcast.elementToElement({
      view: {
        name: this.modelElement,
        classes: [this.modelClass],
      },
      model: (editor, {writer}) => {
        const data = modelConverter[this.modelName].toModelData(editor);
        if (data instanceof Error) throw data;

        return writer.createElement(this.modelName, {
          'data': data,
        });
      },
    });
  }

  private downcast(
    downcast: Downcast,
    isEditor: boolean,
  ): void {
    downcast.elementToElement({
      model: this.modelName,
      view: (element, {writer}) => {
        const data = element.getAttribute('data') as Data | undefined;
        if (!data || this.getIsEmpty(data)) throw new ConverterError(
          `EditingBase.Downcast`,
          'У element атрибут data пустой',
          {model: this.modelName, data},
        );

        const model = modelConverter[this.modelName].toModel(writer, data as any, isEditor);
        if (model instanceof Error) throw model;

        const container = writer.createContainerElement(
          this.modelElement,
          model.attributes,
          model.children,
          {renderUnsafeAttributes: isEditor ? ['ondblclick'] : undefined},
        );
        if (!isEditor) return container;

        container._setAttribute('ondblclick', `${this.uiName}.showUI(this)`);

        return toWidget(container, writer);
      },
    });
  }

  private defineCommands(): void {
    const editor = this.editor;

    Object.entries(this.commandsMap).forEach(
      ([command, Command]) => {
        editor.commands.add(
          command,
          new Command(editor),
        );
      },
    );
  }

  private defineEditingMapper(): void {
    const editor = this.editor;

    editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(
        editor.model,
        view => view.hasClass(this.modelClass),
      ),
    );
  }
}