import {HttpMethod, ListData, UserArgs, UserData, UserEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_UsersRoute = {
  signUp: new g_Route<null, UserData, UserArgs>(
    HttpMethod.post,
    'users/register',
    'Зарегистрироваться',
  ),
  signUpAnotherMany: new g_Route<null, ListData<UserData>, UserArgs[]>(
    HttpMethod.post,
    'users/register/many',
    'Зарегистрировать пользователей',
  ),
  createUser: new g_Route<null, UserData, UserArgs>(
    HttpMethod.post,
    'users',
    'Добавить пользователя',
  ),
  createUsers: new g_Route<null, ListData<UserData>, UserArgs[]>(
    HttpMethod.post,
    'users/many',
    'Добавить пользователей',
  ),
  getUsers: new g_Route<null, ListData<UserData>>(
    HttpMethod.get,
    'users',
    'Получить все пользователей',
  ),
  getUsersMany: new g_Route<null, ListData<UserData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'users/many',
    'Получить пользователей',
  ),
  getUser: new g_Route<{ id: number }, UserData>(
    HttpMethod.get,
    'users/:id',
    'Получить пользователя',
  ),
  editUser: new g_Route<{ id: number }, UserData, Partial<UserArgs>>(
    HttpMethod.put,
    'users/:id',
    'Изменить пользователя',
  ),
  editUsers: new g_Route<null, ListData<UserData>, UserEditionArgs[]>(
    HttpMethod.put,
    'users/many',
    'Изменить пользователей',
  ),
  deleteUser: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'users/:id',
    'Удалить пользователя',
  ),
  deleteUsers: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'users/many',
    'Удалить пользователей',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_UsersRoute;