import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import ReactPlayer, {ReactPlayerProps} from 'react-player';
import {borders} from '#config';

type VideoPropsMin = Readonly<{}>;

export type VideoProps = Readonly<
  Omit<ReactPlayerProps, keyof VideoPropsMin>
  & VideoPropsMin
>;

const styles = {
  base: () => css({
    borderRadius: borders.radius.small,
    overflow: 'hidden',
    backgroundColor: 'black',
  }),
};

export const Video = observer<VideoProps>(props => {
  const {
    ...otherProps
  } = props;

  return (
    <ReactPlayer
      css={styles.base()}
      controls={true}
      width={'100%'}
      height={'auto'}
      {...otherProps}
    />
  );
});