import {AnswerModelName} from '#global';
import {CommandBase} from '#includes/content/editor/plugins/utils';

export class Command extends CommandBase {
  public override execute(data: string) {
    if (!data) return;

    const model = this.editor.model;

    model.change(writer => {
      const element = writer.createElement(AnswerModelName.textModel, {
        'data': data,
      });

      const {end: positionAfter} = model.insertObject(
        element,
        null,
        null,
        {setSelection: 'on'},
      );

      writer.setSelection(positionAfter);
    });
  }

  public override refresh() {
    this.isEnabled = this.getIsEnabled();
  }

  private getIsEnabled(): boolean {
    const model = this.editor.model;
    const selection = model.document.selection;
    const position = selection.getFirstPosition();
    const allowedIn = position && model.schema.findAllowedParent(
      position,
      AnswerModelName.textModel,
    );

    return allowedIn !== null;
  }
}