import {css} from '@emotion/react';
import {theme} from '#store';
import {getEm} from '#includes/utils';
import {observer} from 'mobx-react-lite';
import {forwardRef, HTMLAttributes} from 'react';
import {borders} from '#config';
import {Container, ContainerProps} from '#components/atoms';

type TextBoxPropsMin = {
  label?: string,
  autocomplete?: string,
  value?: string,
  type?: 'text' | 'email' | 'password',
} & Pick<HTMLAttributes<HTMLInputElement>, 'onInput' | 'onChange'>;

export type TextBoxProps =
  Omit<ContainerProps, keyof TextBoxPropsMin | 'checked' | 'children'>
  & TextBoxPropsMin;

const styles = {
  base: () => css({
    position: 'relative',
    // display: 'block',
    // color: theme.current.InputText.color[0],
  }),
  label: () => css({
    fontSize: getEm(12),
    fontWeight: 'bold',
  }),
  input: () => css({
    padding: `${getEm(4)} ${getEm(10)}`,
    display: 'block',
    width: '100%',
    backgroundColor: theme.current.InputText.background[0],
    borderStyle: 'solid',
    borderWidth: borders.width.small,
    borderColor: theme.current.InputText.stroke[0],
    borderRadius: borders.radius.small,
  }),
};

export const TextBox = observer<TextBoxProps, HTMLInputElement>(forwardRef((props, ref) => {
  const {
    label,
    type = 'text',
    printing = 'custom',
    autocomplete,
    value,
    onInput,
    ...otherProps
  } = props;

  return (
    <Container element={'label'} css={styles.base()} {...otherProps}>
      {label && (
        <span css={styles.label()}>
          {label}
        </span>
      )}
      <input
        css={styles.input()}
        type={type}
        value={value}
        autoComplete={autocomplete}
        onInput={onInput}
        ref={ref}
      />
    </Container>
  );
}));