import {SuperError} from './SuperError';

export class ConverterError extends SuperError {
  public constructor(
    sender: string,
    message: string,
    info?: Record<string, any>,
  ) {
    super(sender, message, false);

    this.log(false);
    if (info) {
      Object.entries(info).forEach(([key, value]) => {
        console.group(key);
        if (Array.isArray(value) && value[0] instanceof Element) {
          value.forEach((value, index) => {
            console.group(`[${index}]`);
            console.log(value);
            console.groupEnd();
          });
        } else {
          console.log(value);
        }
        console.groupEnd();
      });
    }
    this.logEnd();
  }
}