import {
  AnswerData,
  ExerciseArgs,
  ExerciseData,
  ExerciseEditionArgs,
  ExerciseMetaData,
  HttpMethod,
  ListData,
} from '../../';
import {g_Route} from '../../utils';

export const g_ExercisesRoute = {
  getExerciseHelp: new g_Route<{ id: number }, { help: ExerciseMetaData['help'] }>(
    HttpMethod.get,
    'exercises/:id/help',
    'Получить подсказку к упражнению',
  ),
  checkExercise: new g_Route<{ id: number }, { correct: true }, AnswerData[]>(
    HttpMethod.post,
    'exercises/:id/check',
    'Проверить ответы упражнения',
  ),
  createExercise: new g_Route<null, ExerciseData, ExerciseArgs>(
    HttpMethod.post,
    'exercises',
    'Добавить упражнение',
  ),
  createExercises: new g_Route<null, ListData<ExerciseData>, ExerciseArgs[]>(
    HttpMethod.post,
    'exercises/many',
    'Добавить упражнения',
  ),
  getExercises: new g_Route<null, ListData<ExerciseData>>(
    HttpMethod.get,
    'exercises',
    'Получить все упражнения',
  ),
  getExercisesMany: new g_Route<null, ListData<ExerciseData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'exercises/many',
    'Получить упражнения',
  ),
  getExercise: new g_Route<{ id: number }, ExerciseData>(
    HttpMethod.get,
    'exercises/:id',
    'Получить упражнение',
  ),
  editExercise: new g_Route<{ id: number }, ExerciseData, Partial<ExerciseArgs>>(
    HttpMethod.put,
    'exercises/:id',
    'Изменить упражнение',
  ),
  editExercises: new g_Route<null, ListData<ExerciseData>, ExerciseEditionArgs[]>(
    HttpMethod.put,
    'exercises/many',
    'Изменить упражнения',
  ),
  deleteExercise: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'exercises/:id',
    'Удалить упражнение',
  ),
  deleteExercises: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'exercises/many',
    'Удалить упражнения',
  ),
} as const satisfies { [p: string]: g_Route<any, any, any, any> };
