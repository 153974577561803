import {css} from '@emotion/react';
import {modelClass, modelStyles} from '#includes/content/editor/plugins';
import {Keyword} from '#includes/content/editor/Keyword';

export const plugins = css(
  Object.fromEntries(modelStyles.map(
    ([model, answerStyle]) => [`.${modelClass[model]}`, answerStyle.viewer],
  )),
  css`
    // Метаданные скрыты
    .${Keyword.metaContainerClass} {
      display: none;
    }
  `,
);