import {HTMLAttributes, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {Property} from 'csstype';
import {theme} from '#store';
import {
  BorderRadius,
  borders,
  BorderWidth,
  ColorName,
  ContainerScheme,
  PaintingType,
} from '#config';
import {getEm} from '#includes/utils';
import {Link as RouterLink} from 'react-router-dom';
import {checkNever} from '#global';

type ContainerPropsMin = {
  color?: ColorName,
  printing?: PaintingType,
  outlineWidth?: BorderWidth,
  borderStyle?: Property.BorderStyle
  borderRadius?: BorderRadius,
  element?: 'button' | 'div' | 'a' | 'RouterLink' | 'label',
  to?: string,
}

export type ContainerProps =
  Omit<HTMLAttributes<HTMLElement>, keyof ContainerPropsMin>
  & ContainerPropsMin;

const getPadding = (
  outlineWidth: NonNullable<ContainerProps['outlineWidth']>,
) => outlineWidth === 'base'
  ? `${getEm(3)} ${getEm(6)}`
  : `${getEm(4)} ${getEm(7)}`;

export const containerStyles = {
  base: (
    paintingType: PaintingType,
    outlineWidth: ValueOf<Required<Pick<ContainerProps, 'outlineWidth'>>>,
    borderStyle: ValueOf<Required<Pick<ContainerProps, 'borderStyle'>>>,
    borderRadius: ValueOf<Required<Pick<ContainerProps, 'borderRadius'>>>,
    textScheme: ContainerScheme,
  ) => css({
    borderColor: borderStyle === 'hidden' ? 'transparent' : textScheme.stroke,
    color: textScheme.color,
    backgroundColor: textScheme.background,
    transition: 'border 300ms, color 300ms, background 300ms',
  }, paintingType === 'custom' ? {} : {
    padding: getPadding(outlineWidth),
    borderStyle: borderStyle === 'hidden' ? 'solid' : borderStyle,
    borderWidth: borders.width[outlineWidth],
    borderRadius: borders.radius[borderRadius],
    fontWeight: 'bold',
  }),
};

export const Container = observer<ContainerProps>(props => {
  const {
    children,
    element = 'div',
    color = theme.current.baseColor,
    printing = 'custom',
    outlineWidth = 'base',
    borderStyle = 'solid',
    borderRadius = 'small',
    to = '#',
    ...otherProps
  } = props;

  const scheme = useMemo(
    () => theme.current.Container(color)[printing],
    [color, printing],
  );

  const style = useMemo(
    () => containerStyles.base(
      printing,
      outlineWidth,
      borderStyle,
      borderRadius,
      scheme,
    ),
    [
      printing,
      outlineWidth,
      borderStyle,
      borderRadius,
      scheme,
    ],
  );

  return element === 'div' ? (
    <div css={style} {...otherProps}>
      {children}
    </div>
  ) : element === 'button' ? (
    <button css={style} {...otherProps}>
      {children}
    </button>
  ) : element === 'a' ? (
    <a css={style} {...otherProps}>
      {children}
    </a>
  ) : element === 'label' ? (
    <label css={style} {...otherProps}>
      {children}
    </label>
  ) : element === 'RouterLink' ? (
    <RouterLink css={style} to={to} {...otherProps}>
      {children}
    </RouterLink>
  ) : checkNever(element);
});