import {ViewElement, ViewNode, ViewText} from '@ckeditor/ckeditor5-engine';
import {ElementData} from './ElementData';
import {TextData} from './TextData';
import {ConverterError} from '#includes/error';

export function toNodeData(
  node: Node | ViewNode,
): ElementData | TextData {
  if (node instanceof Element || node instanceof ViewElement)
    return new ElementData(node);

  if (node instanceof Text || node instanceof ViewText)
    return new TextData(node);

  throw new ConverterError(
    `Converter.${toNodeData.name}`,
    'node расширяет неизвестный класс',
  );
}