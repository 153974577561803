import {HttpMethod, JwtData, LoginArgs} from '../';
import {g_Route} from '../utils/Route';

export const g_AuthRoute = {
  signIn: new g_Route<null, JwtData, LoginArgs>(
    HttpMethod.post,
    'auth',
    'Вход',
  ),
  getToken: new g_Route<null, JwtData | {}>(
    HttpMethod.get,
    'auth',
    'Получение токена',
    true,
  ),
  signOut: new g_Route(
    HttpMethod.delete,
    'auth',
    'Выход',
  ),
  signInAnother: new g_Route<{ userId: number }, JwtData>(
    HttpMethod.post,
    'auth/another/:userId',
    'Вход в чужой аккаунт',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any> } = g_AuthRoute;