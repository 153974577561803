export enum Keyword {
  containerClass = 'ck-custom-container',

  tableClass = 'ck-custom-table',
  columnClass = 'ck-custom-column',

  listClass = 'ck-custom-list',
  horizontalClass = 'ck-custom-horizontal',
  itemClass = 'ck-custom-item',
  beforeClass = 'ck-custom-before',
  removeClass = 'ck-custom-remove',
  insertClass = 'ck-custom-insert',

  formClass = 'ck-custom-form',
  helpClass = 'ck-custom-help',
  contentClass = 'ck-custom-content',
  confirmClass = 'ck-custom-confirm',
  saveClass = 'ck-custom-save',
  cancelClass = 'ck-custom-cancel',

  switchClass = 'ck-custom-switch',
  inputClass = 'ck-custom-input',
  buttonClass = 'ck-custom-button',

  fileClass = 'ck-custom-file',
  previewModeClass = 'ck-custom-preview-mode',
  loadingClass = 'ck-custom-loading',

  textOrFileClass = 'ck-custom-text-or-file',
  fileModeClass = 'ck-custom-file-mode',

  progressClass = 'ck-custom-progress',
  formulaPreviewClass = 'ck-custom-formula-review',

  //

  formHelpLabel = 'help',
  formSaveLabel = 'Save',
  formCancelLabel = 'Cancel',
  formRemoveLabel = 'Remove',
  formAddLabel = 'Add',
  formFileLabel = 'Choose file',
  formCorrectLabel = 'Correct answer',
  formGroupLabel = 'Group name',
  formInputLabel = 'Answer variant',
  formSwitchTextOrFileLabel = 'Replace text and picture',
  formIsCorrectLabel = 'Is this the correct answer',
  formIsHorizontalLabel = 'Is horizontal',

  uploadError = 'Couldn\'t upload file',

  //

  widgetModel = 'exerciseWidget',
  widgetInsertCommand = 'insertExerciseWidget',
  widgetInsertLabel = 'Insert exercise',
  widgetElement = 'section',
  widgetClass = 'exercise',

  metaContainerModel = 'exerciseContainerMeta',
  metaContainerElement = 'div',
  metaContainerClass = 'exercise-container-meta',

  metaModel = 'exerciseMeta',
  metaCommand = 'insertExerciseMeta',
  metaElement = 'div',
  metaClass = 'exercise-meta',
  metaFormClass = 'exercise-meta-form',

  panelModel = 'exercisePanel',
  panelElement = 'div',
  panelClass = 'exercise-panel',

  //

  answerClass = 'answer',

  dropListCommand = 'insertExerciseDropListAnswer',
  dropListLabel = 'Insert answer dropdown',
  dropListClass = 'exercise-answer-drop-list',
  dropListFormClass = 'ck-answer-drop-list-form',

  checkListCommand = 'insertExerciseCheckListAnswer',
  checkListLabel = 'Insert list of answers with checkboxes',
  checkListClass = 'exercise-answer-check-list',
  checkListFormClass = 'ck-answer-check-list-form',

  textCommand = 'insertExerciseTextAnswer',
  textLabel = 'Insert text answer',
  textClass = 'exercise-answer-text',
  textFormClass = 'ck-answer-text-form',

  sortingCommand = 'insertExerciseSortingAnswer',
  sortingLabel = 'Insert sorting answer',
  sortingClass = 'exercise-answer-sorting',
  sortingFormClass = 'ck-answer-sorting-form',

  connectionsCommand = 'insertExerciseConnectionsAnswer',
  connectionsLabel = 'Insert connections answer',
  connectionsClass = 'exercise-answer-connections',
  connectionsFormClass = 'ck-answer-connections-form',

  orderedCommand = 'insertExerciseOrderedAnswer',
  orderedLabel = 'Insert ordered answer',
  orderedClass = 'exercise-answer-ordered',
  orderedFormClass = 'ck-answer-ordered-form',

  videoModel = 'modelVideo',
  videoCommand = 'insertVideo',
  videoLabel = 'Insert video',
  videoClass = 'video',
  videoFormClass = 'ck-video',

  audioModel = 'modelAudio',
  audioCommand = 'insertAudio',
  audioLabel = 'Insert audio',
  audioClass = 'audio',
  audioFormClass = 'ck-audio',

  formulaModel = 'modelFormula',
  formulaCommand = 'insertFormula',
  formulaLabel = 'Insert formula',
  formulaClass = 'formula',
  formulaFormClass = 'ck-formula',
}