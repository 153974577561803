import {observer} from 'mobx-react-lite';
import {Clickable, ClickableProps} from '#components/atoms';

type ExternalLinkPropsMin = {
  href?: string,
} & Partial<Pick<HTMLAnchorElement, 'target' | 'download'>>;

export type ExternalLinkProps =
  Omit<ClickableProps, keyof ExternalLinkPropsMin | 'disabled' | 'element'>
  & ExternalLinkPropsMin;

export const ExternalLink = observer<ExternalLinkProps>(props => {
  const {
    children,
    color = 'blue',
    printing = 'custom',
    href = '#',
    outlineWidth = 'small',
    inline = true,
    target = '_blank',
    download,
    ...otherProps
  } = props;

  return (
    <Clickable
      color={color}
      element={'a'}
      inline={inline}
      outlineWidth={outlineWidth}
      printing={printing}
      {...otherProps}
    >
      <a target={target} download={download} href={download || href}>
        {children}
      </a>
    </Clickable>
  );
});
