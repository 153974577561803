import {HTMLAttributes, useCallback, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {EditorConfig} from '@ckeditor/ckeditor5-core';
import {ClassicEditor} from '@ckeditor/ckeditor5-editor-classic';
import {CustomEditor} from '#includes/content';
import {styles} from './styles';
import {view} from '#store';
import {ParagraphData} from '#global';
import {Button} from '#components/atoms';
import {isProduction} from '#config';
import {Global} from '@emotion/react';

type ContentEditorPropsMin = {
  name: ParagraphData['name'] | null | undefined,
  content: ParagraphData['content'] | null | undefined,
  onChange?: (
    name: ParagraphData['name'],
    content: ParagraphData['content'],
  ) => void,
};

type ContentEditorProps =
  Omit<HTMLAttributes<HTMLElement>, keyof ContentEditorPropsMin>
  & ContentEditorPropsMin;

export const ContentEditor = observer<ContentEditorProps>(props => {
  const {
    name,
    content,
    onChange,
    ...otherProps
  } = props;

  const ref = useRef<CKEditor<CustomEditor>>(null);

  const [initialData, setInitialData] = useState<string | undefined | null>(null);

  useEffect(() => {
    if (!ref.current || !ref.current.editor) return;

    // TODO CKEditor.editor.config.set не работает
    ref.current.editor.config.set('ui.viewportOffset.top', view.topOffset);
  }, [ref.current, view.topOffset]);

  const updateData = useCallback(() => {
    if (content === undefined)
      return setInitialData(name ? `<h1>${name}</h1>` : undefined);

    if (initialData === undefined) return;
    if (content === null) return;
    if (initialData) return;

    setInitialData(`<h1>${name || ''}</h1>${content || '<p></p>'}`);
  }, [initialData, name, content]);
  useEffect(updateData, [name, content]);

  const editorConfig = useCallback((data?: string): EditorConfig => ({
    initialData: data,
    language: 'ru',
    placeholder: 'Контент',
    ui: {
      viewportOffset: {
        top: view.topOffset, // TODO CKEditor.editor.config.ui.viewportOffset.top не обновляется
      },
      poweredBy: {
        position: 'inside',
        side: 'left',
        label: null,
        verticalOffset: 0,
        horizontalOffset: 10000,
      },
    },
  }), []);

  const changeHandler = useCallback((_event: any, editor: ClassicEditor) => {
    if (!onChange) return;

    const data = editor.getData();

    if (!data) return onChange(undefined, undefined);

    const name = data.replace(/<h1>([^<]*)<\/h1>.+/, '$1').replaceAll('&nbsp;', ' ').trim();
    const content = data.replace(/<h1>[^<]*<\/h1>/, '').trim();

    onChange(name ? name : undefined, content ? content : undefined);
  }, [onChange]);

  const readyHandler = useCallback((editor: ClassicEditor) => {
    changeHandler(undefined, editor);

    (globalThis as any).editor = editor;
  }, []);

  return (
    <>
      {!isProduction && (
        <Button
          css={styles.reload}
          color={'orange'}
          onMouseDown={setInitialData.bind(null, null)}
          onMouseUp={updateData}
        >
          Перезапустить редактор
        </Button>
      )}
      <div css={styles.base} {...otherProps}>
        <Global styles={styles.global}/>
        {initialData !== null && (
          <CKEditor
            config={editorConfig(initialData)}
            editor={CustomEditor}
            onChange={changeHandler}
            onReady={readyHandler}
            ref={ref}
          />
        )}
      </div>
    </>
  );
});