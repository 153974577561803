import {HttpMethod, ListData, SectionArgs, SectionData, SectionEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_SectionsRoute = {
  createSection: new g_Route<null, SectionData, SectionArgs>(
    HttpMethod.post,
    'sections',
    'Добавить раздел',
  ),
  createSections: new g_Route<null, ListData<SectionData>, SectionArgs[]>(
    HttpMethod.post,
    'sections/many',
    'Добавить разделы',
  ),
  getSections: new g_Route<null, ListData<SectionData>>(
    HttpMethod.get,
    'sections',
    'Получить все разделы',
  ),
  getSectionsMany: new g_Route<null, ListData<SectionData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'sections/many',
    'Получить разделы',
  ),
  getSection: new g_Route<{ id: number }, SectionData>(
    HttpMethod.get,
    'sections/:id',
    'Получить раздел',
  ),
  editSection: new g_Route<{ id: number }, SectionData, Partial<SectionArgs>>(
    HttpMethod.put,
    'sections/:id',
    'Изменить раздел',
  ),
  editSections: new g_Route<null, ListData<SectionData>, SectionEditionArgs[]>(
    HttpMethod.put,
    'sections/many',
    'Изменить разделы',
  ),
  deleteSection: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'sections/:id',
    'Удалить раздел',
  ),
  deleteSections: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'sections/many',
    'Удалить разделы',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_SectionsRoute;
