import {Keyword} from '#includes/content/editor/Keyword';
import {SuperArray} from '#global';
import {CommandBase} from '#includes/content/editor/plugins/utils';

export class Command extends CommandBase {
  public override execute(data: [string, string[]]) {
    const [help, tags] = data;

    if (tags.length === 0) return;

    const editor = this.editor;
    const model = editor.model;

    model.change(writer => {
      const element = writer.createElement(Keyword.metaModel, {
        data: [
          help || 'Нет подсказки',
          SuperArray.from(tags).removeRepeats().array
        ] as [string, string[]],
      });

      const {end: positionAfter} = editor.model.insertObject(
        element,
        null,
        null,
        {setSelection: 'on'},
      );

      writer.setSelection(positionAfter);
    });
  }

  public override refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const position = selection.getFirstPosition();
    const allowedIn = position && model.schema.findAllowedParent(
      position,
      Keyword.metaModel,
    );

    this.isEnabled = allowedIn !== null;
  }
}