import {ButtonView as CkButtonView} from '@ckeditor/ckeditor5-ui';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseView} from '#includes/content/editor/Views';

export class ButtonView extends BaseView<CkButtonView> {
  private constructor(
    locale: Locale,
    className: string | string[],
    label: string,
    icon?: string,
  ) {
    super(locale, new CkButtonView(locale));

    this.ckView.set({
      label: locale.t(label),
      withText: icon === undefined,
      icon: icon,
      tooltip: true,
      class: ['ck', Keyword.buttonClass, className].flat().join(' '),
    });
  }

  public static create(
    locale: Locale,
    className: string | string[],
    label: string,
    icon?: string,
  ): ButtonView {
    return new ButtonView(
      locale,
      className,
      label,
      icon,
    );
  }

  public focus(): void {
    this.ckView.element?.focus();
  }
}