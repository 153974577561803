import {DifficultyData, HttpMethod, ListData} from '../../';
import {g_Route} from '../../utils';

export const g_DifficultiesRoute = {
  getDifficulties: new g_Route<null, ListData<DifficultyData>>(
    HttpMethod.get,
    'difficulties',
    'Получить все сложности',
  ),
  getDifficultiesMany: new g_Route<null, ListData<DifficultyData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'difficulties/many',
    'Получить сложности',
  ),
  getDifficulty: new g_Route<{ id: number }, DifficultyData>(
    HttpMethod.get,
    'difficulties/:id',
    'Получить сложность',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_DifficultiesRoute;
