import {ClassArgs, ClassData, ClassEditionArgs, HttpMethod, ListData} from '../../';
import {g_Route} from '../../utils';

export const g_ClassesRoute = {
  createClass: new g_Route<null, ClassData, ClassArgs>(
    HttpMethod.post,
    'classes',
    'Добавить класс',
  ),
  createClasses: new g_Route<null, ListData<ClassData>, ClassArgs[]>(
    HttpMethod.post,
    'classes/many',
    'Добавить классы',
  ),
  getClasses: new g_Route<null, ListData<ClassData>>(
    HttpMethod.get,
    'classes',
    'Получить все классы',
  ),
  getClassesMany: new g_Route<null, ListData<ClassData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'classes/many',
    'Получить классы',
  ),
  getClass: new g_Route<{ id: number }, ClassData>(
    HttpMethod.get,
    'classes/:id',
    'Получить класс',
  ),
  editClass: new g_Route<{ id: number }, ClassData, Partial<ClassArgs>>(
    HttpMethod.put,
    'classes/:id',
    'Изменить класс',
  ),
  editClasses: new g_Route<null, ListData<ClassData>, ClassEditionArgs[]>(
    HttpMethod.put,
    'classes/many',
    'Изменить классы',
  ),
  deleteClass: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'classes/:id',
    'Удалить класс',
  ),
  deleteClasses: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'classes/many',
    'Удалить классы',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_ClassesRoute;