import {View} from '@ckeditor/ckeditor5-ui';
import {ContainerBaseView} from './_ContainerBase';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';

export class ProgressView extends ContainerBaseView<'progress', View<HTMLProgressElement>, []> {
  private max: number = 100;
  private value: number = 0;

  private constructor(
    locale: Locale,
    className: string | string[],
  ) {
    const progress = new View<HTMLProgressElement>();
    super(
      locale,
      'progress',
      progress,
      [Keyword.progressClass, className].flat(),
    );
  }

  public static create(
    locale: Locale,
    className: string | string[],
  ): ProgressView {
    return new ProgressView(locale, className);
  }

  public setMax(value: number) {
    this.max = value;

    this.update();
  }

  public setValue(value: number) {
    this.value = value;

    this.update();
  }

  //

  private update() {
    if (!this.ckView.element) return;

    this.ckView.element.max = this.max;
    this.ckView.element.value = this.value;
  }
}