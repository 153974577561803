import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {getEm} from '#includes/utils';
import {HTMLAttributes} from 'react';

type CodePropsMin = {
  children: string,
  object?: never,
} | {
  children?: never,
  object: any,
};

type CodeProps =
  Omit<HTMLAttributes<HTMLElement>, keyof CodePropsMin>
  & CodePropsMin;

// TODO Сделать тему
enum Color {
  darkGray = '#2b2b2b',
  purple = '#9876aa',
  green = '#6a8759',
  gray = '#a8b5b6',
  blue = '#6897bb',
  orange = '#cc7832',
}

const styles = {
  base: () => css({
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    padding: getEm(10),
    color: Color.orange,
    backgroundColor: Color.darkGray,
    overflow: 'auto',
  }),
};

enum Mark {
  end = '##end##',
  purple = '##purple##',
  green = '##green##',
  gray = '##gray##',
  blue = '##blue##',
}

export const Code = observer<CodeProps>(props => {
  const {
    children = '',
    object = null,
    ...otherProps
  } = props;

  try {
    const sureObject = object ? object : children ? JSON.parse(children) : null;
    const text = JSON.stringify(sureObject, null, '  ');
    const marked = (text
        .replaceAll(/(:[^\["]*)("[^"]*")(,?)$/img, `$1${Mark.green}$2${Mark.end}$3`)
        .replaceAll(/(:[^0-9\n]*)([0-9]+)(,?)$/img, `$1${Mark.blue}$2${Mark.end}$3`)
        .replaceAll(/((^[\s\t]*)|(:[\s\t]*))([\[\]{}](\s*[\]}])?)/img, `$1${Mark.gray}$4${Mark.end}`)
        .replaceAll(/(^[^\n"]+)("[^"]*")([^:\n]*:)/img, `$1${Mark.purple}$2${Mark.end}$3`)
        .replaceAll(/(^[^"]*)("[^"]*")(,?$)/img, `$1${Mark.green}$2${Mark.end}$3`)
        .replaceAll(/(^[^0-9\n"]*)([0-9]+)(,?$)/img, `$1${Mark.blue}$2${Mark.end}$3`)
    );
    const styled = (marked
        .replaceAll(Mark.purple, `<span style="color:${Color.purple}">`)
        .replaceAll(Mark.green, `<span style="color:${Color.green}">`)
        .replaceAll(Mark.gray, `<span style="color:${Color.gray}">`)
        .replaceAll(Mark.blue, `<span style="color:${Color.blue}">`)
        .replaceAll(Mark.end, '</span>')
    );

    return (
      <div css={styles.base()} dangerouslySetInnerHTML={{__html: styled}} {...otherProps}/>
    );
  } catch (e) {
    console.log(children, object);
    console.log(e);

    const error = e instanceof Error ? e : new Error('unknown error');

    return (
      <div css={styles.base()} {...otherProps}>
        {error.message}
      </div>
    );
  }
});