import {Plugin} from '@ckeditor/ckeditor5-core';
import {ButtonView, clickOutsideHandler, ContextualBalloon} from '@ckeditor/ckeditor5-ui';
import {Form, ModelData} from './Form';
import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {Converter} from './Converter';
import {SuperError} from '#includes/error';
import {notifications} from '#store';
import {PositionOptions} from '@ckeditor/ckeditor5-utils';

export class Ui extends Plugin {
  public static current: Ui;

  public static showUI(editor: Element): void {
    const data = Converter.toModelData(editor);
    if (data instanceof Error)
      return void notifications.addError(data);

    Ui.current.showUI(data);
  }

  //

  private balloon!: ContextualBalloon;
  private form!: Form;

  public init() {
    Ui.current = this;

    const editor = this.editor;

    this.balloon = editor.plugins.get(ContextualBalloon);
    this.form = this.createFormView();

    editor.ui.componentFactory.add(Keyword.checkListCommand, locale => {
      const command = editor.commands.get(Keyword.checkListCommand) as Command | undefined;
      if (!command) throw new SuperError(
        `${this.constructor.name}.${this.init.name}.addCommand`,
        `Command "${Keyword.checkListCommand}" in not exist`,
      );

      const button = new ButtonView(locale);
      button.set({
        label: editor.t(Keyword.checkListLabel),
        tooltip: true,
        withText: true,
      });

      button
        .bind('isOn')
        .to(command, 'value', value => Boolean(value));

      button
        .bind('isEnabled')
        .to(command);

      button.on('execute', () => this.showUI([]));

      return button;
    });
  }

  private createFormView() {
    const editor = this.editor;

    const form = new Form(editor.locale, () => this.hideUI());
    form.setSubmitHandler(() => {
      editor.execute(Keyword.checkListCommand, form.getData());

      this.hideUI();
    });
    form.setCancelHandler(() => this.hideUI());

    clickOutsideHandler({
      emitter: form.ckView,
      activator: () => this.balloon.visibleView === form.ckView,
      contextElements: [this.balloon.view.element!],
      callback: () => this.hideUI(),
    });

    return form;
  }

  private showUI(data: ModelData): void {
    this.form.setData(data);

    this.balloon.add({
      view: this.form.ckView,
      position: this.getBalloonPosition(),
    });

    this.form.focus();
  }

  private hideUI() {
    this.balloon.remove(this.form.ckView);

    this.editor.editing.view.focus();
  }

  private getBalloonPosition(): Partial<PositionOptions> {
    const view = this.editor.editing.view;
    const viewDocument = view.document;

    const range = viewDocument.selection.getFirstRange();
    if (!range) throw new SuperError(
      `${this.constructor.name}.${this.getBalloonPosition.name}`,
      'range is empty',
    );

    return {
      target: () => view.domConverter.viewRangeToDom(range),
    };
  }
}

(globalThis as any).Ck_Exercise_CheckList_Ui = Ui;