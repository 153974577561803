import {StyleBase} from '#includes/content/editor/plugins/utils';
import {css} from '@emotion/react';
import {getEm} from '#includes/utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {ContainerStyle} from '#includes/ContainerStyle';
import {palette} from '#config';

const draggableItem = css`
  ${ContainerStyle.Clickable};
  color: ${palette.black};

  cursor: grab;
`;

export const Style: StyleBase = {
  general: css`
    gap: ${getEm(7)};

    &:not(.${Keyword.horizontalClass}) {
      display: flex;
      flex-direction: column;
      width: fit-content;
    }

    &.${Keyword.horizontalClass} {
      display: flex;
      flex-wrap: wrap;

      & > *:not(.ck).${Keyword.fileModeClass} {
        min-width: 10em;
      }
    }

    &:not(.draggable) > *:not(.ck), &-draggable {
      display: flex;
      align-items: center;
      justify-content: center;

      &.${Keyword.fileModeClass} {
        max-width: 15em;
      }

      & > img {
        aspect-ratio: 20 / 11;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  `,
  viewer: css`
    &-draggable {
      ${draggableItem};
    }
  `,
  editor: css`
    & > *:not(.ck) {
      ${draggableItem};
    }
  `,
};