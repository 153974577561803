import {css, keyframes as keyframes} from '@emotion/react';
import {observer} from 'mobx-react-lite';
import {getEm} from '#includes/utils';
import {loader, theme} from '#store';

const keyframeNames = {
  outer: keyframes({
    '0%': {transform: 'rotate(0deg)'},
    '100%': {transform: 'rotate(360deg)'},
  }),
  inner: keyframes({
    '0%': {transform: 'rotate(0deg)'},
    '100%': {transform: 'rotate(-360deg)'},
  }),
};

const styles = {
  base: (is_visible: boolean) => css({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999999999,
    opacity: is_visible ? 1 : 0,
    pointerEvents: is_visible ? 'all' : 'none',
    userSelect: 'none',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.current.Preloader.background[0],
    transition: `opacity ease-in`,
    transitionDuration: is_visible ? '50ms' : '500ms',
  }),
  box: () => css({
    position: 'relative',
    width: getEm(120),
    height: getEm(120),
  }),
  outer: (is_visible: boolean) => css({
    position: 'absolute',
    display: 'block',
    borderRadius: '50%',
    border: `${getEm(4)} solid ${theme.current.Preloader.stroke[0]}`,
    borderBottom: 0,
    borderLeftColor: 'transparent',
    animation: 'cubic-bezier(.42, .61, .58, .41) infinite',
    animationDuration: is_visible ? '1000ms' : '200ms',
    animationName: keyframeNames.outer,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
  inner: (is_visible: boolean) => css({
    position: 'absolute',
    display: 'block',
    borderRadius: '50%',
    border: `${getEm(4)} solid ${theme.current.Preloader.stroke[1]}`,
    borderRight: 0,
    borderTopColor: 'transparent',
    animation: 'cubic-bezier(.42, .61, .58, .41) infinite',
    animationDuration: is_visible ? '1000ms' : '200ms',
    animationName: keyframeNames.inner,
    top: `calc(50% - ${getEm(40)})`,
    left: `calc(50% - ${getEm(40)})`,
    width: getEm(80),
    height: getEm(80),
  }),
};

export const Preloader = observer(() => {
  return (
    <div css={styles.base(loader.displayed)}>
      <div css={styles.box()}>
        <span css={styles.outer(loader.displayed)}/>
        <span css={styles.inner(loader.displayed)}/>
      </div>
    </div>
  );
});
