import {StyleBase} from '#includes/content/editor/plugins/utils';
import {css} from '@emotion/react';
import {ContainerStyle} from '#includes/ContainerStyle';
import {getEm} from '#includes/utils';
import {palette} from '#config';

const item = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 20 / 11;
  max-width: 15em;
  width: 100%;

  & > img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const Style: StyleBase = {
  general: css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${getEm(7)};

    & > *:not(.ck) {
      display: flex;
      flex-direction: column;
      gap: ${getEm(7)};
      width: 100%;
      max-width: 15em;

      &:nth-of-type(1) > * {
        ${item};
        ${ContainerStyle.Outlined};
        color: ${palette.black};

        font-weight: normal;
      }

      &:nth-of-type(2) > * {
        ${item};
      }

      &:nth-of-type(3) {
        max-width: initial;
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  `,
  viewer: css`
    &-draggable, & .draggable-empty {
      ${item};
    }

    & > *:nth-of-type(3) > .draggable-fixed {
      display: flex;
      flex-direction: column;
      gap: ${getEm(7)};

      max-width: 15em;
      width: 100%;
    }
  `,
  editor: css`
    & > * {
      &.ck {
        order: -1;
      }

      &:not(.ck) {
        &:nth-of-type(1) {
          order: 1;
        }

        &:nth-of-type(2) {
          order: 3;

          & > * {
            ${ContainerStyle.Dashed};
            color: ${palette.black};
          }
        }

        &:nth-of-type(3) {
          order: 2;
          align-items: flex-start;

          & > * {
            ${item};
            ${ContainerStyle.Clickable};
            color: ${palette.black};
          }
        }
      }
    }
  `,
};