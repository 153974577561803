import {HttpMethod, ListData, PermissionData} from '../../';
import {g_Route} from '../../utils';

export const g_PermissionsRoute = {
  getPermissions: new g_Route<null, ListData<PermissionData>>(
    HttpMethod.get,
    'permissions',
    'Получить все права',
  ),
  getPermissionsMany: new g_Route<null, ListData<PermissionData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'permissions/many',
    'Получить права',
  ),
  getPermission: new g_Route<{ id: number }, PermissionData>(
    HttpMethod.get,
    'permissions/:id',
    'Получить право',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_PermissionsRoute;