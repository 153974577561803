import {action, computed, makeObservable, observable} from 'mobx';
import {
  ExerciseData,
  HttpMethod,
  LevelData,
  ListData,
  ParagraphData,
  SectionData,
  SubjectData,
  SureObject,
  TopicData,
} from '#global';
import {ApiRoute, Route} from '#includes/ApiRoute';
import {CancelTokenSource} from 'axios';
import {axios, Response} from '#includes/axios';
import {RouteBase} from '#includes/RouteBase';
import {Success} from '#includes/axios/Success';
import {Fail} from '#includes/axios/Fail';
import {notifications} from './notifications';

class Nesting {
  private _cancelTokens: Map<string, CancelTokenSource> = new Map<string, CancelTokenSource>();
  private _isLoading: boolean = false;

  private _level: null | Readonly<LevelData> = null;
  private _subject: null | Readonly<SubjectData> = null;
  private _section: null | Readonly<SectionData> = null;
  private _topic: null | Readonly<TopicData> = null;
  private _paragraph: null | Readonly<ParagraphData> = null;
  private _exercises: null | Readonly<ExerciseData[]> = null;

  public constructor() {
    makeObservable<
      typeof this,
      '_level'
      | '_subject'
      | '_section'
      | '_topic'
      | '_paragraph'
      | '_exercises'
      | '_isLoading'
      | 'updateLoadingStatus'
      | 'setExercises'
    >(this, {
      _isLoading: observable.ref,
      updateLoadingStatus: action.bound,
      isLoading: computed,

      _level: observable.ref,
      setLevel: action.bound,
      clearLevel: action.bound,
      setLevelById: action.bound,
      updateLevel: action.bound,
      level: computed,

      _subject: observable.ref,
      setSubject: action.bound,
      clearSubject: action.bound,
      setSubjectById: action.bound,
      updateSubject: action.bound,
      subject: computed,

      _section: observable.ref,
      setSection: action.bound,
      clearSection: action.bound,
      setSectionById: action.bound,
      updateSection: action.bound,
      section: computed,

      _topic: observable.ref,
      setTopic: action.bound,
      clearTopic: action.bound,
      setTopicById: action.bound,
      updateTopic: action.bound,
      topic: computed,

      _paragraph: observable.ref,
      setParagraph: action.bound,
      clearParagraph: action.bound,
      setParagraphById: action.bound,
      updateParagraph: action.bound,
      paragraph: computed,

      _exercises: observable.ref,
      setExercises: action.bound,
      exercises: computed,
    });
  }

  public get isLoading(): boolean {
    return this._isLoading;
  }

  public setLevel(level: LevelData): void {
    this._level = level;
  }

  public clearLevel(): void {
    this._level = null;
    this.clearSubject();
  }

  public updateLevel(): void {
    const id = this.level?.id;
    if (id === undefined) return;

    this.clearLevel();
    this.setLevelById(id);
  }

  public setLevelById(id: number): void {
    this._level = null;

    this.request<LevelData>(ApiRoute.getLevel, id).then(response => {
      if (!(response instanceof Success)) return;
      const level = response.data;

      this.setLevel(level);
    });
  }

  public get level(): Nesting['_level'] {
    return this._level;
  }

  public setSubject(subject: SubjectData): void {
    this._subject = subject;

    if (!this.level || !this.level.SubjectsId.includes(subject.id))
      this.setLevelById(subject.LevelId);
  }

  public clearSubject(): void {
    this._subject = null;
    this.clearSection();
  }

  public updateSubject(): void {
    const id = this.subject?.id;
    if (id === undefined) return;

    this.clearSubject();
    this.setSubjectById(id);
  }

  public setSubjectById(id: number): void {
    this._subject = null;

    this.request<SubjectData>(ApiRoute.getSubject, id).then(response => {
      if (!(response instanceof Success)) return;
      const subject = response.data;

      this.setSubject(subject);
    });
  }

  public get subject(): Nesting['_subject'] {
    return this._subject;
  }

  public setSection(section: SectionData): void {
    this._section = section;

    if (!this.subject || !this.subject.SectionsId.includes(section.id))
      this.setSubjectById(section.SubjectId);
  }

  public clearSection(): void {
    this._section = null;
    this.clearTopic();
  }

  public updateSection(): void {
    const id = this.section?.id;
    if (id === undefined) return;

    this.clearSection();
    this.setSectionById(id);
  }

  public setSectionById(id: number): void {
    this._section = null;

    this.request<SectionData>(ApiRoute.getSection, id).then(response => {
      if (!(response instanceof Success)) return;
      const section = response.data;

      this.setSection(section);
    });
  }

  public get section(): Nesting['_section'] {
    return this._section;
  }

  public setTopic(topic: TopicData): void {
    this._topic = topic;

    if (!this.section || !this.section.TopicsId.includes(topic.id))
      this.setSectionById(topic.SectionId);
  }

  public clearTopic(): void {
    this._topic = null;
    this.clearParagraph();
  }

  public updateTopic(): void {
    const id = this.topic?.id;
    if (id === undefined) return;

    this.clearTopic();
    this.setTopicById(id);
  }

  public setTopicById(id: number): void {
    this._topic = null;

    this.request<TopicData>(ApiRoute.getTopic, id).then(response => {
      if (!(response instanceof Success)) return;
      const topic = response.data;

      this.setTopic(topic);
    });
  }

  public get topic(): Nesting['_topic'] {
    return this._topic;
  }

  public setParagraph(paragraph: ParagraphData): void {
    this._paragraph = paragraph;

    if (!this.topic || !this.topic.ParagraphsId.includes(paragraph.id))
      this.setTopicById(paragraph.TopicId);

    this.setExercises(null);
    this.request<ListData<ExerciseData>>(
      ApiRoute.getExercisesMany,
      paragraph.ExercisesId,
    ).then(response => {
      if (!(response instanceof Success)) return;
      const exercises = response.data.list;

      this.setExercises(exercises);
    });
  }

  public clearParagraph(): void {
    this._paragraph = null;
    this._exercises = null;
  }

  public updateParagraph(): void {
    const id = this.paragraph?.id;
    if (id === undefined) return;

    this.clearParagraph();
    this.setParagraphById(id);
  }

  public setParagraphById(id: number): void {
    this._paragraph = null;
    this._exercises = null;

    this.request<ParagraphData>(ApiRoute.getParagraph, id).then(response => {
      if (!(response instanceof Success)) return;
      const paragraph = response.data;

      this.setParagraph(paragraph);
    });
  }

  public get paragraph(): Nesting['_paragraph'] {
    return this._paragraph;
  }

  public get exercises(): Nesting['_exercises'] {
    return this._exercises;
  }

  //

  private setExercises(exercises: Nesting['_exercises']) {
    this._exercises = exercises;
  }

  private updateLoadingStatus(shouldSetStatusLoading?: true): void {
    if (!shouldSetStatusLoading) {
      this._isLoading = Array.from(this._cancelTokens.keys()).length !== 0;
    } else {
      this._isLoading = true;
    }
  }

  private async request<
    D extends SureObject = SureObject,
    R extends Route<any, D, any, any> = Route<any, D, any, any>
  >(
    route: R,
    id: number | number[],
  ): Promise<Response<D> | undefined> {
    const oldCancelToken = this._cancelTokens.get(route.path);
    if (oldCancelToken) oldCancelToken.cancel('Cancel');
    const cancelToken = axios.createCancelTokenSource();
    this._cancelTokens.set(route.path, cancelToken);
    this.updateLoadingStatus(true);

    let response: Response<D>;

    if (Array.isArray(id)) {
      response = await axios.request<D, undefined, { ids: number[] }, HttpMethod.get>(
        HttpMethod.get,
        `api/${route.path}`,
        undefined,
        {ids: id},
        {
          cancelToken: cancelToken.token,
        },
      );
    } else {
      response = await axios.request<D, undefined, undefined, HttpMethod.get>(
        HttpMethod.get,
        `api/${RouteBase.createUrl(route.path, {id})}`,
        undefined,
        undefined,
        {
          cancelToken: cancelToken.token,
        },
      );
    }

    this._cancelTokens.delete(route.path);
    this.updateLoadingStatus();
    if (response instanceof Success && (response.data as any) === 'Cancel') return;

    if (response instanceof Fail)
      notifications.addError(response);

    return response;
  }
}

export const nesting = new Nesting();

(globalThis as any).nesting = nesting;