import {AnswerModelName, AnswerValueBy} from '#global';
import {Mode, TextOrFileDatum} from '#includes/content/editor/Views';
import {NodeName} from './NodeName';
import {toNodeData} from './toNodeData';
import {ElementData} from './ElementData';
import {ConverterError} from '#includes/error';
import {DowncastWriter, ViewElementAttributes, ViewNode} from '@ckeditor/ckeditor5-engine';
import {isProduction, urls} from '#config';
import {TextData} from '#includes/content/editor/plugins/utils/Converter/TextData';
import {Keyword} from '#includes/content/editor/Keyword';

export abstract class ConverterBase<
  Model extends AnswerModelName,
  Value extends any = AnswerValueBy<Model>,
> {
  public abstract toAnswerValue(
    container: Element,
    isView: boolean,
  ): AnswerValueBy<Model> | Error;

  public abstract toView(
    data: Element,
  ): Element | Error;

  public abstract activateView(
    view: Element,
    limitContainer?: HTMLElement,
  ): Element | Error;

  public abstract toData(
    editor: Element,
  ): Element | Error;

  public abstract toEditor(
    data: Element,
    answer: AnswerValueBy<Model>,
  ): Element | Error;

  public abstract toModelData(
    editor: Node | ViewNode,
  ): Value | Error;

  public abstract toModel(
    writer: DowncastWriter,
    data: Value,
    isEditor: boolean,
  ): { attributes: ViewElementAttributes, children: ViewNode[] } | Error;

  //

  protected toNodeData = toNodeData;

  protected arrangeNested(
    indexes: number[],
    element: Element,
  ): Element | Error {
    const children = this.getChildren(element, HTMLDivElement);
    if (children instanceof Error) return children;

    if (indexes.length !== children.length) return new ConverterError(
      `ConnectionsConverter.${this.arrangeNested.name}`,
      'Длина indexes не равна длине children',
      {indexes, children},
    );

    element.innerHTML = '';

    const sorted: HTMLDivElement[] = new Array(children.length);
    children.forEach((_child, index) => sorted[index] = children[indexes[index]]);
    sorted.forEach(child => element.appendChild(child));

    return element;
  }

  protected confuseNested(
    indexes: number[],
    element: Element,
  ): Element | Error {
    const children = this.getChildren(element, HTMLDivElement);
    if (children instanceof Error) return children;

    if (indexes.length !== children.length) return new ConverterError(
      `ConnectionsConverter.${this.confuseNested.name}`,
      'Длина indexes не равна длине children',
      {indexes, children},
    );

    element.innerHTML = '';

    const sorted: HTMLDivElement[] = [...children];
    children.forEach((child, index) => sorted[indexes[index]] = child);
    sorted.forEach(child => element.appendChild(child));

    return element;
  }

  protected getInnerText(
    element: ElementData,
  ): string | Error {
    const text = element.getChild(0);
    if (!text || !text.isText()) return new ConverterError(
      `ConverterBase.${this.getInnerText.name}`,
      'text не является Text',
      text,
    );

    return text.data;
  }

  protected getNestedTexts(
    element: ElementData,
    nestedElementsName: Exclude<NodeName, 'text'>,
    nestedElementsNamesForSkip: NodeName[] = [],
  ): string[] | Error {
    const answers: string[] = [];

    for (const child of element.children) {
      if (nestedElementsNamesForSkip.includes(child.name)) continue;

      if (child.isText() || !child.nameIs(nestedElementsName)) return new ConverterError(
        `ConverterBase.${this.getNestedTexts.name}`,
        'child не является разрешённым элементом',
        child,
      );

      const string = this.getInnerText(child);
      if (string instanceof Error) return string;

      answers.push(string);
    }

    return answers;
  }

  protected nodeToTextOrFileDatum(
    element: ElementData | TextData,
  ): TextOrFileDatum | Error {
    if (element.isText() || !element.nameIs('div')) return new ConverterError(
      `ConverterBase.${this.nodeToTextOrFileDatum.name}`,
      'element не является Div',
      element,
    );

    const inner = element.getChild(0);
    if (!inner || (!inner.isText() && !inner.nameIs('img'))) return new ConverterError(
      `ConverterBase.${this.nodeToTextOrFileDatum.name}`,
      'inner не является ни Text, ни Img',
      inner,
    );

    if (inner instanceof TextData)
      return new TextOrFileDatum(Mode.text, inner.data);

    const src = inner.src;
    if (!src) return new ConverterError(
      `ConverterBase.${this.nodeToTextOrFileDatum.name}`,
      'inner не имеет src',
      inner,
    );

    const value = src.replace(/^(.*)(\/media\/)/, '');

    return new TextOrFileDatum(Mode.file, value);
  }

  protected textOrFileDatumToModel(
    writer: DowncastWriter,
    datum: TextOrFileDatum,
  ): ViewNode {
    const isFile = datum.mode === Mode.file;

    return writer.createContainerElement('div', isFile ? {
      class: Keyword.fileModeClass,
    } : {}, (
      isFile ? (
        writer.createContainerElement('img', {
          src: `${isProduction ? '' : urls.backend}/media/${datum.value}`,
        })
      ) : (
        writer.createText(datum.value)
      )
    ));
  }

  //

  private getChildren<Child extends Element>(
    element: Element,
    ChildClass: ClassByInstance<Child>,
  ): Child[] | Error {
    const children: Child[] = [];

    for (const child of element.childNodes) {
      if (!(child instanceof ChildClass)) return new ConverterError(
        `${this.constructor.name}.${this.getChildren.name}`,
        `child не является ${ChildClass.name}`,
        {child},
      );

      children.push(child);
    }

    return children;
  }
}

export type {
  NodeName,
};