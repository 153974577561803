import {BaseData} from '../../';

export enum StatusId {
  completed = 1,
}

export type StatusData = BaseData<{
  id: StatusId,
  name: string,
}>;

export const statuses: { readonly [key in StatusId]: StatusData } = {
  [StatusId.completed]: {
    id: StatusId.completed,
    name: 'Завершено',
  },
};