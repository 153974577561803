import {BaseView} from '#includes/content/editor/Views';
import {ButtonView, View} from '@ckeditor/ckeditor5-ui';
import {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import browseFiles from '@ckeditor/ckeditor5-ckfinder/theme/icons/browse-files.svg';
import {FileUpload} from '#includes/FileUpload';
import {isProduction, urls} from '#config';
import {FileData} from '#global';

export class InputFileView extends BaseView<ButtonView> {
  private readonly ckInput: View<HTMLInputElement>;
  private readonly labelText: string;
  private readonly inputFileLabel: View<HTMLSpanElement>;
  private readonly ckPreview: View<HTMLDivElement>;

  private progressHandler?: (max: number, value: number) => void;
  private finallyHandler?: (file?: FileData) => void;

  private file: string | null = null;

  private constructor(
    locale: Locale,
    className: string | string[],
    label: string = '',
  ) {
    super(locale, new ButtonView(locale));
    this.ckView.set({
      label: locale.t(label),
      withText: true,
      icon: browseFiles,
      tooltip: true,
      class: ['ck', Keyword.fileClass, className].flat().join(' '),
    });

    this.labelText = locale.t(Keyword.formFileLabel);

    this.inputFileLabel = new View<HTMLSpanElement>(locale);
    this.inputFileLabel.setTemplate({
      tag: 'span',
      children: [this.labelText],
    });
    this.ckView.template?.children?.push(this.inputFileLabel);

    this.ckInput = new View<HTMLInputElement>(locale);
    this.ckInput.setTemplate({
      tag: 'input',
      attributes: {
        type: 'file',
      },
    });
    this.ckInput.on('render', this.inputRenderHandler.bind(this));
    this.ckView.template?.children?.push(this.ckInput);
    this.ckView.on('execute', () => this.ckInput.element?.click());

    this.ckPreview = new View<HTMLDivElement>(locale);
    this.ckPreview.setTemplate({
      tag: 'div',
    });
    this.ckView.template?.children?.push(this.ckPreview);
  }

  public static create(
    locale: Locale,
    className: string | string[],
    label: string = '',
  ): InputFileView {
    return new InputFileView(
      locale,
      className,
      label,
    );
  }

  public setFile(file: string): void {
    this.file = file;

    if (!this.ckPreview.element || !this.ckView.element) return;

    this.ckPreview.element.style.backgroundImage =
      `url('${isProduction ? '' : urls.backend}/media/${file}')`;

    this.ckView.element.classList.add(Keyword.previewModeClass);

    this.inputFileLabel.element && (this.inputFileLabel.element.innerHTML = file);
  }

  public getFile(): string | null {
    return this.file;
  }

  public setProgressHandler(handler: (max: number, value: number) => void) {
    this.progressHandler = handler;
  }

  public setFinallyHandler(handler: (file?: FileData) => void) {
    this.finallyHandler = handler;
  }

  public clear() {
    this.clearInput();
    this.file = null;
    this.ckPreview.element && (this.ckPreview.element.style.backgroundImage = '');
    this.ckView.element && this.ckView.element.classList.remove(Keyword.previewModeClass);
    this.inputFileLabel.element && (this.inputFileLabel.element.innerHTML = this.labelText);
  }

  public focus() {
    this.ckView.focus();
  }

  //

  private clearInput() {
    if (!this.ckInput.element) return;

    this.ckInput.element.files = null;
    this.ckInput.element.value = '';
  }

  private inputRenderHandler() {
    const element = this.ckInput.element;
    if (!element) return;
    element.onchange = this.inputChangeHandler.bind(this);
  }

  private inputChangeHandler(): void {
    const file = this.ckInput.element?.files?.[0];
    if (!file) return;

    this.clear();

    const fileUpload = new FileUpload();

    fileUpload.initListeners(
      file => {
        this.setFile(`${file.folder}/${file.name}`);
        this.finallyHandler && this.finallyHandler(file);
      },
      (max, value) => {
        this.progressHandler && this.progressHandler(max, value);
      },
      error => {
        this.finallyHandler && this.finallyHandler();

        if (!error) return; // abort
      },
    );

    fileUpload.send(file);
  }
}