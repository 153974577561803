import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';

const styles = {
  base: () => css({
    '&::after': {
      display: 'inline',
      content: '" "',
    },
  }),
};

export const Space = observer(() => {
  return (
    <span css={styles.base()}/>
  );
});