import {lazy} from 'react';

export const Student = {
  Levels: lazy(() => import('./preIndex')
    .then(module => ({default: module.Levels}))),
  Paragraph: lazy(() => import('./preIndex')
    .then(module => ({default: module.Paragraph}))),
};

// noinspection JSUnusedLocalSymbols
const security: LazyComponentsGroup = Student;