import {Plugin} from '@ckeditor/ckeditor5-core';
import {ButtonView, clickOutsideHandler, ContextualBalloon} from '@ckeditor/ckeditor5-ui';
import {PositionOptions} from '@ckeditor/ckeditor5-utils';
import {getRangeText} from '../utils';
import {Form} from './Form';
import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {SuperError} from '#includes/error';
import formulaIcon from '#assets/formula.svg';

export class Ui extends Plugin {
  public static current: Ui;

  public static showUI(span: HTMLElement) {
    if (!(span instanceof HTMLSpanElement)) return;

    const data = span.getAttribute('data-value');
    if (!data) return;

    Ui.current.showUI(data);
  }

  //

  private balloon!: ContextualBalloon;
  private form!: Form;

  public init() {
    Ui.current = this;

    const editor = this.editor;

    this.balloon = editor.plugins.get(ContextualBalloon);
    this.form = this.createFormView();

    editor.ui.componentFactory.add(Keyword.formulaCommand, locale => {
      const button = new ButtonView(locale);
      button.set({
        label: editor.t(Keyword.formulaLabel),
        tooltip: true,
        withText: false,
        icon: formulaIcon,
      });

      const command = editor.commands.get(
        Keyword.formulaCommand,
      ) as Command | undefined;
      if (!command) throw new SuperError(
        `${this.constructor.name}.${this.init.name}.addCommand`,
        `Command "${Keyword.formulaCommand}" in not exist`,
      );

      button
        .bind('isOn')
        .to(command, 'value', value => Boolean(value));

      button
        .bind('isEnabled')
        .to(command);

      this.listenTo(button, 'execute', () => {
        const selection = this.editor.model.document.selection;

        const firstRange = selection.getFirstRange();

        const selectedFormula = firstRange ? getRangeText(firstRange) : '';

        if (!selectedFormula) return this.showUI();

        editor.execute(Keyword.formulaCommand, selectedFormula);
      });

      return button;
    });
  }

  private createFormView() {
    const editor = this.editor;

    const form = new Form(editor.locale, () => this.hideUI());

    form.setSubmitHandler(() => {
      const data = form.getData();

      data && editor.execute(Keyword.formulaCommand, data);

      this.hideUI();
    });

    form.setCancelHandler(() => this.hideUI());

    clickOutsideHandler({
      emitter: form.ckView,
      activator: () => this.balloon.visibleView === form.ckView,
      contextElements: [this.balloon.view.element!],
      callback: () => this.hideUI(),
    });

    return form;
  }

  private showUI(): void;
  private showUI(answer: string): void;
  private showUI(answer: string = ''): void {
    this.balloon.add({
      view: this.form.ckView,
      position: this.getBalloonPosition(),
    });

    this.form.setData(answer);

    this.form.focus();
  }

  private hideUI() {
    this.balloon.remove(this.form.ckView);

    this.editor.editing.view.focus();
  }

  private getBalloonPosition(): Partial<PositionOptions> {
    const view = this.editor.editing.view;
    const viewDocument = view.document;

    const range = viewDocument.selection.getFirstRange();
    if (!range) throw new SuperError(
      `${this.constructor.name}.${this.getBalloonPosition.name}`,
      'range is empty',
    );

    return {
      target: () => view.domConverter.viewRangeToDom(range),
    };
  }
}

(globalThis as any).Ck_Formula_Ui = Ui;