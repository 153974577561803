import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {Main} from '#components/molecules';
import {getEm} from '#includes/utils';
import {ReactElement} from 'react';

export type LoadingProps = {
  children: ReactElement,
};

const styles = {
  title: () => css({
    fontSize: getEm(24),
  }),
};

export const Loading = observer(() => {
  return (
    <Main>
      <h2 css={styles.title()}>
        Загрузка страницы...
      </h2>
    </Main>
  );
});