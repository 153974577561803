import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Collection} from '@ckeditor/ckeditor5-utils';
import {View, ViewCollection} from '@ckeditor/ckeditor5-ui';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseView} from '#includes/content/editor/Views';

type ElementName = 'div' | 'form' | 'progress';
type ElementByName<Name extends ElementName> =
  Name extends 'div'
    ? HTMLDivElement
    : Name extends 'form'
      ? HTMLFormElement
      : Name extends 'progress'
        ? HTMLProgressElement
        : never;

export abstract class ContainerBaseView<
  Name extends ElementName,
  CkView extends View<ElementByName<Name>>,
  Children extends BaseView[],
> extends BaseView<CkView> {
  private readonly ckViews: ViewCollection<Children[number]['ckView']>;

  private readonly children: Collection<Children[number]>;
  public readonly clear;
  public readonly removeChild;
  public readonly addChild;

  protected constructor(
    locale: Locale,
    elementName: Name,
    ckView: CkView,
    className: string | string[],
  ) {
    super(locale, ckView);

    this.ckViews = this.ckView.createCollection();

    this.ckView.setTemplate({
      tag: elementName,
      attributes: {
        class: ['ck', Keyword.containerClass, className].flat(),
        tabindex: '-1',
      },
      children: this.ckViews,
    });

    this.children = new Collection<Children[number]>();
    this.children.on('change', () => {
      // TODO Этот код выполняется в 100 раз чаще, чем нужно
      this.ckViews.clear();
      this.ckViews.addMany(this.children.map(view => view.ckView));
    });

    this.clear = this.children.clear.bind(this.children);
    this.removeChild = this.children.remove.bind(this.children);
    this.addChild = this.children.add.bind(this.children);
  }

  public setChildren(children: Children): void {
    this.children.clear();
    this.children.addMany(children);
  }

  public addChildAfter(child: Children[number], newChild: Children[number]): void {
    const index = this.children.getIndex(child);

    this.insertChildTo(index === -1 ? 0 : index + 1, newChild);
  }

  public addChildBefore(child: Children[number], newChild: Children[number]): void {
    const index = this.children.getIndex(child);

    this.insertChildTo(index === -1 ? Infinity : index, newChild);
  }

  public getChildrenCollection(): Collection<Children[number]> {
    return this.children;
  }

  public getChildrenLength(): number {
    return this.children.length;
  }

  public getChildren(): Children {
    return Array.from(this.children) as Children;
  }

  //

  private insertChildTo(index: number, child: Children[number]): void {
    if (index < 0)
      return void this.children.add(child, 0);

    if (index >= this.children.length)
      return void this.children.add(child);

    this.children.add(child, index);
  }
}