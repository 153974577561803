import {action, computed, makeObservable, observable} from 'mobx';

export type ViewConfig = {
  autoWidth: boolean,
  header: boolean,
  footer: boolean,
};

class View {
  private _config: Readonly<ViewConfig> = View.createConfig();
  private _topOffset: number = 0;
  private _grabbing: boolean = false;

  public constructor() {
    makeObservable<View, '_config' | '_topOffset' | '_grabbing'>(this, {
      _config: observable.ref,
      config: computed,
      setConfig: action.bound,

      _topOffset: observable.ref,
      topOffset: computed,
      setTopOffset: action.bound,

      _grabbing: observable.ref,
      grabbing: computed,
      setGrabbing: action.bound,
    });
  }

  public get topOffset(): number {
    return this._topOffset;
  }

  public setTopOffset(topOffset: number) {
    this._topOffset = topOffset;
  }

  public get config(): Readonly<ViewConfig> {
    return this._config;
  }

  public setConfig(config: Partial<ViewConfig> = {}) {
    this._config = View.createConfig(config);
  }

  public get grabbing(): boolean {
    return this._grabbing;
  }

  public setGrabbing(config: boolean = false) {
    this._grabbing = config;
  }

  //

  private static createConfig(config: Partial<ViewConfig> = {}): Readonly<ViewConfig> {
    return {
      autoWidth: config.autoWidth ?? false,
      header: config.header ?? true,
      footer: config.footer ?? true,
    };
  }
}

export const view = new View();

(globalThis as any).view = view;
