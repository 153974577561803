import {
  BaseView,
  ButtonView,
  ContainerView,
  InputFileView,
  InputView,
} from '#includes/content/editor/Views';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import swapIcon from '#assets/swap.svg';

export enum Mode {
  file = 1,
  text,
}

export class TextOrFileDatum {
  public mode: Mode;
  public value: string;

  public constructor(mode: Mode = Mode.text, value: string = '') {
    this.mode = mode;
    this.value = value;
  }
}

type Children = [InputView, InputFileView, ButtonView];

export class TextOrFileView extends BaseView<ContainerView<Children>['ckView']> {
  private readonly container: ContainerView<Children>;

  private mode: Mode = Mode.text;

  private readonly input: InputView;
  private readonly inputFile: InputFileView;
  private readonly button: ButtonView;

  private constructor(
    locale: Locale,
    className: string | string[],
    label: string = '',
  ) {
    const container = ContainerView.create<Children>(
      locale,
      [className, Keyword.textOrFileClass].flat(),
    );
    super(locale, container.ckView);
    this.container = container;
    this.container.ckView.on('render', this.update.bind(this));

    this.input = InputView.create(locale, [], label);

    this.inputFile = InputFileView.create(locale, [], label);

    this.button = ButtonView.create(
      locale,
      [],
      Keyword.formSwitchTextOrFileLabel,
      swapIcon,
    );
    this.button.setHandler(this.clickHandler.bind(this));

    this.container.setChildren([
      this.input,
      this.inputFile,
      this.button,
    ]);
  }

  public static create(
    locale: Locale,
    className: string | string[],
    label: string = '',
  ): TextOrFileView {
    return new TextOrFileView(
      locale,
      className,
      label,
    );
  }

  public setDatum(datum: TextOrFileDatum): void {
    this.mode = datum.mode;

    this.mode === Mode.file
      ? this.inputFile.setFile(datum.value)
      : this.input.setValue(datum.value);
  }

  public getDatum(): TextOrFileDatum {
    return new TextOrFileDatum(
      this.mode,
      this.mode === Mode.file
        ? this.inputFile.getFile() ?? ''
        : this.input.getValue(),
    );
  }

  public focus() {
    this.mode === Mode.file
      ? this.inputFile.focus()
      : this.input.focus();
  }

  //

  private clickHandler() {
    this.mode = this.mode === Mode.file
      ? Mode.text
      : Mode.file;

    this.update();
    this.focus();
  }

  private update() {
    const element = this.container.ckView.element;
    if (!element) return;

    this.mode === Mode.file
      ? element.classList.add(Keyword.fileModeClass)
      : element.classList.remove(Keyword.fileModeClass);
  }
}