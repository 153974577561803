// noinspection ES6PreferShortImport

import {RoleId} from '#global';
import {AiOutlineHome} from '@react-icons/all-files/ai/AiOutlineHome';
import {HiLogout} from 'react-icons/hi';
import {Route, routes} from './routes';
import {IconType} from 'react-icons';
import {RouteBase} from '#includes/RouteBase';

export class HeaderItemClass<
  R extends Route | never = never,
  B extends RouteBase<string, any> = (typeof routes)[R],
> {
  public readonly content: string | IconType;
  public readonly url?: string;
  public readonly subItems: HeaderItemClass[] = [];

  public constructor(content: string | IconType, to: R, params: Record<B['_typeK'], string | number>);
  public constructor(content: string | IconType, to: B['_typeK'] extends never ? R : never);
  public constructor(content: string | IconType, to: string);
  public constructor(content: string | IconType, subItems: HeaderItemClass[]);
  public constructor(
    content: string | IconType,
    arg2: HeaderItemClass[] | string | R,
    params: Record<B['_typeK'], string | number> = {} as any,
  ) {
    this.content = content;
    if (Array.isArray(arg2)) {
      this.subItems = arg2;
    } else if (typeof arg2 === 'string') {
      this.url = arg2;
    } else {
      this.url = (routes[arg2] as any).createUrl(params);
    }
  }
}

const home: HeaderItemClass = new HeaderItemClass(AiOutlineHome, Route.Home);
const rightNotEmpty: HeaderItemClass[] = [
  new HeaderItemClass(HiLogout, Route.SignOut),
];
const rightEmpty: HeaderItemClass[] = [
  new HeaderItemClass('Вход', Route.SignIn),
  new HeaderItemClass('Регистрация', Route.SignUp),
];
const leftWithoutHome: Record<'empty' | keyof typeof RoleId, HeaderItemClass[]> = (() => {
  const empty: HeaderItemClass[] = [];
  const student: HeaderItemClass[] = [
    new HeaderItemClass('Начать учиться', Route.StudentLevels),
  ];
  const teacher: HeaderItemClass[] = [];
  const author: HeaderItemClass[] = [
    new HeaderItemClass('Админка', Route.AuthorLevels),
  ];
  const admin: HeaderItemClass[] = [
    new HeaderItemClass('Админ', [
      new HeaderItemClass('Файлы', Route.AdminFiles),
      new HeaderItemClass('Пользователи', Route.AdminUsers),
    ]),
    new HeaderItemClass('Автор', Route.AuthorLevels),
    new HeaderItemClass('Учитель', teacher),
    new HeaderItemClass('Ученик', Route.StudentLevels),
  ];

  return {empty, student, teacher, author, admin};
})();

type Header = {
  fontSize: string,
  items: Record<'empty' | RoleId, {
    left: HeaderItemClass[],
    right: HeaderItemClass[],
  }>,
};
export const header: Header = {
  fontSize: '17px',
  items: {
    empty: {
      left: [
        home,
        ...leftWithoutHome.empty,
      ],
      right: rightEmpty,
    },
    [RoleId.admin]: {
      left: [
        home,
        ...leftWithoutHome.admin,
      ],
      right: rightNotEmpty,
    },
    [RoleId.author]: {
      left: [
        home,
        ...leftWithoutHome.author,
      ],
      right: rightNotEmpty,
    },
    [RoleId.teacher]: {
      left: [
        home,
        ...leftWithoutHome.teacher,
      ],
      right: rightNotEmpty,
    },
    [RoleId.student]: {
      left: [
        home,
        ...leftWithoutHome.student,
      ],
      right: rightNotEmpty,
    },
  },
};

(globalThis as any).header = header;