import {Plugin} from '@ckeditor/ckeditor5-core';
import {toWidget, viewToModelPositionOutsideModelElement, Widget} from '@ckeditor/ckeditor5-widget';
import {DowncastWriter, ViewContainerElement, ViewElement} from '@ckeditor/ckeditor5-engine';
import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {isProduction, urls} from '#config';

export class Editing extends Plugin {
  public static get requires() {
    return [Widget];
  }

  public init() {
    this.defineSchema();
    this.defineConverters();
    this.defineChildCheck();

    this.editor.commands.add(
      Keyword.videoCommand,
      new Command(this.editor),
    );

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(
        this.editor.model,
        viewElement => {
          return viewElement.hasClass(Keyword.videoClass);
        },
      ),
    );
  }

  private defineChildCheck(): void {
    // const schema = this.editor.model.schema;
    //
    // schema.addChildCheck((context, childDefinition) => {
    //   if (childDefinition.name !== Keyword.videoModel) return;
    //
    //   return;
    // });
  }

  private defineSchema() {
    const schema = this.editor.model.schema;

    schema.register(Keyword.videoModel, {
      inheritAllFrom: '$blockObject',
      allowAttributes: ['data'],
    });
  }

  private defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'div',
        classes: [Keyword.videoClass],
      },
      model: (element, {writer}) => {
        const video = element.getChild(0);
        if (!(video instanceof ViewElement)) return null;

        const src = (video && video.getAttribute('src')) || '';
        const data = src
          .replace(/.*media\/(.+)$/, '$1');

        return writer.createElement(Keyword.videoModel, {data});
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: Keyword.videoModel,
      view: (element, {writer}) => {
        const data = element.getAttribute('data')
        if (typeof data !== 'string') return null;

        const container = this.createVideoField(writer, data);

        container._setAttribute('ondblclick', 'Ck_Video_Ui.showUI(this)');

        return toWidget(container, writer);
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: Keyword.videoModel,
      view: (element, {writer}) => {
        const data = element.getAttribute('data')
        if (typeof data !== 'string') return null;

        return this.createVideoField(writer, data);
      },
    });
  }

  private createVideoField(
    writer: DowncastWriter,
    data: string,
  ): ViewContainerElement {
    return writer.createContainerElement('div', {
      class: Keyword.videoClass,
    }, [
      writer.createContainerElement('video', {
        src: `${isProduction ? '' : urls.backend}/media/${data}`,
        controls: 'controls'
      }),
    ], {
      renderUnsafeAttributes: ['ondblclick'],
    });
  }
}