class Panel {
  private static readonly rootSelector = '#root';

  private _element: HTMLDivElement | null = null;

  public appendChild(node: Node): void {
    this.init();

    this._element!.appendChild(node);
  }

  public removeChild(node: Node): void {
    if (!this._element) throw new Error(
      `${this.constructor.name}.${this.removeChild.name}: ` +
      `Панель отсутствует`,
    );

    this._element.removeChild(node);

    if (this._element.children.length === 0) {
      this.remove();
    }
  }

  //

  private init(): void {
    if (this._element) return;

    const container = document.querySelector(Panel.rootSelector);
    if (!container) throw new Error(
      `${this.constructor.name}.${this.init.name}: ` +
      `По селектору ${Panel.rootSelector} не удалось получить элемент`,
    );

    for (const child of container.childNodes) {
      if (!(child instanceof HTMLDivElement)) continue;
      if (child.className !== 'draggable-panel') continue;
      child.remove();
    }

    this._element = document.createElement('div');
    this._element.className = 'draggable-panel';
    container.appendChild(this._element);
  }

  private remove(): void {
    if (!this._element) return;

    this._element.remove();
    this._element = null;
  }
}

export const panel = new Panel();