import type {Locale} from '@ckeditor/ckeditor5-utils';
import {icons} from '@ckeditor/ckeditor5-core';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseView, ButtonView, ContainerView} from '#includes/content/editor/Views';

type ContainerChildren<
  Children extends BaseView[]
> = [
  ButtonView,
  ContainerView<Children>,
  ButtonView,
  ButtonView,
];

export class ItemView<
  Children extends BaseView[],
> extends BaseView<ContainerView<ContainerChildren<Children>>['ckView']> {
  declare id: string;

  public readonly getChildrenCollection;

  private readonly beforeButton: ButtonView;

  private readonly contentContainer: ContainerView<Children>;
  public readonly setChildren;

  private readonly removeButton: ButtonView;

  private readonly insertButton: ButtonView;

  private constructor(locale: Locale) {
    const container = ContainerView.create<ContainerChildren<Children>>(
      locale,
      Keyword.itemClass,
    );

    super(locale, container.ckView);
    this.getChildrenCollection = container.getChildrenCollection.bind(container);

    this.beforeButton = ButtonView.create(
      locale,
      Keyword.beforeClass,
      Keyword.formAddLabel,
      icons.plus,
    );

    this.contentContainer = ContainerView.create<Children>(
      locale,
      Keyword.contentClass,
    );
    this.setChildren = this.contentContainer.setChildren.bind(this.contentContainer);

    this.removeButton = ButtonView.create(
      locale,
      Keyword.removeClass,
      Keyword.formRemoveLabel,
      icons.eraser,
    );

    this.insertButton = ButtonView.create(
      locale,
      Keyword.insertClass,
      Keyword.formAddLabel,
      icons.plus,
    );

    container.setChildren([
      this.beforeButton,
      this.contentContainer,
      this.removeButton,
      this.insertButton,
    ]);
  }

  public static create<
    Child extends BaseView[]
  >(locale: Locale): ItemView<Child> {
    return new ItemView<Child>(locale);
  }

  public setBeforeHandler(handler: () => void): void {
    this.beforeButton.setHandler(handler);
  }

  public setRemoveHandler(handler: () => void): void {
    this.removeButton.setHandler(handler);
  }

  public setInsertHandler(handler: () => void): void {
    this.insertButton.setHandler(handler);
  }

  public getChildren(): Children {
    return this.contentContainer.getChildren();
  }
}