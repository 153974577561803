import {action, computed, makeObservable, observable} from 'mobx';
import {Inline, RoleId, UnknownType, UserData} from '#global';
import {ApiRoute} from '#includes/ApiRoute';

class User {
  private _current: UserData | null = null;

  public constructor() {
    this._current = User.getDataByStorage();

    makeObservable<typeof this, '_current'>(this, {
      _current: observable.struct,
      current: computed,
      currentRoleId: computed,
      authorized: computed,
      setData: action.bound,
      clear: action.bound,
    });

    window.setTimeout(() => ApiRoute.getToken.request());
  }

  public get authorized(): boolean {
    return this._current !== null;
  }

  public get current(): UserData | null {
    return this._current;
  }

  public get currentRoleId(): RoleId | null {
    if (!this._current) return null;
    if (this._current.RolesId.length === 0) return null;

    return this._current.RolesId.reduce((p, c) => c <= p ? c : p, RoleId.student);
  }

  public setData(data: UserData): void {
    User.validateUserData(data);

    window.localStorage.user = JSON.stringify(data);

    this._current = data;
  }

  public clear(): void {
    this._current = null;

    delete window.localStorage.user;
  }

  private static validateUserData(data: { [p in keyof Partial<UserData>]: UserData[p] | UnknownType }): UserData {
    if (typeof data !== 'object') throw new Error('data is not object');
    if (typeof data.id !== 'number') throw new Error('data.id is not number');
    if (typeof data.lastName !== 'string') throw new Error('data.lastName is not string');
    if (typeof data.firstName !== 'string') throw new Error('data.firstName is not string');
    if (data.patronymic && typeof data.patronymic !== 'string') throw new Error(
      'data.patronymic is not string');
    if (typeof data.email !== 'string') throw new Error('data.email is not string');

    return data as UserData;
  }

  private static getDataByStorage(): User['_current'] {
    return Inline.tryCatch(
      () => {
        const userInStorage = JSON.parse(window.localStorage['user']);
        return User.validateUserData(userInStorage);
      },
      () => {
        delete window.localStorage['user'];
        return null;
      },
    );
  }
}

type UserWithOrWithoutData = Readonly<{
  authorized: true,
  current: UserData,
} | {
  authorized: false,
  current: null,
}>

export const user = (new User()) as User & UserWithOrWithoutData;

(globalThis as any).user = user;