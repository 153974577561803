import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {AnswerModelName} from '#global';
import {ModelData} from './Form';
import {EditingBase, NodeName} from '#includes/content/editor/plugins/utils';
import {
  SchemaCompiledItemDefinition,
  SchemaContext,
} from '@ckeditor/ckeditor5-engine/src/model/schema';

export class Editing extends EditingBase<ModelData> {
  protected readonly modelName = AnswerModelName.connectionsModel;
  protected readonly modelElement: NodeName = 'div';
  protected readonly isInline = false;
  protected readonly modelClass = Keyword.connectionsClass;
  protected readonly commandsMap = {[Keyword.connectionsCommand]: Command};
  protected readonly uiName = 'Ck_Exercise_Connections_Ui';

  protected childCheck(
    context: SchemaContext,
    definition: SchemaCompiledItemDefinition,
  ): false | void {
    return this.childCheckInExercise(context, definition);
  }

  protected getIsEmpty(
    data: ModelData,
  ): boolean {
    return data.length === 0;
  }
}