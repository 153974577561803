import {HttpMethod, ListData, SubjectArgs, SubjectData, SubjectEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_SubjectsRoute = {
  createSubject: new g_Route<null, SubjectData, SubjectArgs>(
    HttpMethod.post,
    'subjects',
    'Добавить предмет',
  ),
  createSubjects: new g_Route<null, ListData<SubjectData>, SubjectArgs[]>(
    HttpMethod.post,
    'subjects/many',
    'Добавить предметы',
  ),
  getSubjects: new g_Route<null, ListData<SubjectData>>(
    HttpMethod.get,
    'subjects',
    'Получить все предметы',
  ),
  getSubjectsMany: new g_Route<null, ListData<SubjectData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'subjects/many',
    'Получить предметы',
  ),
  getSubject: new g_Route<{ id: number }, SubjectData>(
    HttpMethod.get,
    'subjects/:id',
    'Получить предмет',
  ),
  editSubject: new g_Route<{ id: number }, SubjectData, Partial<SubjectArgs>>(
    HttpMethod.put,
    'subjects/:id',
    'Изменить предмет',
  ),
  editSubjects: new g_Route<null, ListData<SubjectData>, SubjectEditionArgs[]>(
    HttpMethod.put,
    'subjects/many',
    'Изменить предметы',
  ),
  deleteSubject: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'subjects/:id',
    'Удалить предмет',
  ),
  deleteSubjects: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'subjects/many',
    'Удалить предметы',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_SubjectsRoute;
