import {RefObject, useEffect, useState} from 'react';
import {useEventListener} from 'usehooks-ts';

export type Position = {
  x: 'left' | 'right',
  y: 'top' | 'bottom',
};

export const usePosition = (ref: RefObject<HTMLElement>) => {
  const [position, setPosition] = useState<Position>({
    x: 'left',
    y: 'top',
  });

  const computePosition = () => {
    if (!ref.current) return;
    const element = ref.current as HTMLElement;
    const elementRect = element.getBoundingClientRect();

    setPosition(() => ({
      x: window.innerWidth - elementRect.right < elementRect.left ? 'right' : 'left',
      y: elementRect.top > window.innerHeight - elementRect.bottom ? 'bottom' : 'top',
    }));
  };

  useEffect(computePosition, [ref]);

  useEventListener('scroll', computePosition);
  useEventListener('resize', computePosition);

  return [position];
};
