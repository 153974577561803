import {HTMLAttributes} from 'react';
import {observer} from 'mobx-react-lite';

type TabPropsMin = {
  title: string,
};

export type TabProps =
  Pick<HTMLAttributes<HTMLElement>, 'children'>
  & TabPropsMin;

export const Tab = observer<TabProps>(props => {
  const {
    children,
  } = props;

  return (
    <>
      {children}
    </>
  );
});