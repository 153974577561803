import type {Locale} from '@ckeditor/ckeditor5-utils';
import {icons} from '@ckeditor/ckeditor5-core';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseView, ButtonView, ContainerView} from '#includes/content/editor/Views';
import {FocusableView} from './_Focusable';

type ButtonsContainerChildren = [ButtonView, ButtonView];

type FormContainerChildren<Children extends BaseView[]> = [
  ContainerView<Children>,
  ContainerView<ButtonsContainerChildren>,
];

export class FormView<
  Children extends BaseView[],
> extends BaseView<FocusableView<FormContainerChildren<Children>>['ckView']> {
  private readonly form: FocusableView<FormContainerChildren<Children>>;
  public readonly focusFirstInput;
  public readonly updateFocusTracker;

  private readonly contentContainer: ContainerView<Children>;
  public readonly setChildren;

  private readonly buttonsContainer: ContainerView<ButtonsContainerChildren>;

  private readonly submit: ButtonView;
  private readonly cancel: ButtonView;

  private constructor(
    locale: Locale,
    className: Keyword,
    cancelCallback: () => void,
  ) {
    const form = FocusableView.create<FormContainerChildren<Children>>(
      locale,
      [],
      cancelCallback,
    );

    super(locale, form.ckView);

    this.form = form;
    this.focusFirstInput = this.form.focusFirstInput.bind(this.form);
    this.updateFocusTracker = this.form.update.bind(this.form);

    this.contentContainer = ContainerView.create<Children>(locale, className);
    this.setChildren = this.contentContainer.setChildren.bind(this.contentContainer);

    this.buttonsContainer = ContainerView.create<ButtonsContainerChildren>(
      locale,
      Keyword.confirmClass,
    );

    this.submit = ButtonView.create(
      locale,
      Keyword.saveClass,
      Keyword.formSaveLabel,
      icons.check,
    );

    this.cancel = ButtonView.create(
      locale,
      Keyword.cancelClass,
      Keyword.formCancelLabel,
      icons.cancel,
    );

    this.buttonsContainer.setChildren([
      this.submit,
      this.cancel,
    ]);

    this.form.setChildren([
      this.contentContainer,
      this.buttonsContainer,
    ]);

    this.form.ckView
      .delegate('submit')
      .to(this.submit.ckView, 'execute');
  }

  public static create<
    Children extends BaseView[]
  >(
    locale: Locale,
    className: Keyword,
    cancelCallback: () => void,
  ): FormView<Children> {
    return new FormView<Children>(
      locale,
      className,
      cancelCallback,
    );
  }

  public setSubmitHandler(handler: () => void): void {
    this.submit.setHandler(handler);
  }

  public setCancelHandler(handler: () => void): void {
    this.cancel.setHandler(handler);
  }
}