import {DowncastWriter, ViewElementAttributes, ViewNode} from '@ckeditor/ckeditor5-engine';
import {ConverterBase} from '#includes/content/editor/plugins/utils';
import {ConverterError} from '#includes/error';
import {AnswerCheckListData, AnswerModelName} from '#global';
import {ModelData} from './Form';
import {SwitchDatum} from '#includes/content/editor/Forms';
import {Keyword} from '#includes/content/editor/Keyword';

export const Converter = new (class Converter extends ConverterBase<
  AnswerModelName.checkListModel,
  ModelData
> {
  public toAnswerValue(
    container: Element,
    isView: boolean,
  ): AnswerCheckListData['value'] | Error {
    if (!(container instanceof HTMLDivElement)) return new ConverterError(
      `CheckListConverter.${this.toAnswerValue.name}`,
      'container не является Div',
      {container},
    );

    const values: AnswerCheckListData['value'] = [];

    for (const label of container.children) {
      if (!(label instanceof HTMLLabelElement)) return new ConverterError(
        `CheckListConverter.${this.toAnswerValue.name}`,
        'label не является Label',
        {label},
      );

      const input = label.firstChild;
      if (!(input instanceof HTMLInputElement)) return new ConverterError(
        `CheckListConverter.${this.toAnswerValue.name}`,
        'input не является Input',
        {input},
      );

      const text = label.lastChild;
      if (!(text instanceof Text)) return new ConverterError(
        `CheckListConverter.${this.toAnswerValue.name}`,
        'text не является Text',
        {text},
      );

      input.checked && values.push(text.data);
    }

    return values;
  }

  public toView(
    data: Element,
  ): Element | Error {
    return data;
  }

  public activateView(
    view: Element,
  ): Element | Error {
    return view;
  }

  public toData(
    editor: Element,
  ): Element | Error {
    if (!(editor instanceof HTMLDivElement)) return new ConverterError(
      `CheckListConverter.${this.toData.name}`,
      'editor не является Div',
      {editor},
    );

    for (const label of editor.childNodes) {
      if (!(label instanceof HTMLLabelElement)) return new ConverterError(
        `CheckListConverter.${this.toData.name}`,
        'label не является Label',
        {label},
      );

      const nodes = label.childNodes;
      if (nodes.length !== 2) return new ConverterError(
        `CheckListConverter.${this.toData.name}`,
        'У label должно быть строго 2 потомка',
        {label},
      );

      const input = nodes[0];
      if (!(input instanceof HTMLInputElement)) return new ConverterError(
        `CheckListConverter.${this.toData.name}`,
        'input не является Input',
        {input},
      );

      input.removeAttribute('checked');
    }

    return editor;
  }

  public toEditor(
    data: Element,
    answer: AnswerCheckListData['value'],
  ): Element | Error {
    if (!(data instanceof HTMLDivElement)) return new ConverterError(
      `CheckListConverter.${this.toEditor.name}`,
      'data не является Div',
      {data},
    );

    const labels = data.childNodes;
    for (let j = 0; j < labels.length; j++) {
      const label = labels[j];
      if (!(label instanceof HTMLLabelElement)) return new ConverterError(
        `CheckListConverter.${this.toEditor.name}`,
        'label не является Label',
        {label},
      );

      const input = label.firstChild;
      if (!(input instanceof HTMLInputElement)) return new ConverterError(
        `CheckListConverter.${this.toEditor.name}`,
        'input не является Input',
        {input},
      );

      const text = label.lastChild;
      if (!(text instanceof Text)) return new ConverterError(
        `CheckListConverter.${this.toEditor.name}`,
        'text не является Text',
        {text},
      );

      const isCorrect = answer.find(correct => correct === text.data);
      isCorrect && input.setAttribute('checked', 'checked');
    }

    return data;
  }

  public toModelData(
    editor: Node | ViewNode,
  ): ModelData | Error {
    const container = this.toNodeData(editor);
    if (container.isText() || !container.nameIs('div')) return new ConverterError(
      `CheckListConverter.${this.toModelData.name}`,
      'container не является Div',
      {container},
    );

    const data: ModelData = [];

    for (const label of container.children) {
      // Редактор добавляет свои элементы в container
      if (!label.isText() && label.hasClass('ck')) continue;

      if (label.isText() || !label.nameIs('label')) return new ConverterError(
        `CheckListConverter.${this.toModelData.name}`,
        'label не является Label',
        {label},
      );

      const checkbox = label.getChild(0);
      if (!checkbox || checkbox.isText() || !checkbox.nameIs('input')) return new ConverterError(
        `CheckListConverter.${this.toModelData.name}`,
        'checkbox не является Input',
        {checkbox},
      );

      const text = label.getChild(1);
      if (!text || !text.isText()) return new ConverterError(
        `CheckListConverter.${this.toModelData.name}`,
        'text не является Text',
        {text},
      );

      data.push(new SwitchDatum(text.data, checkbox.checked));
    }

    return data;
  }

  public toModel(
    writer: DowncastWriter,
    data: ModelData,
    isEditor: boolean,
  ): { attributes: ViewElementAttributes, children: ViewNode[] } | Error {
    return {
      attributes: {
        class: [Keyword.answerClass, Keyword.checkListClass].join(' '),
      },
      children: data.map(datum => (
        writer.createContainerElement('label', {}, [
          writer.createContainerElement('input', (
            datum.enabled
              ? {type: 'checkbox', checked: 'checked'}
              : {type: 'checkbox'}
          )),
          writer.createText(datum.value),
        ])
      )),
    };
  }
})();