import {css} from '@emotion/react';
import {observer} from 'mobx-react-lite';
import {Route as ClientRoute, routes as clientRoutes} from '#config';
import {Footer, Header, Notifications, SuspenseMain} from '#components/organisms';
import {Home, NotFound, SignIn, SignOut, SignUp} from '#components/pages';
import {theme, view} from '#store';
import {Route, Routes} from 'react-router-dom';
import backgroundTexture from '#assets/texture.png';
import backgroundGrid from '#assets/grid.png';
import {Admin, Author, Student} from '#components/roles';
import {FunctionComponent, useMemo} from 'react';

const a1 = '99';
const a2 = 'BB';

const styles = {
  base: () => css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundImage: [
      `linear-gradient(${theme.current.App.background[1]}${a1}, ${theme.current.App.background[1]}${a1})`,
      `url(${backgroundGrid})`,
      `linear-gradient(${theme.current.App.background[1]}${a2}, ${theme.current.App.background[1]}${a2})`,
      `url(${backgroundTexture})`,
    ].join(),
    backgroundRepeat: 'repeat',
    backgroundSize: 'min(max(22vw, 22vh), 500px)',
  }),
};

const routesSettings: Record<ClientRoute, FunctionComponent> = {
  [ClientRoute.Home]: Home,

  [ClientRoute.SignIn]: SignIn,
  [ClientRoute.SignUp]: SignUp,
  [ClientRoute.SignOut]: SignOut,

  [ClientRoute.AdminUsers]: Admin.Roles,
  [ClientRoute.AdminFiles]: Admin.Files,

  [ClientRoute.AuthorLevels]: Author.Levels,
  [ClientRoute.AuthorSubjects]: Author.Subjects,
  [ClientRoute.AuthorSections]: Author.Sections,
  [ClientRoute.AuthorTopics]: Author.Topics,
  [ClientRoute.AuthorParagraphs]: Author.Paragraphs,
  [ClientRoute.AuthorParagraph]: Author.Paragraph,
  [ClientRoute.AuthorViewer]: Author.Viewer,

  [ClientRoute.StudentLevels]: Student.Levels,
  [ClientRoute.StudentParagraph]: Student.Paragraph,

  [ClientRoute.NotFound]: NotFound,
};

export const Page = observer(() => {
  const routes = useMemo(() => (
    <Routes>
      {Object.entries(routesSettings).map(([id, Component]) => (
        <Route
          key={id} path={String(clientRoutes[Number(id) as ClientRoute])} element={
          <SuspenseMain>
            <Component/>
          </SuspenseMain>
        }
        />
      ))}
    </Routes>
  ), [routesSettings]);

  return (
    <div css={styles.base()}>
      {view.config.header && <Header/>}
      {routes}
      {view.config.footer && <Footer/>}
      <Notifications/>
    </div>
  );
});