import './init-localization';

import {ClassicEditor} from '@ckeditor/ckeditor5-editor-classic';
import {Alignment} from '@ckeditor/ckeditor5-alignment';
import {Autoformat} from '@ckeditor/ckeditor5-autoformat';
import {Autosave} from '@ckeditor/ckeditor5-autosave';
import {
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
} from '@ckeditor/ckeditor5-basic-styles';
import {Essentials} from '@ckeditor/ckeditor5-essentials';
import {Heading, Title} from '@ckeditor/ckeditor5-heading';
import {Highlight} from '@ckeditor/ckeditor5-highlight';
import {HorizontalLine} from '@ckeditor/ckeditor5-horizontal-line';
import {GeneralHtmlSupport} from '@ckeditor/ckeditor5-html-support';
import {
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
} from '@ckeditor/ckeditor5-image';
import {Indent, IndentBlock} from '@ckeditor/ckeditor5-indent';
import {AutoLink, Link, LinkImage} from '@ckeditor/ckeditor5-link';
import {List, ListProperties} from '@ckeditor/ckeditor5-list';
import {PageBreak} from '@ckeditor/ckeditor5-page-break';
import {Paragraph} from '@ckeditor/ckeditor5-paragraph';
import {PasteFromOffice} from '@ckeditor/ckeditor5-paste-from-office';
import {RemoveFormat} from '@ckeditor/ckeditor5-remove-format';
import {SelectAll} from '@ckeditor/ckeditor5-select-all';
import {SourceEditing} from '@ckeditor/ckeditor5-source-editing';
import {Style} from '@ckeditor/ckeditor5-style';
import {Table, TableCaption, TableColumnResize, TableToolbar} from '@ckeditor/ckeditor5-table';

import {Keyword} from './Keyword';
import {CustomUploadAdapter, Exercise, modelConverter, Video, Audio, Formula} from './plugins';

import {EditorConfig} from '@ckeditor/ckeditor5-core/src/editor/editorconfig';
import pencil from '@ckeditor/ckeditor5-core/theme/icons/pencil.svg';
import {theme} from '#store';

export class CustomEditor extends ClassicEditor {
  public static override builtinPlugins = [
    Video,
    Audio,
    Formula,
    Exercise,

    Alignment,
    AutoLink,
    Autoformat,
    Autosave,
    Bold,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    SourceEditing,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableColumnResize,
    TableToolbar,
    Title,
    Underline,

    CustomUploadAdapter,
  ];

  public static override defaultConfig = {
    language: 'ru',
    toolbar: {
      items: [
        'sourceEditing',
        '|',
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        {
          label: 'Другие стили текста',
          items: [
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
          ],
        },
        'highlight',
        'removeFormat',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
        'alignment',
        '|',
        'link',
        'imageUpload',
        Keyword.videoCommand,
        Keyword.audioCommand,
        Keyword.formulaCommand,
        'insertTable',
        'pageBreak',
        'horizontalLine',
        '-',
        'heading',
        'style',
        {
          label: 'Упражнения',
          icon: pencil,
          items: [
            Keyword.widgetModel,
            '-',
            Keyword.textCommand,
            '-',
            Keyword.dropListCommand,
            '-',
            Keyword.checkListCommand,
            '-',
            Keyword.sortingCommand,
            '-',
            Keyword.connectionsCommand,
            '-',
            Keyword.orderedCommand,
          ],
        },
      ],
      shouldNotGroupWhenFull: true,
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        'toggleImageCaption',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage',
      ],
    },
    table: {
      contentToolbar: [
        'toggleTableCaption',
        'tableColumn',
        'tableRow',
        'mergeTableCells',
      ],
    },
    heading: {
      options: [
        {model: 'paragraph', title: 'Простой текст', class: 'p'},
        {model: 'heading1', view: 'h2', title: 'Заголовок', class: 'h2'},
        {model: 'heading2', view: 'h3', title: 'Подзаголовок', class: 'h3'},
      ],
    },
    highlight: {
      options: [
        {
          model: 'themePen',
          class: 'colored-theme',
          title: 'Ручка темы',
          color: theme.current.basePalette.hard,
          type: 'pen',
        },
        {
          model: 'redPen',
          class: 'colored-red',
          title: 'Красная ручка',
          color: '#FF0000',
          type: 'pen',
        },
        {
          model: 'orangePen',
          class: 'colored-orange',
          title: 'Оранжевая ручка',
          color: '#ffa600',
          type: 'pen',
        },
        {
          model: 'yellowPen',
          class: 'colored-yellow',
          title: 'Жёлтая ручка',
          color: '#e9e900',
          type: 'pen',
        },
        {
          model: 'greenPen',
          class: 'colored-green',
          title: 'Зелёная ручка',
          color: '#14ff14',
          type: 'pen',
        },
        {
          model: 'cyanPen',
          class: 'colored-cyan',
          title: 'Голубая ручка',
          color: '#59ffff',
          type: 'pen',
        },
        {
          model: 'bluePen',
          class: 'colored-blue',
          title: 'Синяя ручка',
          color: '#5778ff',
          type: 'pen',
        },
        {
          model: 'purplePen',
          class: 'colored-purple',
          title: 'Фиолетовая ручка',
          color: '#ff1aff',
          type: 'pen',
        },
      ],
    },
    style: {
      definitions: [
        {
          name: 'Красная строка',
          element: 'p',
          classes: ['red-line'],
        },
      ],
    },
    alignment: {
      options: [
        {name: 'left', className: 'align-left'},
        {name: 'center', className: 'align-center'},
        {name: 'right', className: 'align-right'},
        {name: 'justify', className: 'align-justify'},
      ],
    },
    // autosave: {
    //   save: (editor) => {
    //     return new Promise<unknown>(resolve => {
    //       window.localStorage.setItem('ck-autosave', editor.);
    //       resolve(undefined);
    //     });
    //   },
    //   waitingTime: 1000,
    // },
  } as EditorConfig;
}

export {
  modelConverter,
};