import {lazy} from 'react';

export const Author = {
  Levels: lazy(() => import('./preIndex')
    .then(module => ({default: module.Levels}))),
  Subjects: lazy(() => import('./preIndex')
    .then(module => ({default: module.Subjects}))),
  Sections: lazy(() => import('./preIndex')
    .then(module => ({default: module.Sections}))),
  Topics: lazy(() => import('./preIndex')
    .then(module => ({default: module.Topics}))),
  Paragraphs: lazy(() => import('./preIndex')
    .then(module => ({default: module.Paragraphs}))),
  Paragraph: lazy(() => import('./preIndex')
    .then(module => ({default: module.Paragraph}))),
  Viewer: lazy(() => import('./preIndex')
    .then(module => ({default: module.Viewer}))),
};

// noinspection JSUnusedLocalSymbols
const security: LazyComponentsGroup = Author;