import {css} from '@emotion/react';
import {theme} from '#store';
import {borders, palette} from '#config';
import {getEm} from '#includes/utils';
import {Keyword} from '#includes/content/editor/Keyword';

export const content = css`
  figure {
    margin-inline: auto;
    margin-block: ${getEm(7)};
    
    &.table {
      table {
        border-collapse: collapse;

        & th {
          background-color: ${theme.current.basePalette.white};
          font-weight: bold;
          color: ${theme.current.basePalette.hard};
        }

        & th, & td {
          border: solid ${borders.width.small} ${palette.gray.soft};
          padding: ${getEm(7)};
          text-align: left;
        }
      }
    }

    &.image {
      &.image_resized {
        margin-inline: auto;
      }

      & img {
        width: 100%;
      }

      & figcaption {
        background-color: transparent;
        font-size: 0.75em;
        font-style: italic;
      }
    }
  }

  strong {
    font-weight: bold;
  }

  hr {
    border-width: ${borders.width.base};
    border-style: none;
    border-top-style: solid;
    border-color: ${palette.black};
    background-color: transparent;
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .align-justify {
    text-align: justify;
  }

  .ck-content, .cv-content {
    display: flex;
    flex-direction: column;
  }
  
  p:not(:last-of-type),
  .exercise-submit-container:not(:last-of-type),
  .${Keyword.widgetClass}:not(:last-of-type) {
    margin-bottom: ${getEm(7)};
  }

  // .ck-content, .cv-content, td, th, .${Keyword.panelClass} {
  //   gap: ${getEm(7)}; // TODO Gap не работает в table-cell и block
  // }
`;