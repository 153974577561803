import {BaseData, PermissionId} from '../../';

export enum RoleId {
  admin = 1,
  author,
  teacher,
  student,
}

export type RoleData = BaseData<{
  id: RoleId,
  name: string,
  PermissionsId: PermissionId[],
}>;

export const roles: { readonly [id in RoleId]: RoleData } = {
  [RoleId.admin]: {
    id: RoleId.admin,
    name: 'Админ',
    PermissionsId: [
      // PermissionId.databaseManagement,
      // PermissionId.userManagement,
    ],
  },
  [RoleId.author]: {
    id: RoleId.author,
    name: 'Автор',
    PermissionsId: [],
  },
  [RoleId.teacher]: {
    id: RoleId.teacher,
    name: 'Учитель',
    PermissionsId: [],
  },
  [RoleId.student]: {
    id: RoleId.student,
    name: 'Ученик',
    PermissionsId: [],
  },
};
