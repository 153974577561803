import {BaseData, DifficultyData, ParagraphData, TagData} from '../../';

export enum AnswerModelName {
  textModel = 'exerciseTextAnswer',
  dropListModel = 'exerciseDropListAnswer',
  checkListModel = 'exerciseCheckListAnswer',
  sortingModel = 'exerciseSortingAnswer',
  connectionsModel = 'exerciseConnectionsAnswer',
  orderedModel = 'exerciseOrderedAnswer',
}

type CreateAnswerData<
  Model extends AnswerModelName,
  Data extends any
> = {
  model: Model,
  value: Data,
};

export type AnswerTextData = CreateAnswerData<AnswerModelName.textModel, string>;
export type AnswerDropListData = CreateAnswerData<AnswerModelName.dropListModel, string>;
export type AnswerCheckListData = CreateAnswerData<AnswerModelName.checkListModel, string[]>;
export type AnswerSortingData = CreateAnswerData<AnswerModelName.sortingModel, string[][]>;
export type AnswerConnectionsData = CreateAnswerData<AnswerModelName.connectionsModel, number[]>;
export type AnswerOrderedData = CreateAnswerData<AnswerModelName.orderedModel, number[]>;

export type AnswerData =
  AnswerTextData
  | AnswerDropListData
  | AnswerCheckListData
  | AnswerSortingData
  | AnswerConnectionsData
  | AnswerOrderedData;

export type AnswerDataBy<M extends AnswerModelName> = AnswerData & { model: M };
export type AnswerValueBy<M extends AnswerModelName> = AnswerDataBy<M>['value'];

export type ExerciseMetaData = {
  help: string,
};

export type ExerciseData = BaseData<{
  id: number,
  name?: string,
  DifficultyId: DifficultyData['id'],
  ParagraphsId: ParagraphData['id'][],
  TagsId: TagData['id'][],
  data: AnswerData[],
  meta: ExerciseMetaData,
}>;

export type ExerciseArgs = Readonly<
  Pick<ExerciseData, 'name' | 'DifficultyId' | 'data' | 'meta'>
  & Partial<Pick<ExerciseData, 'ParagraphsId' | 'TagsId'>>
>;

export type ExerciseEditionArgs = Readonly<
  Partial<ExerciseArgs>
  & Pick<ExerciseData, 'id'>
>;