import {action, computed, makeObservable, observable} from 'mobx';

export enum DeviceType {
  phone = 1,
  tablet,
  monitor,
}

type DeviceSettings = {
  readonly type: DeviceType,
  readonly range: { min: number, max: number },
  readonly width: string,
};

class Device {
  private readonly _settings: DeviceSettings[] = [
    {type: DeviceType.phone, range: {min: -Infinity, max: 575}, width: '100%'},
    {type: DeviceType.tablet, range: {min: 575, max: 975}, width: `${575}px`},
    {type: DeviceType.monitor, range: {min: 975, max: Infinity}, width: `${975}px`},
  ];

  private _current: DeviceSettings = this._settings[0];

  public constructor() {
    makeObservable<typeof this, '_current'>(this, {
      _current: observable.ref,
      current: computed,
      updateByWidth: action.bound,
    });
  }

  public get current() {
    return this._current;
  }

  public getByType(type: DeviceType): DeviceSettings {
    return this._settings.find(setting => setting.type === type)!;
  }

  public updateByWidth(width: number) {
    this._current = this._settings.find(({range: {min, max}}) => min <= width && width < max)!;
  }
}

export const device = new Device();

(globalThis as any).device = device;
