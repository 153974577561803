import {ParagraphData} from '#global';
import {modelConverter} from '#includes/content';
import {ConverterPattern} from './stringsConverters';
import {isProduction, urls} from '#config';
import {modelClasses} from '#includes/content/editor/plugins';
import {Keyword} from '#includes/content/editor/Keyword';

function toViewFileMode(
  content: string,
): string {
  const classAttr = `class="${Keyword.fileModeClass}"`;

  const fileModePattern = new RegExp(`<span ${classAttr}>([^<]+)</span>`, 'g');

  const styleAttr = 'style="background-image: url(\'/media/$1\')"';
  const fileModeReplacer = `<span ${classAttr} ${styleAttr}>$1</span>`;

  return content.replaceAll(
    fileModePattern,
    fileModeReplacer,
  );
}

function toViewStrings(
  content: string,
): string {
  const viewFileModeContent = toViewFileMode(content);

  if (isProduction) return viewFileModeContent;

  return viewFileModeContent.replaceAll(
    ConverterPattern.media,
    `${urls.backend}/media/`,
  );
}

export function toViewContent(
  dataContent: NonNullable<ParagraphData['content']>,
): string[] | Error {
  const viewStringsContent = toViewStrings(dataContent);

  const contentBody = new DOMParser()
    .parseFromString(viewStringsContent, 'text/html')
    .body;

  const exercises = contentBody.getElementsByClassName(Keyword.widgetClass);
  for (let exerciseIndex = exercises.length - 1; exerciseIndex >= 0; exerciseIndex--) {
    const exercise = exercises[exerciseIndex];

    // Внутренний контейнер упражнения заменяется на его содержимое
    exercise.childNodes[1].replaceWith(...exercise.childNodes[1].childNodes);

    for (const [model, className] of modelClasses) {
      const answers = exercise.getElementsByClassName(className);
      for (let answerIndex = answers.length - 1; answerIndex >= 0; answerIndex--) {
        const answer = answers[answerIndex];

        const convertedAnswer = modelConverter[model].toView(answer);
        if (convertedAnswer instanceof Error) return convertedAnswer;

        convertedAnswer.setAttribute(
          'name',
          `${model}-${exerciseIndex}-${answerIndex}`,
        );

        answer.replaceWith(convertedAnswer);
      }
    }

    const submitContainer = document.createElement('div');
    submitContainer.className = 'exercise-submit-container';

    const help = document.createElement('button');
    help.setAttribute('onclick', 'return false');
    help.className = 'exercise-meta-help';
    help.name = `help-${exerciseIndex}`;
    submitContainer.appendChild(help);

    const submit = document.createElement('input');
    submit.type = 'submit';
    submit.className = 'exercise-submit';
    submit.name = `submit-${exerciseIndex}`;
    submitContainer.appendChild(submit);

    // Контейнер упражнения заменяется на его содержимое и добавляется кнопка
    exercise.replaceWith(...exercise.childNodes, submitContainer);
  }

  const contentHtml = contentBody.innerHTML;
  const pagesHtml = contentHtml.split(ConverterPattern.pageSeparator);

  return pagesHtml;
}