import {memo, ReactElement} from 'react';
import {css, keyframes} from '@emotion/react';

type LoaderProps = {
  children?: ReactElement,
  isLoading: boolean,
};

const scale = 250;

const keyframeNames = {
  base: keyframes({
    '0%': {
      backgroundPositionX: `calc(min(${scale}vw, ${scale}vh) * 2)`,
    },
    '100%': {
      backgroundPositionX: `min(${scale}vw, ${scale}vh)`,
    },
  }),
};

const styles = {
  base: (isLoading: boolean) => css({
    position: 'absolute',
    display: 'none',
    '& + *': {
      position: 'relative',
      pointerEvents: isLoading ? 'none' : 'all',
      userSelect: isLoading ? 'none' : 'initial',
      overflow: 'hidden',
      '&:before': {
        content: '""',
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        backgroundImage: 'linear-gradient(135deg, transparent, #888, transparent, #888, transparent)',
        backgroundSize: isLoading
          ? `min(${scale}vw, ${scale}vh) min(${scale}vw, ${scale}vh)`
          : `calc(min(${scale}vw, ${scale}vh) * 2) calc(min(${scale}vw, ${scale}vh) * 2)`,
        transition: '500ms ease-out',
        animation: `${keyframeNames.base} 2s linear infinite`,
        opacity: isLoading ? 0.5 : 0,
      },
    },
  }),
};

export const Loader = memo<LoaderProps>(props => {
  const {
    children,
    isLoading,
  } = props;

  return (
    <>
      <div css={styles.base(isLoading)}/>
      {children}
    </>
  );
});