import {g_Route} from '../utils';
import {g_AuthRoute} from './auth';
import {g_RootRoute} from './root';
import {g_TablesRoute} from './tables';
import {g_ClassesRoute} from '../entities/Class/routes';
import {g_DifficultiesRoute} from '../entities/Difficulty/routes';
import {g_ExercisesRoute} from '../entities/Exercise/routes';
import {g_FilesRoute} from '../entities/File/routes';
import {g_IpsRoute} from '../entities/Ip/routes';
import {g_TagsRoute} from '../entities/Tag/routes';
import {g_LevelsRoute} from '../entities/Level/routes';
import {g_LogsRoute} from '../entities/Log/routes';
import {g_ParagraphsRoute} from '../entities/Paragraph/routes';
import {g_PermissionsRoute} from '../entities/Permission/routes';
import {g_RolesRoute} from '../entities/Role/routes';
import {g_SectionsRoute} from '../entities/Section/routes';
import {g_StatusesRoute} from '../entities/Status/routes';
import {g_SubjectsRoute} from '../entities/Subject/routes';
import {g_TopicsRoute} from '../entities/Topic/routes';
import {g_UsersRoute} from '../entities/User/routes';
import {g_UserMetadataRoute} from '../entities/UserMetadatum/routes';
import {g_UserSectionsRoute} from '../entities/UserSection/routes';
import {g_UserTopicsRoute} from '../entities/UserTopic/routes';
import {g_VariantsRoute} from '../entities/Variant/routes';

export const g_ApiRoute = {
  ...g_AuthRoute,
  ...g_TablesRoute,
  ...g_RootRoute,
  ...g_ClassesRoute,
  ...g_DifficultiesRoute,
  ...g_ExercisesRoute,
  ...g_FilesRoute,
  ...g_IpsRoute,
  ...g_TagsRoute,
  ...g_LevelsRoute,
  ...g_LogsRoute,
  ...g_ParagraphsRoute,
  ...g_PermissionsRoute,
  ...g_RolesRoute,
  ...g_SectionsRoute,
  ...g_StatusesRoute,
  ...g_SubjectsRoute,
  ...g_TopicsRoute,
  ...g_UsersRoute,
  ...g_UserMetadataRoute,
  ...g_UserSectionsRoute,
  ...g_UserTopicsRoute,
  ...g_VariantsRoute,
} as const satisfies { [p: string]: g_Route<any, any, any, any> };