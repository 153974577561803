import {Collection, Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {InputView, ItemView, ListView} from '#includes/content/editor/Views';
import {BaseForm} from '#includes/content/editor/Forms';
import {isTruthy} from '#includes/isTruthy';

export class ColumnData {
  public name: string;
  public values: string[];

  public constructor(name: string = '', values: string[] = []) {
    this.name = name;
    this.values = values;
  }
}

export type ModelData = ColumnData[];

class ColumnView extends ListView<[InputView], string> {
  protected readonly columnCollection: Collection<ItemView<[InputView]>>;

  private constructor(locale: Locale) {
    super(locale);
    this.columnCollection = this.getChildrenCollection();
  }

  public static create(locale: Locale) {
    return new ColumnView(locale);
  }

  public getData(): ColumnData {
    const [name, ...values] = this.columnCollection.map(item => {
      const [inputView] = item.getChildren();
      return inputView.getValue();
    });

    return new ColumnData(name, values);
  }

  public focus() {
    const cell = this.getChildrenCollection().first;
    const input = cell?.getChildren()?.[0];
    input?.focus();
  }

  //

  protected itemChildrenCreation(
    _item: ItemView<[InputView]>,
    datum?: string,
  ): [InputView] {
    const inputView = InputView.create(this.locale, []);
    datum && inputView.setValue(datum);

    return [
      inputView,
    ];
  }

  protected checkEmpty(): void {
    const length = this.getChildrenLength();
    if (length === 0) return void this.setItems(['', '']);
    if (length === 1) return void this.addChild(this.createItem(''));
  }
}

class TableView extends ListView<[ColumnView], ColumnData> {
  private addColumnHandler?: () => void;

  protected readonly columnCollection: Collection<ItemView<[ColumnView]>>;

  private constructor(locale: Locale) {
    super(locale);
    this.columnCollection = this.getChildrenCollection();
  }

  public static create(locale: Locale) {
    return new TableView(locale);
  }

  public getData(): ModelData {
    return this.columnCollection.map(item => {
      const [columnView] = item.getChildren();
      const data = columnView.getData();
      data.values = data.values.filter(isTruthy);
      return data;
    }).filter(data => data.values.length !== 0 || data.name);
  }

  public setAddColumnHandler(handler: () => void) {
    this.addColumnHandler = handler;
  }

  //

  protected itemChildrenCreation(
    _item: ItemView<[ColumnView]>,
    datum?: ColumnData,
  ): [ColumnView] {
    const columnView = ColumnView.create(this.locale);
    if (datum) columnView.setItems([datum.name, ...datum.values]);
    this.updateHandler && columnView.setUpdateHandler(this.updateHandler.bind(this));

    return [
      columnView,
    ];
  }

  protected checkEmpty(): void {
    const length = this.getChildrenLength();

    if (length === 0) {
      this.setItems([new ColumnData(), new ColumnData()]);
      this.addColumnHandler && this.addColumnHandler();
      return;
    }

    if (length === 1) {
      this.addChild(this.createItem(new ColumnData()));
      this.addColumnHandler && this.addColumnHandler();
      return;
    }
  }
}

export class Form extends BaseForm<[TableView], ModelData> {
  private readonly table: TableView;
  public readonly setAddColumnHandler;
  public readonly getData;

  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.sortingFormClass, cancelCallback);

    this.table = TableView.create(this.locale);
    this.setAddColumnHandler = this.table.setAddColumnHandler.bind(this.table);
    this.table.setUpdateHandler(() => {
      this.form.updateFocusTracker();
    });
    this.getData = this.table.getData.bind(this.table);

    this.form.setChildren([
      this.table,
    ]);
  }

  public override setData(data: ModelData): void {
    this.table.setItems(data);
    super.setData(data);
  }
}