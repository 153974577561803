import {clickableStyles, containerStyles} from '#components/atoms';
import {theme} from '#store';
import {css} from '@emotion/react';

export const ContainerStyle = (() => {
  const Outlined = containerStyles.base(
    'outlined',
    'base',
    'solid',
    'base',
    theme.current.Container(theme.current.baseColor)['outlined'],
  );

  const Filled = containerStyles.base(
    'filled',
    'base',
    'solid',
    'base',
    theme.current.Container(theme.current.baseColor)['filled'],
  );

  const Dashed = containerStyles.base(
    'outlined',
    'small',
    'dashed',
    'base',
    theme.current.Container(theme.current.baseColor)['outlined'],
  );

  const Clickable = css([
    Outlined,
    clickableStyles.base(
      'outlined',
      'solid',
      theme.current.Clickable(theme.current.baseColor)['outlined'],
      false,
      false,
      true,
    ),
  ]);

  const ClickableFilled = css([
    Filled,
    clickableStyles.base(
      'filled',
      'solid',
      theme.current.Clickable(theme.current.baseColor)['filled'],
      false,
      false,
      true,
    ),
  ]);

  return {Filled, ClickableFilled, Outlined, Dashed, Clickable} as const;
})();