import {css} from '@emotion/react';
import {ContainerStyle} from '#includes/ContainerStyle';
import {getEm} from '#includes/utils';

export const content = css`

  .exercise-submit-container {
    justify-content: flex-end;
    display: flex;
    gap: ${getEm(7)};
    margin-top: ${getEm(7)};

    & .exercise-meta-help {
      ${ContainerStyle.ClickableFilled};

      width: 2em;
      justify-content: center;

      &::after {
        content: "?";
      }
    }

    & .exercise-submit {
      ${ContainerStyle.ClickableFilled};
    }
  }
`;