import {action, computed, makeObservable, observable} from 'mobx';
import {isTruthy} from '#includes/isTruthy';

export type BreadcrumbsItem = {
  content: string,
  url?: string,
};

class Breadcrumbs {
  private _items: ReadonlyArray<Readonly<BreadcrumbsItem>> = [];

  public constructor() {
    makeObservable<typeof this, '_items'>(this, {
      _items: observable.ref,
      items: computed,
      set: action.bound,
      clear: action.bound,
    });
  }

  public get items(): ReadonlyArray<Readonly<BreadcrumbsItem>> {
    return this._items;
  }

  public set(item: (BreadcrumbsItem | Falsy)[]) {
    this._items = item.filter(isTruthy);
  }

  public clear(): void {
    this._items = [];
  }
}

export const breadcrumbs = new Breadcrumbs();

(globalThis as any).breadcrumbs = breadcrumbs;
