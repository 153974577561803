import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import ReactAudioPlayer from 'react-audio-player';
import React from 'react';

type AudioPropsMin = {
  volume?: number,
};

type ReactAudioPlayerProps = React.ComponentProps<typeof ReactAudioPlayer>

export type AudioProps =
  Omit<ReactAudioPlayerProps, keyof AudioPropsMin>
  & AudioPropsMin;

const styles = {
  base: () => css`
    width: 100%;
  `,
};

export const Audio = observer<AudioProps>(props => {
  const {
    volume = 1,
    ...otherProps
  } = props;

  return (
    <ReactAudioPlayer
      css={styles.base()}
      controls={true}
      volume={volume}
      {...otherProps}
    />
  );
});