import type {Locale} from '@ckeditor/ckeditor5-utils';
import {InputView} from '#includes/content/editor/Views';
import {BaseForm} from '#includes/content/editor/Forms';
import {Keyword} from '#includes/content/editor/Keyword';

export type ModelData = string;

export class Form extends BaseForm<[InputView], ModelData> {
  private readonly input: InputView;

  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.textFormClass, cancelCallback);

    this.input = InputView.create(
      locale,
      [],
      Keyword.formCorrectLabel,
    );

    this.form.setChildren([
      this.input,
    ]);
  }

  public getData(): ModelData {
    return this.input.getValue();
  }

  public override setData(data: ModelData): void {
    this.input.setValue(data);
    super.setData(data);
  }
}