import {Element, Writer} from '@ckeditor/ckeditor5-engine';
import {CommandBase} from '../../utils';
import {Keyword} from '#includes/content/editor/Keyword';

export class Command extends CommandBase {
  public override execute(): void {
    this.editor.model.change(writer => {
      this.editor.model.insertObject(
        Command.createWidget(writer),
      );
    });
  }

  public override refresh(): void {
    const model = this.editor.model;
    const selection = model.document.selection;
    const position = selection.getFirstPosition();
    const allowedIn = position && model.schema.findAllowedParent(
      position,
      Keyword.widgetModel,
    );

    this.isEnabled = allowedIn !== null;
  }

  private static createWidget(writer: Writer): Element {
    const widget = writer.createElement(Keyword.widgetModel);

    const metaContainer = writer.createElement(Keyword.metaContainerModel);
    writer.append(metaContainer, widget);

    const meta = writer.createElement(Keyword.metaModel, {
      data: ['Нет подсказки', ['Без меток']] as [string, string[]],
    });
    writer.append(meta, metaContainer);

    const panel = writer.createElement(Keyword.panelModel);
    writer.append(panel, widget);
    writer.appendElement('paragraph', panel);

    return widget;
  }
}