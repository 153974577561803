import {HttpMethod, ListData, LogData} from '../../';
import {g_Route} from '../../utils';

export const g_LogsRoute = {
  getLogs: new g_Route<null, ListData<LogData>>(
    HttpMethod.get,
    'logs',
    'Получить все логи',
  ),
  getLogsMany: new g_Route<null, ListData<LogData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'logs/many',
    'Получить логи',
  ),
  getLog: new g_Route<{ id: number }, LogData>(
    HttpMethod.get,
    'logs/:id',
    'Получить лог',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_LogsRoute;
