import {Interactive, InteractiveProps} from './Interactive';
import type {Insertable} from './Insertable';
import {Rectangle, Vector2} from '#includes/geometry';
import {panel} from './panel';
import {view} from '#store';
import {cursor} from './cursor';

export type DraggableProps = InteractiveProps & {
  target: Symbol,
};

export class Draggable extends Interactive {
  protected static readonly throttling: boolean = false;

  protected static readonly hairbreadth: number = 1;
  protected static readonly tickSpeed: number = Draggable.throttling ? 300 : 30;
  protected static readonly speed: number = Draggable.throttling ? 0.9 : 0.3;

  public static dragged: Draggable | null;

  private readonly position: Vector2 = new Vector2();
  private active: boolean = false;
  private parent: Insertable | null = null;
  private readonly target: Symbol;
  private readonly clone: HTMLElement;

  private constructor(props: DraggableProps) {
    const {
      element,
      target,
    } = props;

    super(props);

    this.bindMethods();

    this.target = target;
    this.clone = element.cloneNode(true) as HTMLElement;

    panel.appendChild(this.clone);

    this.setEventListeners();
  }

  public static create(props: DraggableProps): Draggable {
    return new Draggable(props);
  }

  public setParent(parent: Insertable | null): void {
    if (this.parent) this.parent.removeChild(this);

    this.parent = parent;

    if (!this.parent) return;

    this.parent.addChild(this);
  }

  public getParent(): Insertable | null {
    return this.parent;
  }

  public override deactivate(): void {
    super.deactivate();

    this.removeEventListeners();

    panel.removeChild(this.clone);

    Draggable.dragged === this && view.setGrabbing(false);
  }

  public targetIs(target: Symbol): boolean {
    return this.target === target;
  }

  //

  private limit(position: Vector2): Vector2 {
    const halfSize = Vector2.fromElementSize(this.clone).divide(2);
    const clone = new Rectangle(
      position.subtract(halfSize),
      position.add(halfSize),
    );

    const limit = Rectangle.fromElement(this.limitElement);

    const problem = {
      left: clone.left < limit.left,
      top: clone.top < limit.top,
      right: limit.right < clone.right,
      bottom: limit.bottom < clone.bottom,
    };

    if (problem.left && problem.right) {
      position.x = limit.left + (limit.right - limit.left) / 2;
    } else {
      if (problem.left) {
        position.x = limit.left + halfSize.x;
      } else if (problem.right) {
        position.x = limit.right - halfSize.x;
      }
    }

    if (problem.top && problem.bottom) {
      position.y = limit.top + (limit.bottom - limit.top) / 2;
    } else {
      if (problem.top) {
        position.y = limit.top + halfSize.y;
      } else if (problem.bottom) {
        position.y = limit.bottom - halfSize.y;
      }
    }

    return position;
  }

  private updateStyle(): void {
    const thisDragged = Draggable.dragged === this;
    const aboveThis = cursor.aboveDraggable === this;
    const size = Vector2.fromElementSize(this.element);

    this.clone.style.zIndex = thisDragged ? '2' : aboveThis ? '1' : '';
    this.clone.style.opacity = this.active ? '1' : '0';
    this.clone.style.left = `${this.position.x}px`;
    this.clone.style.top = `${this.position.y}px`;
    this.clone.style.width = `${size.x}px`;
    this.clone.style.height = `${size.y}px`;

    this.element.style.opacity = this.active ? '0.1' : '1';
  }

  private setPosition(target: Vector2, force?: boolean): void {
    if (force) return void this.position.set(target);

    const difference = target.subtract(this.position);
    const differenceLength = difference.getLength();

    const isDifferent = differenceLength >= Draggable.hairbreadth;

    const newPosition = isDifferent
      ? this.position.add(difference.multiply(Draggable.speed))
      : target;

    const limitedPosition = target
      ? newPosition
      : this.limit(newPosition);

    this.position.set(limitedPosition);

    this.active = isDifferent || Draggable.dragged === this;
  }

  private onStart(event: MouseEvent | TouchEvent): void | false {
    if (event instanceof MouseEvent && event.button !== 0) return;

    if (Draggable.dragged) return;

    view.setGrabbing(true);

    Draggable.dragged = this;

    this.active = true;

    event.preventDefault();
    return false;
  }

  private updateParent(): void {
    if (Draggable.dragged === cursor.aboveDraggable) return;
    if (!cursor.aboveInsertable) return;
    if (!this.draggingAbove()) return;

    this.setParent(cursor.aboveInsertable);
  }

  private onEnd(): void {
    if (Draggable.dragged !== this) return;

    this.updateParent();

    Draggable.dragged = null;

    view.setGrabbing(false);
  }

  private draggingAbove(): boolean {
    if (Draggable.dragged !== this) return false;
    if (!cursor.aboveInsertable) return false;
    if (this.target !== cursor.aboveInsertable.group) return false;

    if (this.parent === cursor.aboveInsertable) return true;
    if (cursor.aboveInsertable.isLimited) return false;

    return true;
  }

  private getTarget(): Vector2 {
    // Текущий не активен?
    if (!this.active)
      return this.getElementPosition();

    // Текущий над своим же элементом?
    if (Draggable.dragged === this && cursor.aboveDraggable === this)
      return this.getElementPosition();

    // Текущий не перетаскивается?
    if (Draggable.dragged !== this)
      return this.getElementPosition();

    // Текущий над другим элементом?
    if (this.draggingAbove())
      if (cursor.aboveDraggable)
        return cursor.aboveDraggable.getElementPosition();
      else if (cursor.aboveInsertable && !cursor.aboveInsertable.isLimited)
        return cursor.aboveInsertable.getEmptyElementPosition(this.clone);

    return cursor.position;
  }

  private onTick(): void {
    this.setPosition(this.getTarget().round(), !this.active);

    this.updateStyle();
  }

  private setEventListeners(): void {
    this.element.style.touchAction = 'none';
    this.element.style.userSelect = 'none';
    this.element.setAttribute('ondragstart', 'return false;');

    this.interval = window.setInterval(this.onTick, Draggable.tickSpeed);

    this.element.addEventListener('pointerdown', this.onStart);
    window.addEventListener('pointerup', this.onEnd);
  }

  private removeEventListeners(): void {
    this.element.style.touchAction = '';
    this.element.style.userSelect = '';
    this.element.removeAttribute('ondragstart');

    window.clearInterval(this.interval);

    this.element.removeEventListener('pointerdown', this.onStart);
    window.removeEventListener('pointerup', this.onEnd);
  }

  private bindMethods(): void {
    this.onStart = this.onStart.bind(this);
    this.onEnd = this.onEnd.bind(this);
    this.onTick = this.onTick.bind(this);
  }
}