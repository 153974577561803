import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  DifficultyId,
  ExerciseArgs,
  ExerciseData,
  ParagraphData,
  SuperArray,
  TagData,
  TopicData,
} from '#global';
import {AxiosHook, useAxios} from '#hooks';
import {ApiRoute} from '#includes/ApiRoute';
import {notifications} from '#store';
import {isTruthy} from '#includes/isTruthy';
import {ContentConverter} from '#includes/content/Converter';
import {Success} from '#includes/axios/Success';

export function useParagraphSaver(
  paragraphId: ParagraphData['id'],
  name: ParagraphData['name'],
  editorContent: ParagraphData['content'],
) {
  const apiCreateTags = useAxios(ApiRoute.createTags, {
    fail: error => notifications.addError(error),
  });

  const apiEditParagraph = useAxios(ApiRoute.editParagraph, {
    fail: error => notifications.addError(error),
  });

  const apiCreateExercises = useAxios(ApiRoute.createExercises, {
    fail: error => notifications.addError(error),
  });

  const isLoading = AxiosHook.useIsLoading([
    apiCreateTags,
    apiEditParagraph,
    apiCreateExercises,
  ]);

  const save = useCallback(async (): Promise<ParagraphData | void> => {
    if (isLoading) return;

    const exercisesInfo = ContentConverter
      .editorToExercisesInfo(editorContent);
    if (exercisesInfo instanceof Error) throw exercisesInfo;

    const tagNames = SuperArray
      .from(exercisesInfo.flatMap(({tagNames}) => tagNames))
      .removeRepeats()
      .array;

    const responseTags = await apiCreateTags.request(
      null,
      tagNames.map(tag => ({name: tag})),
    );
    if (!(responseTags instanceof Success)) return;
    const tags = responseTags.data.list;

    const responseExercises = await apiCreateExercises.request(
      null,
      exercisesInfo
        .map<ExerciseArgs>(({tagNames, answersData, meta}) => ({
          DifficultyId: DifficultyId.normal,
          data: answersData,
          TagsId: tagNames
            .map<TagData | undefined>(tagName => (
              tags.find(({name}) => tagName === name)
            ))
            .filter<TagData>(isTruthy)
            .map<number>(({id}) => id),
          meta: meta,
        })),
    );
    if (!(responseExercises instanceof Success)) return;
    const exercises = responseExercises.data.list;

    const dataContent = ContentConverter.editorToData(editorContent);
    if (dataContent instanceof Error)
      return void notifications.addError(dataContent);

    const responseParagraph = await apiEditParagraph.request(
      {id: paragraphId},
      {
        name: name ?? null,
        content: dataContent ?? null,
        ExercisesId: exercises.map(({id}) => id),
      },
    );
    if (!(responseParagraph instanceof Success)) return;
    const paragraph = responseParagraph.data;

    return paragraph;
  }, [
    paragraphId,
    name,
    editorContent,
    isLoading,
  ]);

  return {
    isLoading,
    save,
  };
}

export function useParagraphChanger(
  paragraph: null | Readonly<ParagraphData>,
  exercises: null | Readonly<ExerciseData[]>,
  topic: null | Readonly<TopicData>,
  errorCallback: (error: Error) => void,
) {
  const [name, setName] = useState<ParagraphData['name']>(null);
  const [content, setContent] = useState<ParagraphData['content']>(null);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const apiTags = useAxios(ApiRoute.getTagsMany, {
    fail: error => errorCallback(error),
  });

  const initialName = useMemo<ParagraphData['name']>(
    () => paragraph ? paragraph.name : null,
    [paragraph],
  );

  const initialContent = useMemo<ParagraphData['content']>(() => {
    if (
      !paragraph
      || !exercises
      || !apiTags.success
      || !topic
    ) return null;

    const editor = ContentConverter.dataToEditor(
      paragraph.content,
      exercises,
      apiTags.success.list,
    );
    if (editor instanceof Error)
      return void errorCallback(editor);

    return editor;
  }, [
    paragraph,
    exercises,
    apiTags.success,
    topic,
  ]);

  useEffect(() => {
    const nameChanged = !name
      ? Boolean(initialName)
      : initialName !== name;

    const contentChanged = !content
      ? Boolean(initialContent)
      : initialContent !== content;

    setHasChanges(nameChanged || contentChanged);
  }, [initialName, initialContent, name, content]);

  useEffect(() => {
    if (!exercises) return;

    apiTags.request(null, undefined, {
      ids: exercises.flatMap(({TagsId}) => TagsId),
    });
  }, [exercises]);

  const changeHandler = useCallback((
    name: ParagraphData['name'],
    editorContent: ParagraphData['content'],
  ) => {
    setName(name);
    setContent(editorContent);
  }, []);

  useEffect(
    () => changeHandler(initialName, initialContent),
    [initialName, initialContent],
  );

  const setData = useCallback((data: string) => {

  }, []);

  return {
    name,
    content,
    hasChanges,
    isLoading: apiTags.isLoading,
    changeHandler,
    setData,
  };
}