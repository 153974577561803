import {compile, serialize, stringify} from 'stylis';
import {insertNode} from '#includes/insertAfter';
import {CSSInterpolation} from '@emotion/serialize';
import {css} from '@emotion/react';

export type GlobalStyleProps = {
  name: string,
  styles: CSSInterpolation,
} & ({
  before: HTMLElement | string | undefined | null,
  after?: never,
} | {
  before?: never,
  after: HTMLElement | string | undefined | null,
});

export class GlobalStyle {
  private readonly element: HTMLStyleElement;

  private constructor(
    props: GlobalStyleProps,
  ) {
    const {name, styles} = props;

    const isBefore = 'before' in props;

    const refProp = isBefore
      ? props.before
      : props.after;

    const ref = typeof refProp === 'string'
      ? GlobalStyle.getRendered(refProp)
      : refProp;

    this.element = GlobalStyle.getOrCreateElement(name);

    GlobalStyle.renderElement(this.element, ref, isBefore);

    this.element.innerHTML = serialize(
      compile(css(styles).styles),
      stringify,
    );
  }

  public static render(
    props: GlobalStyleProps,
  ): GlobalStyle {
    return new GlobalStyle(props);
  }

  public static remove(
    name: string,
  ): void {
    GlobalStyle.removeElement(GlobalStyle.getRendered(name));
  }

  public getElement(): HTMLStyleElement {
    return this.element;
  }

  public destroy(): void {
    if (this.element.parentElement !== document.head)
      throw new Error('Элемент не найден');

    GlobalStyle.removeElement(this.element);
  }

  //

  private static removeElement(
    style?: HTMLStyleElement | null,
  ): void {
    if (!style) return;

    style.innerHTML = '';
    style.remove();
  }

  private static createElement(
    name: string,
  ): HTMLStyleElement {
    const style = document.createElement('style');
    style.setAttribute(name, 'true');

    return style;
  }

  private static getOrCreateElement(
    name: string,
  ): HTMLStyleElement {
    const rendered = GlobalStyle.getRendered(name);
    if (rendered) return rendered;

    return GlobalStyle.createElement(name);
  }

  private static getRendered(
    name: string,
  ): HTMLStyleElement | null {
    for (const style of document.head.childNodes) {
      if (!(style instanceof HTMLStyleElement)) continue;
      if (!style.hasAttribute(name)) continue;

      return style;
    }

    return null;
  }

  private static renderBefore(
    node: HTMLStyleElement,
    before?: HTMLElement | null,
  ): HTMLStyleElement {
    return insertNode.before(document.head, node, before);
  }

  private static renderAfter(
    node: HTMLStyleElement,
    after?: HTMLElement | null,
  ): HTMLStyleElement {
    return insertNode.after(document.head, node, after);
  }

  private static renderElement(
    node: HTMLStyleElement,
    ref: HTMLElement | null | undefined,
    isBefore: boolean,
  ): HTMLStyleElement {
    if (isBefore)
      return GlobalStyle.renderBefore(node, ref);

    return GlobalStyle.renderAfter(node, ref);
  }
}