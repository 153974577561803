import {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {Container, ContainerProps} from '#components/atoms';
import {theme} from '#store';
import {ClickableScheme, PaintingType} from '#config';

type ClickablePropsMin = {
  disabled?: boolean,
  inline?: boolean,
}

export type ClickableProps =
  Omit<ContainerProps, keyof ClickablePropsMin>
  & ClickablePropsMin;

export const clickableStyles = {
  base: (
    paintingType: PaintingType,
    borderStyle: ValueOf<Required<Pick<ContainerProps, 'borderStyle'>>>,
    clickableScheme: ClickableScheme,
    inline: boolean,
    disabled: boolean,
    offAlign = false,
  ) => css({
    display: inline ? 'inline' : 'flex',
    alignItems: offAlign ? undefined : 'flex-start',
    justifyContent: offAlign ? undefined : 'flex-start',
    cursor: disabled ? 'default' : 'pointer',
  }, disabled ? {
    borderColor: clickableScheme.stroke[2],
    color: clickableScheme.color[2],
    backgroundColor: clickableScheme.background[2],
  } : {
    '&:hover': {
      transition: '0ms',
      borderColor: borderStyle === 'hidden' ? 'transparent' : clickableScheme.stroke[1],
      color: clickableScheme.color[1],
      backgroundColor: clickableScheme.background[1],
    },
  }),
};

export const Clickable = observer<ClickableProps>(props => {
  const {
    children,
    color = theme.current.baseColor,
    printing = 'custom',
    inline = false,
    disabled = false,
    borderStyle = 'solid',
    ...otherProps
  } = props;

  const clickableScheme = useMemo(
    () => theme.current.Clickable(color)[printing],
    [color, printing],
  );

  const style = useMemo(() => clickableStyles.base(
    printing,
    borderStyle,
    clickableScheme,
    inline,
    disabled,
  ), [
    printing,
    borderStyle,
    clickableScheme,
    inline,
    disabled,
  ]);

  return (
    <Container
      css={style}
      color={color}
      printing={printing}
      borderStyle={borderStyle}
      {...otherProps}
    >
      {children}
    </Container>
  );
});