import {ReactElement, Suspense} from 'react';
import {observer} from 'mobx-react-lite';
import {Loading} from '#components/pages';
import {MainProps} from '#components/molecules';

type SuspenseMainProps = {
  children: ReactElement<MainProps>
};

export const SuspenseMain = observer<SuspenseMainProps>(props => {
  const {
    children,
  } = props;

  return (
    <Suspense fallback={<Loading/>}>
      {children}
    </Suspense>
  );
});