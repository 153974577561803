import {FileLoader, UploadAdapter} from '@ckeditor/ckeditor5-upload/src/filerepository';
import {Editor} from '@ckeditor/ckeditor5-core';
import {FileUpload} from '#includes/FileUpload';
import {isProduction, urls} from '#config';

type FileUploadData = {
  default: string,
};

class CustomUploadAdapterWorker implements UploadAdapter {
  private readonly loader: FileLoader;

  private fileUpload?: FileUpload;

  public constructor(loader: FileLoader) {
    this.loader = loader;
  }

  public async upload() {
    const file = await this.loader.file;

    return new Promise<FileUploadData>((resolve, reject) => {
      if (!file) return reject('File not received');

      this.fileUpload = new FileUpload();

      this.fileUpload.initListeners(
        data => {
          if (!data.mimetype.startsWith('image'))
            return reject('File is of unknown type');

          const url = `${isProduction ? '' : urls.backend}/media/${data.folder}/${data.name}`;

          resolve({default: url});
        },
        (total, loaded) => {
          this.loader.uploadTotal = total;
          this.loader.uploaded = loaded;
        },
        error => {
          reject(error);
        },
      );

      this.fileUpload.send(file);
    });
  }

  public abort() {
    this.fileUpload?.abort();
  }
}

export function CustomUploadAdapter(editor: Editor): void {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new CustomUploadAdapterWorker(loader);
  };
}