import {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {CheckBox, FlexSeparator, Link, List, Space, TextBox} from '#components/atoms';
import {loader, notifications, theme} from '#store';
import {css} from '@emotion/react';
import {Main, SignForm} from '#components/molecules';
import {palette, Route, routes} from '#config';
import {useNavigate} from 'react-router-dom';
import {useAxios} from '#hooks';
import {ApiRoute} from '#includes/ApiRoute';
import {getEm} from '#includes/utils';

const styles = {
  base: () => css({
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  input100: () => css({
    width: '100%',
  }),
  under: () => css({
    fontSize: getEm(14),
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
  }),
  forgot: () => css({
    color: theme.current.links.color,
  }),
  already: () => css({
    color: palette.gray.dark,
    fontSize: getEm(14),
    marginTop: getEm(20),
  }),
  alreadyLink: () => css({
    display: 'inline',
    color: palette.blue.base,
  }),
};

export const SignIn = observer(() => {
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const apiSignIn = useAxios(ApiRoute.signIn, {
    success: () => navigate(routes[Route.Home].createUrl()),
    fail: error => notifications.addError(error),
  });

  const submitHandler = async () => {
    if (!emailRef.current || !passwordRef.current) return;

    loader.add('SignIn');
    await apiSignIn.request(null, {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    });
    loader.remove('SignIn');
  };

  return (
    <Main css={styles.base()} view={{header: false, footer: false, autoWidth: true}}>
      <SignForm
        submit={'Войти'}
        disabled={apiSignIn.isLoading}
        onSubmit={submitHandler}
      >
        <List>
          <TextBox
            css={styles.input100()}
            label={'Email'}
            type={'email'}
            ref={emailRef}
          />
          <TextBox
            css={styles.input100()}
            label={'Пароль'}
            type={'password'}
            ref={passwordRef}
          />
          <List css={[styles.input100(), styles.under()]} horizontal={true}>
            <CheckBox
              label={'Запомнить меня'}
            />
            <FlexSeparator/>
            <Link css={styles.forgot()} to={Route.NotFound}>
              Забыли пароль?
            </Link>
          </List>
        </List>
      </SignForm>
      <div css={styles.already()}>
        Ещё нет учётной записи?
        <Space/>
        <Link css={styles.alreadyLink()} printing={'custom'} to={Route.SignUp}>
          Зарегистрироваться
        </Link>
      </div>
    </Main>
  );
});