import {Plugin} from '@ckeditor/ckeditor5-core';
import {Ui} from './Ui';
import {Editing} from './Editing';

export class Sorting extends Plugin {
  public static get requires() {
    return [Editing, Ui];
  }
}

export * from './Converter';
export * from './Style';