// noinspection HtmlRequiredAltAttribute

import {ImgHTMLAttributes} from 'react';
import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';

type ImagePropsMin = {
  alt?: string
};

export type ImageProps =
  Omit<ImgHTMLAttributes<HTMLImageElement>, keyof ImagePropsMin | 'children'>
  & ImagePropsMin;

const styles = {
  base: () => css({
    objectFit: 'cover',
    backgroundPosition: 'center',
  }),
};

export const Image = observer<ImageProps>(props => {
  const {
    alt = '',
    ...otherProps
  } = props;

  return (
    <img css={styles.base()} alt={alt} {...otherProps}/>
  );
});