import {Collection, Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {
  ItemView,
  ListView,
  SwitchView,
  TextOrFileDatum,
  TextOrFileView,
} from '#includes/content/editor/Views';
import {BaseForm} from '#includes/content/editor/Forms';

export class ModelData {
  public values: TextOrFileDatum[];
  public isHorizontal: boolean;

  public constructor(values: TextOrFileDatum[] = [], isHorizontal: boolean = false) {
    this.values = values;
    this.isHorizontal = isHorizontal;
  }
}

class OrderedView extends ListView<[TextOrFileView], TextOrFileDatum> {
  protected readonly columnCollection: Collection<ItemView<[TextOrFileView]>>;

  private constructor(locale: Locale) {
    super(locale);
    this.columnCollection = this.getChildrenCollection();
  }

  public static create(locale: Locale) {
    return new OrderedView(locale);
  }

  public getValues(): TextOrFileDatum[] {
    const values: TextOrFileDatum[] = [];

    for (const item of this.columnCollection) {
      const data = item.getChildren()[0].getDatum();
      if (!data.value) continue;

      values.push(data);
    }

    return values;
  }

  //

  protected itemChildrenCreation(
    item: ItemView<[TextOrFileView]>,
    datum?: TextOrFileDatum,
  ): [TextOrFileView] {
    if (!datum) datum = new TextOrFileDatum();

    const view = TextOrFileView.create(
      this.locale,
      [],
      Keyword.formInputLabel,
    );
    view.setDatum(datum);

    return [view];
  }

  protected checkEmpty(): void {
    const length = this.getChildrenLength();
    if (length === 0) return void this.setItems([new TextOrFileDatum(), new TextOrFileDatum()]);
    if (length === 1) return void this.addChild(this.createItem(new TextOrFileDatum()));
  }
}

export class Form extends BaseForm<[OrderedView, SwitchView], ModelData> {
  private readonly ordered: OrderedView;
  private readonly switch: SwitchView;

  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.orderedFormClass, cancelCallback);

    this.ordered = OrderedView.create(this.locale);
    this.ordered.setUpdateHandler(() => {
      this.form.updateFocusTracker();
    });

    this.switch = SwitchView.create(
      locale,
      [],
      Keyword.formIsHorizontalLabel,
      true,
    );
    this.switch.setHandler(() => this.switch.setEnabled(!this.switch.getEnabled()));

    this.form.setChildren([
      this.ordered,
      this.switch,
    ]);
  }

  public getData(): ModelData {
    const values = this.ordered.getValues();
    const isHorizontal = this.switch.getEnabled();

    return new ModelData(values, isHorizontal);
  }

  public override setData(data: ModelData): void {
    this.ordered.setItems(data.values);
    this.switch.setEnabled(data.isHorizontal);

    super.setData(data);
  }
}