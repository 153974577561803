import {observer} from 'mobx-react-lite';
import {Button, Form, Link} from '#components/atoms';
import {HTMLAttributes, JSXElementConstructor, ReactElement, useCallback} from 'react';
import {css} from '@emotion/react';
import {device, DeviceType, theme} from '#store';
import {getEm} from '#includes/utils';
import {GiBrain} from '@react-icons/all-files/gi/GiBrain';
import {Route, urls} from '#config';

type Children = ReactElement<
  HTMLAttributes<HTMLInputElement>,
  JSXElementConstructor<HTMLAttributes<HTMLInputElement>>
>;

type SignFormPropsMin = {
  submit: string,
  onSubmit: () => void,
  disabled: boolean,
  children: Children | Array<Children>,
};

export type SignFormProps =
  Omit<HTMLAttributes<HTMLElement>, keyof SignFormPropsMin>
  & SignFormPropsMin;

const styles = {
  base: () => css({
    marginTop: getEm(80),
    width: device.current.width,
    maxWidth: device.getByType(DeviceType.tablet).width,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  header: () => css({
    padding: getEm(12),
    fontWeight: 'bold',
  }),
  headerInner: () => css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: getEm(18),
  }),
  icon: () => css({
    width: '1em',
    fontSize: getEm(50),
    paddingBottom: getEm(5),
    color: theme.current.basePalette.hard,
  }),
  form: () => css({
    display: 'flex',
    flexDirection: 'column',
    padding: `${getEm(15)} ${getEm(10)}`,
    alignContent: 'stretch',
    justifyContent: 'space-between',
    width: '100%',
  }),
  submit: () => css({
    marginTop: getEm(25),
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

export const SignForm = observer<SignFormProps>(props => {
  const {
    submit,
    children,
    onSubmit,
    disabled,
    ...otherProps
  } = props;

  const submitHandler = useCallback(
    () => !disabled && onSubmit(),
    [disabled, onSubmit],
  );

  return (
    <div css={styles.base()} {...otherProps}>
      <div css={styles.header()}>
        <Link printing={'custom'} to={Route.Home}>
          <div css={styles.headerInner()}>
            <GiBrain css={styles.icon()}/>
            {urls.short}
          </div>
        </Link>
      </div>
      <Form css={styles.form()} onSubmit={() => submitHandler()}>
        {children}
        <Button css={styles.submit()} disabled={disabled} onClick={() => submitHandler()}>
          {submit}
        </Button>
      </Form>
    </div>
  );
});