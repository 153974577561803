import {FocusCycler, View, ViewCollection} from '@ckeditor/ckeditor5-ui';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Collection, FocusTracker, KeystrokeHandler} from '@ckeditor/ckeditor5-utils';
import {BaseView, TextOrFileView} from '#includes/content/editor/Views';
import {FormBaseView} from './_Base';
import {Keyword} from '#includes/content/editor/Keyword';
import {SuperError} from '#includes/error';

export class FocusableView<
  Children extends BaseView[],
> extends BaseView<FormBaseView<Children>['ckView']> {
  private readonly form: FormBaseView<Children>;
  public readonly setChildren;

  private readonly focusCycler: FocusCycler;
  private readonly focusables: ViewCollection;
  private readonly focusTracker: FocusTracker;
  private readonly keystrokeHandler: KeystrokeHandler;

  private constructor(
    locale: Locale,
    className: string | string[],
    cancelCallback: () => void,
  ) {
    const form = FormBaseView.create<Children>(locale, className);

    super(locale, form.ckView);

    this.form = form;
    this.setChildren = this.form.setChildren.bind(this.form);

    this.focusables = new ViewCollection();

    this.focusTracker = new FocusTracker();
    this.focusables.on('add', (_event, view: View) => {
      if (!view.element || !(view.element instanceof HTMLElement)) throw new SuperError(
        `${this.constructor.name}.focusables.on.add`,
        'view.element не является HTMLElement',
      );

      this.focusTracker.add(view.element);
    });
    this.focusables.on('remove', (_event, view: View) => {
      if (!view.element || !(view.element instanceof HTMLElement)) throw new SuperError(
        `${this.constructor.name}.focusables.on.remove`,
        'view.element не является HTMLElement',
      );

      this.focusTracker.remove(view.element);
    });

    this.keystrokeHandler = new KeystrokeHandler();
    this.keystrokeHandler.set('Esc', (_data, cancel) => {
      cancelCallback();
      cancel();
    });
    this.form.setRenderHandler(element => this.keystrokeHandler.listenTo(element));
    this.form.setDestroyHandler(() => this.keystrokeHandler.destroy());

    this.focusCycler = new FocusCycler({
      focusables: this.focusables,
      focusTracker: this.focusTracker,
      keystrokeHandler: this.keystrokeHandler,
      actions: {
        focusPrevious: 'shift + tab',
        focusNext: 'tab',
      },
    });

    this.ckView.addRenderHandler(this.update.bind(this));
  }

  public static create<
    Children extends BaseView[],
  >(
    locale: Locale,
    className: string | string[],
    cancelCallback: () => void,
  ) {
    return new FocusableView<Children>(
      locale,
      className,
      cancelCallback,
    );
  }

  public update(): void {
    this.focusables.clear();
    this.addInTracker(this.form);
  }

  public focusFirstInput(): void {
    const firstInput = this.focusables.find(
      focusable => Boolean(
        focusable.element?.classList.contains(Keyword.inputClass)
        || focusable.element?.classList.contains(Keyword.fileClass),
      ),
    );
    if (!firstInput || !firstInput.element) return;

    if (firstInput.element.classList.contains(Keyword.fileClass)) {
      if (firstInput.element.classList.contains(Keyword.fileModeClass)) {
        const input = firstInput.element.children?.[1];
        if (!input || !(input instanceof HTMLButtonElement)) return;

        input.focus();
      } else {
        const input = firstInput.element.children?.[0]?.children?.[0]?.children?.[0];
        if (!input || !(input instanceof HTMLInputElement)) return;

        input.focus();
      }
    } else {
      const input = firstInput.element.children?.[0]?.children?.[0];
      if (!input || !(input instanceof HTMLInputElement)) return;

      input.focus();
    }
  }

  //

  private addInTracker(view: BaseView): void {
    if (view instanceof TextOrFileView) {
      const collection = view.ckView.template?.children?.[0];
      if (!collection || !(collection instanceof ViewCollection)) return;

      for (const child of collection)
        this.focusables.add(child);

      return;
    }

    const collection = FocusableView.getChildrenCollection(view);

    if (!collection) {
      this.focusables.add(view.ckView);
    } else {
      for (const child of collection)
        this.addInTracker(child);
    }
  }

  private static getChildrenCollection(
    view: BaseView,
  ): Collection<BaseView> | void {
    if (!(view as any).getChildrenCollection) return;

    return (view as any).getChildrenCollection();
  }
}