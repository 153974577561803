import {HTMLAttributes} from 'react';
import {observer} from 'mobx-react-lite';

type DropListPropsMin = {
  options: string[],
};

type DropListProps =
  Omit<HTMLAttributes<HTMLSelectElement>, keyof DropListPropsMin | 'children'>
  & DropListPropsMin;

export const DropList = observer<DropListProps>(props => {
  const {
    options,
    ...otherProps
  } = props;

  return (
    <select {...otherProps}>
      {options.map(option => (
        <option>
          {option}
        </option>
      ))}
    </select>
  );
});