import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseView, ContainerView} from '#includes/content/editor/Views';
import {ItemView} from './_Item';

type ListChildren<Children extends BaseView[]> = ItemView<Children>[];

export type ListEvent<Children extends BaseView[]> = {
  sender: ItemView<Children>,
  type: 'before',
  item: ItemView<Children>,
} | {
  sender: ItemView<Children>,
  type: 'remove',
} | {
  sender: ItemView<Children>,
  type: 'after',
  item: ItemView<Children>,
};

export abstract class ListView<
  Children extends BaseView[],
  DatumOfItem extends any,
> extends BaseView<ContainerView<ListChildren<Children>>['ckView']> {
  public static ItemView = ItemView;

  private readonly list: ContainerView<ListChildren<Children>>;
  public readonly addChild;
  public readonly getChildrenLength;
  public readonly getChildrenCollection;

  private _updateHandler?: () => void;

  protected constructor(
    locale: Locale,
    className: string | string[] = [],
  ) {
    const list = ContainerView.create<ListChildren<Children>>(
      locale,
      [Keyword.listClass, className].flat(),
    );

    super(locale, list.ckView);

    this.list = list;
    this.addChild = this.list.addChild.bind(this.list);
    this.getChildrenLength = this.list.getChildrenLength.bind(this.list);
    this.getChildrenCollection = this.list.getChildrenCollection.bind(this.list);

    this.checkEmpty();
  }

  protected abstract itemChildrenCreation(
    item: ListChildren<Children>[number],
    datum?: DatumOfItem,
  ): Children;

  public createItem(
    datum?: DatumOfItem,
    eventHandler?: (event: ListEvent<Children>) => void,
  ): ListChildren<Children>[number] {
    const item = ItemView.create<Children>(this.locale);

    item.setBeforeHandler(() => {
      const newItem = this.createItem(undefined, eventHandler);
      this.list.addChildBefore(item, newItem);
      this._updateHandler && this._updateHandler();
      this.checkEmpty();
      eventHandler && eventHandler({sender: item, type: 'before', item: newItem});
    });
    item.setRemoveHandler(() => {
      eventHandler && eventHandler({sender: item, type: 'remove'});
      this.list.removeChild(item);
      this._updateHandler && this._updateHandler();
      this.checkEmpty();
    });
    item.setInsertHandler(() => {
      const newItem = this.createItem(undefined, eventHandler);
      this.list.addChildAfter(item, newItem);
      this._updateHandler && this._updateHandler();
      this.checkEmpty();
      eventHandler && eventHandler({sender: item, type: 'after', item: newItem});
    });

    item.setChildren(this.itemChildrenCreation(item, datum));

    return item;
  }

  public setUpdateHandler(handler: () => void): void {
    this._updateHandler = handler;
  }

  public get updateHandler(): (() => void) | undefined {
    return this._updateHandler;
  }

  public setItems(
    data: DatumOfItem[],
    eventHandler?: (event: ListEvent<Children>) => void,
  ): void {
    this.list.setChildren(
      data.map(datum => this.createItem(datum, eventHandler)),
    );

    this.checkEmpty();
  }

  //

  protected abstract checkEmpty(): void;
}

export type {
  ItemView,
};