import {CommandBase} from '../utils';
import {Keyword} from '#includes/content/editor/Keyword';

export class Command extends CommandBase {
  public override execute(data: string) {
    this.editor.model.change(writer => {
      const element = writer.createElement(Keyword.videoModel, {
        data: data,
      });

      const {end: positionAfter} = this.editor.model.insertObject(
        element,
        null,
        null,
        {setSelection: 'on'},
      );

      writer.setSelection(positionAfter);
    });
  }

  public override refresh() {
    this.isEnabled = this.getIsEnabled();
  }

  private getIsEnabled(): boolean {
    const model = this.editor.model;
    const selection = model.document.selection;
    const position = selection.getFirstPosition();
    const allowedIn = position && model.schema.findAllowedParent(position, Keyword.videoModel);

    return allowedIn !== null;
  }
}