// noinspection ES6PreferShortImport

import {config} from '#global';

const ipSubstrings = window.location.hostname.split('.');

const isLocalhost = window.location.hostname === 'localhost';
const isLocalIp = ipSubstrings[0] === '192' && ipSubstrings[1] === '168';

const isTestProduction = false;
export const isSureProduction = !isLocalhost && !isLocalIp;
export const isProduction = isSureProduction || isTestProduction;

const frontend = window.location.origin;
const short = window.location.host;
const backend = isSureProduction
  ? config.urls.domain
  : `http://${window.location.hostname}:${config.server.port}`;

export const urls = {short, frontend, backend} as const;

(globalThis as any).urls = urls;
(globalThis as any).isProduction = isProduction;