import {Collection, Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {InputView, ItemView, ListView} from '#includes/content/editor/Views';
import {BaseForm} from '#includes/content/editor/Forms';

class TagsView extends ListView<[InputView], string> {
  protected readonly columnCollection: Collection<ItemView<[InputView]>>;

  private constructor(locale: Locale) {
    super(locale);
    this.columnCollection = this.getChildrenCollection();
  }

  public static create(locale: Locale) {
    return new TagsView(locale);
  }

  public setValues(values: string[]): void {
    this.setItems(values);
  }

  public getValues(): string[] {
    const values: string[] = [];

    for (const item of this.columnCollection) {
      const value = item.getChildren()[0].getValue();
      if (!value) continue;

      values.push(value);
    }

    return values;
  }

  //

  protected itemChildrenCreation(
    _item: ItemView<[InputView]>,
    datum?: string,
  ): [InputView] {
    const view = InputView.create(
      this.locale,
      [],
      Keyword.formInputLabel,
    );
    datum && view.setValue(datum);

    return [view];
  }

  protected checkEmpty(): void {
    const length = this.getChildrenLength();
    if (length === 0) return void this.setItems(['Без меток']);
  }
}

export class Form extends BaseForm<[InputView, TagsView], [string, string[]]> {
  private readonly help: InputView;
  private readonly tags: TagsView;

  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.metaFormClass, cancelCallback);

    this.help = InputView.create(
      this.locale,
      Keyword.helpClass,
      this.locale.t(Keyword.formHelpLabel),
    );

    this.tags = TagsView.create(this.locale);
    this.tags.setUpdateHandler(() => {
      this.form.updateFocusTracker();
    });

    this.form.setChildren([
      this.help,
      this.tags,
    ]);
  }

  public getData(): [string, string[]] {
    return [this.help.getValue(), this.tags.getValues()];
  }

  public override setData(data: [string, string[]]): void {
    const [helpValue, tagsValues] = data;

    this.help.setValue(helpValue);
    this.tags.setValues(tagsValues);

    super.setData(data);
  }
}