import {HTMLAttributes, ReactElement, useCallback, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {Tab, TabProps} from './Tab';
import {SuperArray} from '#global';
import {TabButton} from '#components/atoms/Tabs/TabButton';
import {getEm} from '#includes/utils';
import {theme} from '#store';
import {borders, palette} from '#config';

type TabsPropsMin = {
  children?: ReactElement<TabProps> | Array<ReactElement<TabProps>>,
};

export type TabsProps =
  Omit<HTMLAttributes<HTMLElement>, keyof TabsPropsMin>
  & TabsPropsMin;

const containerTheme = theme.current.Container(theme.current.baseColor);

const styles = {
  base: () => css({}),
  header: () => css({
    display: 'flex',
    alignItems: 'flex-end',
    height: '2em',
  }),
  headerFirst: () => css({
    width: '0.5em',
    borderBottomStyle: 'solid',
    borderBottomWidth: borders.width.small,
    borderBottomColor: palette.black,
  }),
  headerLast: () => css({
    flexGrow: 1,
    width: '0.5em',
    borderBottomStyle: 'solid',
    borderBottomWidth: borders.width.small,
    borderBottomColor: palette.black,
  }),
  body: () => css({
    padding: `${getEm(10)} 0`,
    borderBottomStyle: 'solid',
    borderBottomWidth: borders.width.small,
    borderBottomColor: palette.black,
  }),
};

export const Tabs = observer<TabsProps>(props => {
  const {
    children,
    ...otherProps
  } = props;

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [titles, TabItems] = useMemo(() => {
    const TabItems = children ? (Array.isArray(children) ? children : [children]) : [];
    const titles = TabItems.map(tab => tab.props.title);

    if (titles.find(title => !title))
      throw new Error('Отсутствие Tab[].props.title запрещено');

    if (SuperArray.from(titles).findPair())
      throw new Error('Использование одинаковых Tab[].props.title запрещено');

    return [titles, TabItems];
  }, [children]);

  const clickHandler = useCallback((title: string) => () => {
    setSelectedIndex(titles.findIndex(currentTitle => currentTitle === title));
  }, [titles]);

  const TabButtons = useMemo(() => titles.map((title, index) => (
    <TabButton
      key={title}
      disabled={index === selectedIndex}
      onClick={clickHandler(title)}
    >
      {title}
    </TabButton>
  )), [clickHandler, selectedIndex, titles]);

  return (
    <div css={styles.base()} {...otherProps}>
      <div css={styles.header()}>
        <div css={styles.headerFirst()}/>
        {TabButtons}
        <div css={styles.headerLast()}/>
      </div>
      <div css={styles.body()}>
        {TabItems[selectedIndex]}
      </div>
    </div>
  );
});

export {
  Tab,
};