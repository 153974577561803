import {Plugin} from '@ckeditor/ckeditor5-core';
import {Converter as TextConverter, Style as TextStyle, Text} from './Text';
import {Converter as SortingConverter, Sorting, Style as SortingStyle} from './Sorting';
import {Converter as DropListConverter, DropList, Style as DropListStyle} from './DropList';
import {CheckList, Converter as CheckListConverter, Style as CheckListStyle} from './CheckList';
import {Converter as OrderedConverter, Ordered, Style as OrderedStyle} from './Ordered';
import {
  Connections,
  Converter as ConnectionsConverter,
  Style as ConnectionsStyle,
} from './Connections';
import {AnswerModelName} from '#global';
import {ConverterBase, StyleBase} from '#includes/content/editor/plugins/utils';
import {Keyword} from '../../../Keyword';

export class Answer extends Plugin {
  public static get requires() {
    return [Text, Sorting, DropList, CheckList, Connections, Ordered];
  }
}

export const modelClass = {
  [AnswerModelName.textModel]: Keyword.textClass,
  [AnswerModelName.dropListModel]: Keyword.dropListClass,
  [AnswerModelName.checkListModel]: Keyword.checkListClass,
  [AnswerModelName.sortingModel]: Keyword.sortingClass,
  [AnswerModelName.connectionsModel]: Keyword.connectionsClass,
  [AnswerModelName.orderedModel]: Keyword.orderedClass,
} as const satisfies { [M in AnswerModelName]: Keyword };
export const modelClasses = Object
  .entries(modelClass) as [AnswerModelName, Keyword][];

export const modelStyle = {
  [AnswerModelName.textModel]: TextStyle,
  [AnswerModelName.dropListModel]: DropListStyle,
  [AnswerModelName.checkListModel]: CheckListStyle,
  [AnswerModelName.sortingModel]: SortingStyle,
  [AnswerModelName.orderedModel]: OrderedStyle,
  [AnswerModelName.connectionsModel]: ConnectionsStyle,
} as const satisfies { [M in AnswerModelName]: StyleBase };
export const modelStyles = Object
  .entries(modelStyle) as [AnswerModelName, StyleBase][];

export const modelConverter = {
  [AnswerModelName.textModel]: TextConverter,
  [AnswerModelName.dropListModel]: DropListConverter,
  [AnswerModelName.checkListModel]: CheckListConverter,
  [AnswerModelName.sortingModel]: SortingConverter,
  [AnswerModelName.orderedModel]: OrderedConverter,
  [AnswerModelName.connectionsModel]: ConnectionsConverter,
} as const satisfies { [M in AnswerModelName]: ConverterBase<M, any> };
export const modelConverters = Object
  .entries(modelConverter) as [AnswerModelName, ConverterBase<AnswerModelName, any>][];