import {Plugin} from '@ckeditor/ckeditor5-core';
import {Locale} from '@ckeditor/ckeditor5-utils';
import {ButtonView} from '@ckeditor/ckeditor5-ui';
import {Keyword} from '#includes/content/editor/Keyword';
import {SuperError} from '#includes/error';

export class Ui extends Plugin {
  public static showUI(e: any) {
    console.log(e);
  }

  public init() {
    this.registerButtons();
  }

  private registerButtons() {
    const editor = this.editor;

    editor.ui.componentFactory.add(
      Keyword.widgetModel,
      locale => this.createButton(locale),
    );
  }

  private createButton(locale: Locale): ButtonView {
    const editor = this.editor;

    const command = editor.commands.get(Keyword.widgetInsertCommand);
    if (!command) throw new SuperError(
      `${this.constructor.name}.${this.init.name}.addCommand`,
      `Command "${Keyword.widgetInsertCommand}" in not exist`,
    );

    const button = new ButtonView(locale);

    button.set({
      label: editor.t(Keyword.widgetInsertLabel),
      withText: true,
      tooltip: true,
    });

    button.bind('isOn').to(command, 'value', value => Boolean(value));
    button.bind('isEnabled').to(command);

    this.listenTo(
      button,
      'execute',
      () => editor.execute(Keyword.widgetInsertCommand),
    );

    return button;
  }
}

(globalThis as any).Ck_Box_Ui = Ui;