import {action, computed, makeObservable, observable} from 'mobx';

class Loader {
  private _loaders: string[] = ['load'];
  private _displayed: boolean = true;

  public constructor() {
    makeObservable<typeof this, '_displayed'>(this, {
      _displayed: observable.ref,
      displayed: computed,
      add: action.bound,
      remove: action.bound,
    });
  }

  public get displayed(): boolean {
    return this._displayed;
  }

  public add(name: string): void {
    this._displayed = this._loaders.push(name) > 0;
  }

  public remove(name: string): void {
    this._loaders = this._loaders.filter(loader => loader !== name);
    this._displayed = this._loaders.length > 0;
  }
}

export const loader = new Loader();

(globalThis as any).loader = loader;