import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {useEffectOnce} from 'usehooks-ts';

type ErrorTextProps = {
  children: string,
};

const styles = {
  base: () => css({
    color: '#F55',
    backgroundColor: '#400',
    fontWeight: 'bold',
    padding: '0 0.2em',
    cursor: 'not-allowed',
    border: 'solid 2px #000',
    '&:hover > *': {
      display: 'inline',
    },
  }),
  info: () => css({
    display: 'none',
  }),
};

export const ErrorText = observer<ErrorTextProps>(props => {
  const {
    children: message,
  } = props;

  useEffectOnce(() => {
    console.error(message);
  });

  return (
    <span css={styles.base()}>
      ERROR
      <span css={styles.info()}>
        : {message}
      </span>
    </span>
  );
});