import {HttpMethod, ListData, ParagraphArgs, ParagraphData, ParagraphEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_ParagraphsRoute = {
  createParagraph: new g_Route<null, ParagraphData, ParagraphArgs>(
    HttpMethod.post,
    'paragraphs',
    'Добавить параграф',
  ),
  createParagraphs: new g_Route<null, ListData<ParagraphData>, ParagraphArgs[]>(
    HttpMethod.post,
    'paragraphs/many',
    'Добавить параграфы',
  ),
  getParagraphs: new g_Route<null, ListData<ParagraphData>>(
    HttpMethod.get,
    'paragraphs',
    'Получить все параграфы',
  ),
  getParagraphsMany: new g_Route<null, ListData<ParagraphData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'paragraphs/many',
    'Получить параграфы',
  ),
  getParagraph: new g_Route<{ id: number }, ParagraphData>(
    HttpMethod.get,
    'paragraphs/:id',
    'Получить параграф',
  ),
  editParagraph: new g_Route<{ id: number }, ParagraphData, Partial<ParagraphArgs>>(
    HttpMethod.put,
    'paragraphs/:id',
    'Изменить параграф',
  ),
  editParagraphs: new g_Route<null, ListData<ParagraphData>, ParagraphEditionArgs[]>(
    HttpMethod.put,
    'paragraphs/many',
    'Изменить параграфы',
  ),
  deleteParagraph: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'paragraphs/:id',
    'Удалить параграф',
  ),
  deleteParagraphs: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'paragraphs/many',
    'Удалить параграфы',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_ParagraphsRoute;
