import {Locale} from '@ckeditor/ckeditor5-utils';
import type {BaseView} from '#includes/content/editor/Views';
import {ContainerBaseView} from './_ContainerBase';
import {View} from '@ckeditor/ckeditor5-ui';

export class ContainerView<
  Children extends BaseView[]
> extends ContainerBaseView<'div', View<HTMLDivElement>, Children> {
  private constructor(locale: Locale, className: string | string[]) {
    super(locale, 'div', new View<HTMLDivElement>(locale), className);
  }

  public static create<
    Children extends BaseView[],
  >(locale: Locale, className: string | string[]): ContainerView<Children> {
    return new ContainerView<Children>(locale, className);
  }
}