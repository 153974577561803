import {Interactive, InteractiveProps} from './Interactive';
import {Draggable} from './Draggable';
import {Vector2} from '#includes/geometry';
import {cursor} from '#includes/Interactive/cursor';

export type InsertableProps = InteractiveProps & {
  group?: Symbol,
  childrenLimit?: number,
};

export class Insertable extends Interactive {
  private readonly emptyElement: HTMLElement;
  public readonly group: Symbol;
  private readonly children: Set<Draggable> = new Set();
  public readonly childrenLimit: number;

  private constructor(props: InsertableProps) {
    const {
      group = Insertable.createId(),
      childrenLimit = Infinity,
    } = props;

    super(props);

    this.element.classList.add('draggable-fixed');

    this.group = group;
    this.childrenLimit = childrenLimit;

    this.emptyElement = document.createElement('span');
    this.emptyElement.className = 'draggable-empty';
    this.element.appendChild(this.emptyElement);

    Array.from(this.element.childNodes).forEach((child, index) => {
      if (!(child instanceof HTMLElement)) return;
      if (child.classList.contains('draggable-empty')) return;

      child.setAttribute('data-draggable-index', String(index));

      const draggable = Draggable.create({
        element: child,
        target: this.group,
        limitElement: this.limitElement,
      });

      draggable.setParent(this);
    });
  }

  public static create(props: InsertableProps): Insertable {
    return new Insertable(props);
  }

  public get isLimited(): boolean {
    return this.children.size === this.childrenLimit;
  }

  public addChild(draggable: Draggable): void {
    this.children.add(draggable);

    if (this.children.size > this.childrenLimit) throw new Error(
      `${this.constructor.name}.${this.addChild.name}: ` +
      'this.children.size > this.childrenLimit',
    );

    this.updateStyle();

    this.element.insertBefore(
      draggable.element,
      !cursor.aboveDraggable || draggable === cursor.aboveDraggable
        ? this.emptyElement
        : cursor.aboveDraggable.element,
    );
  }

  public removeChild(draggable: Draggable): void {
    this.children.delete(draggable);

    this.updateStyle();

    this.element.removeChild(draggable.element);
  }

  public override deactivate(): void {
    super.deactivate();

    this.emptyElement.remove();
    this.element.classList.remove('draggable-fixed');
  }

  public getEmptyElementPosition(sizeFrom: HTMLElement): Vector2 {
    return Vector2
      .fromElementTopLeft(this.emptyElement)
      .add(Vector2.fromElementSize(sizeFrom).divide(2));
  }

  //

  private updateStyle(): void {
    const emptyChildren = this.children.size === 0;
    this.element.classList[emptyChildren ? 'remove' : 'add']('draggable-parent');

    this.emptyElement.style.display = this.isLimited ? 'none' : '';
  }
}