import {View} from '@ckeditor/ckeditor5-ui';

export class RenderedCkView<Element extends HTMLElement> extends View<Element> {
  private renderHandlers: ((element: Element) => void)[] = [];
  private destroyHandlers: (() => void)[] = [];

  public addRenderHandler(handler: (element: Element) => void) {
    this.renderHandlers.push(handler);
  }

  public addDestroyHandler(handler: () => void) {
    this.destroyHandlers.push(handler);
  }

  public override render() {
    super.render();

    this.element && this.renderHandlers.forEach(handler => handler(this.element!));
  }

  public override destroy() {
    this.destroyHandlers.forEach(handler => handler());

    super.destroy();
  }
}