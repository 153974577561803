import {observer} from 'mobx-react-lite';
import {Clickable, ClickableProps} from '#components/atoms';
import {theme} from '#store';

export type ButtonPropsMin = {
  cancelEvents?: boolean,
};

export type ButtonProps =
  Omit<ClickableProps, keyof ButtonPropsMin | 'element'>
  & ButtonPropsMin;

export const Button = observer<ButtonProps>(props => {
  const {
    children,
    color = theme.current.baseColor,
    printing = 'filled',
    disabled = false,
    cancelEvents = true,
    ...otherProps
  } = props;

  return (
    <Clickable
      element={cancelEvents ? 'div' : 'button'}
      color={color}
      printing={printing}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </Clickable>
  );
});
