import {BaseData} from '../../';

export enum PermissionId {
  userManagement = 1,
  databaseManagement,
}

export type PermissionData = BaseData<{
  id: PermissionId,
  name: string,
}>;

export const permissions: { readonly [key in PermissionId]: PermissionData } = {
  [PermissionId.userManagement]: {
    id: PermissionId.userManagement,
    name: 'Управление пользователями',
  },
  [PermissionId.databaseManagement]: {
    id: PermissionId.databaseManagement,
    name: 'Управление базой данных',
  },
};
