import {css} from '@emotion/react';
import {getEm} from '#includes/utils';
import {EditorStyle, GeneralStyle} from '#includes/content';
import {views} from '#includes/content/styles/editor/views';
import {forms} from '#includes/content/styles/editor/forms';

const global = css`
  &, & *, & *::after, & *::before,
  &:focus, &:focus *,
  &:hover, &:hover * {
    transition: none !important;
  }
  
  .page-break {
    padding-block: 5em;
  }

  .ck-widget__type-around__button,
  .ck-widget__type-around__button * {
    cursor: pointer;
  }

  .ck-editor__nested-editable.ck-editor__nested-editable_focused, 
  .ck-editor__nested-editable:focus {
    background-color: transparent;
  }
`

export const styles = {
  global: [
    css({'.ck': [views, forms, global]}),
    css`
      // Реклама редактора
      .ck-powered-by-balloon {
        display: none !important;
      }

      // Панель над редактором
      .ck-body-wrapper {
        position: absolute;
      }

      // Высота редактора всегда максимальна
      .ck-editor, .ck.ck-editor__main, .ck.ck-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: hidden;
      }

      // Нормальные отступы
      .ck-content {
        padding-inline: ${getEm(20)} !important;
        padding-block: ${getEm(10)} !important;

        &::after {
          content: "";
          display: inline-block;
          padding-bottom: getEm(13);
        }
      }

      // Кнопки панель управления виджетом в строчку
      .ck-balloon-rotator__content .ck-toolbar__items {
        flex-wrap: nowrap !important;
      }

      // Убирает текст кнопки переключения исходного кода
      .ck-source-editing-button {
        & > span {
          display: none !important;
        }

        & > svg {
          margin-right: 0 !important;
        }
      }

      // Стили текста
      .ck-style-grid__button {
        &.ck-disabled {
          opacity: 0.4;
        }

        &__preview {
          align-items: flex-start !important;
          width: 100%;

          & > * {
            white-space: pre-line;

            &::before {
              content: "Раз два три четыре пять. Это пример текста. ";
            }
          }

          &:not(.ck-disabled) {
            &, & * {
              cursor: pointer;
            }
          }
        }
      }
    `,
  ],
  base: css(
    GeneralStyle,
    EditorStyle,
    css`
      display: flex;
      flex-direction: column;
    `,
  ),
  reload: css`
    position: fixed;
    left: 0;
    bottom: 0;
    margin: ${getEm(7)};
  `,
};