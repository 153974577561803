import {Plugin} from '@ckeditor/ckeditor5-core';
import {Command} from './Command';
import {
  toWidget,
  toWidgetEditable,
  viewToModelPositionOutsideModelElement,
  Widget,
} from '@ckeditor/ckeditor5-widget';
import {Keyword} from '#includes/content/editor/Keyword';
import {
  DowncastWriter,
  ViewContainerElement,
  ViewElement,
  ViewNode,
  ViewText,
} from '@ckeditor/ckeditor5-engine';
import {Command as MetaCommand} from './MetaCommand';
import {ConverterError} from '#includes/error';

export class Editing extends Plugin {
  static get requires(): typeof Plugin[] {
    return [Widget];
  }

  public init(): void {
    this.defineSchema();
    this.defineConverters();
    this.defineChildCheck();
    this.defineCommands();
  }

  //

  private defineSchema(): void {
    const schema = this.editor.model.schema;

    schema.register(Keyword.widgetModel, {
      inheritAllFrom: '$blockObject',
      allowChildren: [Keyword.panelModel, Keyword.metaModel],
    });

    schema.register(Keyword.metaContainerModel, {
      isLimit: true,
      isSelectable: false,
      allowIn: Keyword.widgetModel,
      allowChildren: [Keyword.metaModel],
    });

    schema.register(Keyword.metaModel, {
      inheritAllFrom: '$blockObject',
      isInline: true,
      allowIn: Keyword.metaContainerModel,
      allowAttributes: ['data'],
    });

    schema.register(Keyword.panelModel, {
      isLimit: true,
      allowIn: Keyword.widgetModel,
      allowContentOf: '$root',
    });
  }

  private defineConverters(): void {
    this.defineWidgetConverters();
    this.definePanelConverters();
    this.defineMetaContainerConverters();
    this.defineMetaConverters();
  }

  private defineWidgetConverters(): void {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: Keyword.widgetElement,
        classes: Keyword.widgetClass,
      },
      model: Keyword.widgetModel,
    });

    conversion.for('dataDowncast').elementToElement({
      model: Keyword.widgetModel,
      view: {
        name: Keyword.widgetElement,
        classes: Keyword.widgetClass,
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: Keyword.widgetModel,
      view: (modelElement, {writer: viewWriter}) => {
        const container = viewWriter.createContainerElement(Keyword.widgetElement, {
          class: Keyword.widgetClass,
        });

        return toWidget(container, viewWriter, {
          label: Keyword.widgetInsertLabel,
          hasSelectionHandle: true,
        });
      },
    });
  }

  private definePanelConverters(): void {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: Keyword.panelElement,
        classes: Keyword.panelClass,
      },
      model: Keyword.panelModel,
    });

    conversion.for('dataDowncast').elementToElement({
      model: Keyword.panelModel,
      view: {
        name: Keyword.panelElement,
        classes: Keyword.panelClass,
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: Keyword.panelModel,
      view: (modelElement, {writer: viewWriter}) => {
        const container = viewWriter.createEditableElement(Keyword.panelElement, {
          class: Keyword.panelClass,
        });

        return toWidgetEditable(container, viewWriter);
      },
    });
  }

  private defineMetaContainerConverters(): void {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: Keyword.metaContainerElement,
        classes: Keyword.metaContainerClass,
      },
      model: Keyword.metaContainerModel,
    });

    conversion.for('editingDowncast').elementToElement({
      model: Keyword.metaContainerModel,
      view: {
        name: Keyword.metaContainerElement,
        classes: Keyword.metaContainerClass,
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: Keyword.metaContainerModel,
      view: {
        name: Keyword.metaContainerElement,
        classes: Keyword.metaContainerClass,
      },
    });
  }

  private defineMetaConverters(): void {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'div',
        classes: [Keyword.metaClass],
      },
      model: (element, {writer}) => {
        const data = this.getData(element);
        if (data instanceof Error) throw data;

        return writer.createElement(Keyword.metaModel, {
          'data': data,
        });
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: Keyword.metaModel,
      view: (element, {writer}) => {
        const data = element.getAttribute('data') as [string, string[]] | undefined;
        if (!data) return null;

        const meta = this.createMetaContainer(writer, data);

        meta._setAttribute('onclick', 'Ck_Meta_Ui.showUI(this)');

        return toWidget(meta, writer);
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: Keyword.metaModel,
      view: (element, {writer}) => {
        const data = element.getAttribute('data') as [string, string[]] | undefined;

        return data && this.createMetaContainer(writer, data);
      },
    });
  }

  private defineChildCheck(): void {
    const schema = this.editor.model.schema;

    schema.addChildCheck((context, childDefinition) => {
      if (childDefinition.name !== Keyword.widgetModel) return;

      const length = context.length;
      if (length <= 1) return;

      for (let i = 0; i < length; i++)
        if (context.getItem(i)?.name === Keyword.widgetModel)
          return false;
    });
  }

  private defineCommands(): void {
    this.editor.commands.add(
      Keyword.widgetInsertCommand,
      new Command(this.editor),
    );

    this.editor.commands.add(
      Keyword.metaCommand,
      new MetaCommand(this.editor),
    );

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(
        this.editor.model,
        viewElement => {
          return viewElement.hasClass(Keyword.metaClass);
        },
      ),
    );
  }

  //

  private createMetaContainer(
    writer: DowncastWriter,
    data: [string, string[]],
  ): ViewContainerElement {
    const [help, tags] = data;

    return writer.createContainerElement(
      'div',
      {
        class: Keyword.metaClass,
      },
      [
        writer.createContainerElement(
          'div',
          {},
          [
            writer.createText(help),
          ],
        ),
        writer.createContainerElement(
          'div',
          {},
          tags.map(value => (
            writer.createContainerElement(
              'span',
              {},
              writer.createText(value),
            )
          )),
        ),
      ],
      {
        renderUnsafeAttributes: ['onclick'],
      },
    );
  }

  private getData(element: ViewNode): [string, string[]] | Error {
    if (!(element instanceof ViewElement) || element.name !== 'div') return new ConverterError(
      `${this.constructor.name}.${this.getData.name}`,
      'element не является div',
      {element},
    );

    const help = element.getChild(0);
    if (!(help instanceof ViewElement) || help.name !== 'div') return new ConverterError(
      `${this.constructor.name}.${this.getValues.name}`,
      'help не является div',
      {help},
    );

    const helpText = help.getChild(0);
    if (!(helpText instanceof ViewText)) return new ConverterError(
      `${this.constructor.name}.${this.getValues.name}`,
      'helpText не является ViewText',
      {helpText},
    );

    const helpValue = helpText.data.trim();

    const tags = this.getValues(element.getChild(1));
    if (tags instanceof Error) return tags;

    return [helpValue, tags];
  }

  private getValues(list?: ViewNode): string[] | Error {
    if (!(list instanceof ViewElement) || list.name !== 'div') return new ConverterError(
      `${this.constructor.name}.${this.getValues.name}`,
      'list не является div',
      {list},
    );

    const values: string[] = [];

    for (const item of list.getChildren()) {
      if (!(item instanceof ViewElement) || item.name !== 'span') return new ConverterError(
        `${this.constructor.name}.${this.getValues.name}`,
        'item не является Span',
        {item},
      );

      const text = item.getChild(0);
      if (!(text instanceof ViewText)) return new ConverterError(
        `${this.constructor.name}.${this.getValues.name}`,
        'text не является ViewText',
        {text},
      );

      const value = text.data.trim();

      value && values.push(value);
    }

    return values;
  }
}