import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {ModelData} from './Form';
import {AnswerModelName} from '#global';
import {EditingBase, NodeName} from '#includes/content/editor/plugins/utils';
import {
  SchemaCompiledItemDefinition,
  SchemaContext,
} from '@ckeditor/ckeditor5-engine/src/model/schema';

export class Editing extends EditingBase<ModelData> {
  protected readonly modelName = AnswerModelName.dropListModel;
  protected readonly modelElement: NodeName = 'span';
  protected readonly isInline = true;
  protected readonly modelClass = Keyword.dropListClass;
  protected readonly commandsMap = {[Keyword.dropListCommand]: Command};
  protected readonly uiName = 'Ck_Exercise_DropList_Ui';

  protected childCheck(
    context: SchemaContext,
    definition: SchemaCompiledItemDefinition,
  ): false | void {
    return this.childCheckInExercise(context, definition);
  }

  protected getIsEmpty(
    data: ModelData,
  ): boolean {
    return data.length === 0;
  }
}