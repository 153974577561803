import {AnswerData, ExerciseMetaData, ParagraphData} from '#global';
import {modelConverter} from '#includes/content';
import {Keyword} from '#includes/content/editor/Keyword';
import {ConverterError} from '#includes/error';
import {modelClasses} from '#includes/content/editor/plugins';

function extractTagNames(exercise: Element): string[] | Error {
  const tagNames: string[] = [];

  const metadata = exercise.getElementsByClassName(Keyword.metaClass)[0];

  const exerciseTags = metadata?.lastChild;
  if (!(exerciseTags instanceof HTMLDivElement)) return new ConverterError(
    extractMeta.name,
    'exerciseTags не является Div',
  );

  for (let i = 0; i < exerciseTags.childNodes.length; i++) {
    const exerciseTag = exerciseTags.childNodes[i];
    if (!(exerciseTag instanceof HTMLSpanElement)) return new ConverterError(
      extractMeta.name,
      'exerciseTag не является Span',
    );

    tagNames.push(exerciseTag.innerHTML);
  }

  return tagNames;
}

function extractMeta(exercise: Element): ExerciseMetaData | Error {
  const metadata = exercise.getElementsByClassName(Keyword.metaClass)[0];

  const exerciseHelp = metadata?.firstChild;
  if (!(exerciseHelp instanceof HTMLDivElement)) return new ConverterError(
    extractMeta.name,
    'exerciseHelp не является Div',
    {exerciseHelp},
  );

  return {
    help: exerciseHelp.innerHTML,
  };
}

function extractAnswersData(
  exercise: Element,
): AnswerData[] | Error {
  const answersData: AnswerData[] = [];

  const answers = exercise.getElementsByClassName(Keyword.answerClass);
  for (const answer of answers) {
    const modelClass = modelClasses.find(([, className]) => (
      answer.classList.contains(className)
    ));

    if (!modelClass) return new ConverterError(
      'extractAnswerData',
      'answer не имеет необходимый класс. ' +
      {answer},
    );

    const model = modelClass[0];

    const value = modelConverter[model].toAnswerValue(answer, false);
    if (value instanceof Error) return value;

    answersData.push({model, value} as AnswerData);
  }

  return answersData;
}

export type ExerciseInfo = {
  tagNames: string[],
  answersData: AnswerData[],
  meta: ExerciseMetaData,
};

export function extractExercisesInfo(
  editorContent: NonNullable<ParagraphData['content']>,
): ExerciseInfo[] | Error {
  const contentBody = new DOMParser()
    .parseFromString(editorContent, 'text/html')
    .body;

  const exercisesInfo: ExerciseInfo[] = [];

  const exercises = contentBody.getElementsByClassName(Keyword.widgetClass);
  for (let i = 0; i < exercises.length; i++) {
    const meta = extractMeta(exercises[i]);
    if (meta instanceof Error) return meta;

    const tagNames = extractTagNames(exercises[i]);
    if (tagNames instanceof Error) return tagNames;

    const answerData = extractAnswersData(exercises[i]);
    if (answerData instanceof Error) return answerData;

    exercisesInfo.push({
      meta: meta,
      tagNames: tagNames,
      answersData: answerData,
    });
  }

  return exercisesInfo;
}