import {css} from '@emotion/react';
import {Button, Container, List, ListProps} from '#components/atoms';
import {getEm} from '#includes/utils';
import {palette} from '#config';
import {observer} from 'mobx-react-lite';

type EntityValue = string | number | null;

type EntityType = {
  id: number,
} & { [p: string | number | symbol]: EntityValue };

type EntityEditorPropsMin<Entity extends EntityType> = {
  list: Entity[],
  onOpen?: (id: number) => void,
  onAdd?: () => void,
  onEdit?: (id: number) => void,
  onRemove?: (id: number) => void,
};

type EntityEditorProps<Entity extends EntityType> =
  Omit<ListProps, keyof EntityEditorPropsMin<Entity> | 'children'>
  & EntityEditorPropsMin<Entity>;

const styles = {
  base: () => css({
    marginTop: getEm(20),
    alignItems: 'flex-start',
  }),
  item: () => css({
    width: '100%',
  }),
  content: () => css({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: '100%',
    flexGrow: 1,
    color: palette.black,
  }),
  buttons: () => css({
    flexWrap: 'wrap',
    minHeight: '100%',
  }),
};

export const EntityEditor = observer(<Entity extends EntityType>(
  props: EntityEditorProps<Entity>,
) => {
  const {
    list: entities,
    onOpen,
    onAdd,
    onEdit,
    onRemove,
    ...otherProps
  } = props;

  return (
    <List css={styles.base()} {...otherProps}>
      {entities.map(({id, ...entity}) => (
        <List key={id} css={styles.item()} horizontal={true}>
          {onOpen ? (
            <Button
              css={styles.content()}
              printing={'outlined'}
              onClick={() => onOpen(id)}
            >
              {Object.keys(entity).map(key => entity[key] ?? '***').join(' ')}
            </Button>
          ) : (
            <Container
              css={styles.content()}
              printing={'outlined'}
            >
              {Object.keys(entity).map(key => entity[key] ?? '***').join(' ')}
            </Container>
          )}
          {(onEdit || onRemove) && (
            <List css={styles.buttons()} horizontal={true}>
              {onEdit && (
                <Button onClick={() => onEdit(id)}>
                  Изменить
                </Button>
              )}
              {onRemove && (
                <Button printing={'outlined'} color={'red'} onClick={() => onRemove(id)}>
                  Удалить
                </Button>
              )}
            </List>
          )}
        </List>
      ))}
      {onAdd && (
        <Button css={styles.item()} printing={'outlined'} onClick={() => onAdd()}>
          Добавить
        </Button>
      )}
    </List>
  );
});