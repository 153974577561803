import type {InputTextView} from '@ckeditor/ckeditor5-ui';
import {createLabeledInputText, LabeledFieldView} from '@ckeditor/ckeditor5-ui';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseView} from '#includes/content/editor/Views';

export class InputView extends BaseView<LabeledFieldView<InputTextView>> {
  private constructor(
    locale: Locale,
    className: string | string[] = [],
    label: string = '',
  ) {
    super(locale, new LabeledFieldView<InputTextView>(
      locale,
      createLabeledInputText,
    ));

    this.ckView.set({
      label: locale.t(label),
      class: ['ck', Keyword.inputClass, className].flat().join(' '),
    });
  }

  public static create(
    locale: Locale,
    className: string | string[] = [],
    label: string = '',
  ): InputView {
    return new InputView(
      locale,
      className,
      label,
    );
  }

  public setValue(value: string = ''): void {
    this.ckView.fieldView.value = value;

    if (!this.ckView.fieldView.element) return;
    this.ckView.fieldView.element.value = value;
  }

  public getValue(): string {
    return this.ckView.fieldView.element?.value || '';
  }

  public focus(): void {
    this.ckView.focus();
  }
}