import {DowncastWriter, ViewElementAttributes, ViewNode} from '@ckeditor/ckeditor5-engine';
import {ConverterBase} from '#includes/content/editor/plugins/utils';
import {ConverterError} from '#includes/error';
import {AnswerDropListData, AnswerModelName} from '#global';
import {ModelData} from './Form';
import {SwitchDatum} from '#includes/content/editor/Forms';
import {Keyword} from '#includes/content/editor/Keyword';

export const Converter = new (class Converter extends ConverterBase<
  AnswerModelName.dropListModel,
  ModelData
> {
  public toAnswerValue(
    container: Element,
    isView: boolean,
  ): AnswerDropListData['value'] | Error {
    if (!(container instanceof HTMLSpanElement)) return new ConverterError(
      `DropListConverter.${this.toAnswerValue.name}`,
      'container не является Span',
      {container},
    );

    const select = container.firstChild;
    if (!(select instanceof HTMLSelectElement)) return new ConverterError(
      `DropListConverter.${this.toAnswerValue.name}`,
      'select не является Select',
      {select},
    );

    const selected = select.options[select.options.selectedIndex];
    if (!selected || selected.disabled) return '';

    return selected.innerHTML;
  }

  public toView(
    data: Element,
  ): Element | Error {
    if (!(data instanceof HTMLSpanElement)) return new ConverterError(
      `DropListConverter.${this.toView.name}`,
      'data не является Span',
      {data},
    );

    const select = data.firstChild;
    if (!(select instanceof HTMLSelectElement)) return new ConverterError(
      `DropListConverter.${this.toView.name}`,
      'select не является Select',
      {select},
    );

    const firstOption = document.createElement('option');
    firstOption.setAttribute('disabled', 'disabled');
    firstOption.setAttribute('selected', 'true');
    firstOption.innerText = 'Выберите ответ';

    select.options.add(firstOption, 0);

    return data;
  }

  public activateView(
    view: Element,
  ): Element | Error {
    return view;
  }

  public toData(
    editor: Element,
  ): Element | Error {
    if (!(editor instanceof HTMLSpanElement)) return new ConverterError(
      `DropListConverter.${this.toData.name}`,
      'editor не является Span',
      {editor},
    );

    const select = editor.firstChild;
    if (!(select instanceof HTMLSelectElement)) return new ConverterError(
      `DropListConverter.${this.toData.name}`,
      'select не является Select',
      {select},
    );

    for (const option of select.childNodes) {
      if (!(option instanceof HTMLOptionElement)) return new ConverterError(
        `DropListConverter.${this.toData.name}`,
        'option не является Option',
        {option},
      );

      option.selected = false;
      option.removeAttribute('selected');
    }

    return editor;
  }

  public toEditor(
    data: Element,
    answer: AnswerDropListData['value'],
  ): Element | Error {
    if (!(data instanceof HTMLSpanElement)) return new ConverterError(
      `DropListConverter.${this.toEditor.name}`,
      'data не является Span',
      {data},
    );

    const select = data.firstChild;
    if (!(select instanceof HTMLSelectElement)) return new ConverterError(
      `DropListConverter.${this.toEditor.name}`,
      'select не является Select',
      {select},
    );

    const options = select.childNodes;
    for (let j = 0; j < options.length; j++) {
      const option = options[j];
      if (!(option instanceof HTMLOptionElement)) return new ConverterError(
        `DropListConverter.${this.toEditor.name}`,
        'option не является Option',
        {option},
      );

      const isCorrect = answer === option.innerText;
      isCorrect && option.setAttribute('selected', 'selected');
    }

    return data;
  }

  public toModelData(
    editor: Node | ViewNode,
  ): ModelData | Error {
    const container = this.toNodeData(editor);
    if (container.isText() || !container.nameIs('span')) return new ConverterError(
      `DropListConverter.${this.toModelData.name}`,
      'container не является Span',
      {container},
    );

    const select = container.getChild(0);
    if (!select || select.isText() || !select.nameIs('select')) return new ConverterError(
      `DropListConverter.${this.toModelData.name}`,
      'select не является Select',
      {select},
    );

    const data: ModelData = [];

    for (const option of select.children) {
      if (option.isText() || !option.nameIs('option')) return new ConverterError(
        `DropListConverter.${this.toModelData.name}`,
        'option не является Option',
        {option},
      );

      // Правильный ответ должен быть включён
      if (option.disabled) continue;

      const value = this.getInnerText(option);
      if (value instanceof Error) return value;

      data.push(new SwitchDatum(value, option.selected));
    }

    return data;
  }

  public toModel(
    writer: DowncastWriter,
    data: ModelData,
    isEditor: boolean,
  ): { attributes: ViewElementAttributes, children: ViewNode[] } | Error {
    return {
      attributes: {
        class: [Keyword.answerClass, Keyword.dropListClass].join(' '),
      },
      children: [
        writer.createContainerElement('select', {}, (
          data.map(datum => (
            writer.createContainerElement('option', (
              datum.enabled
                ? {selected: 'selected'}
                : {}
            ), [
              writer.createText(datum.value),
            ])
          ))
        )),
      ],
    };
  }
})();