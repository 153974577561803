import {HttpMethod, ListData, VariantArgs, VariantData, VariantEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_VariantsRoute = {
  createVariant: new g_Route<null, VariantData, VariantArgs>(
    HttpMethod.post,
    'variants',
    'Добавить вариант',
  ),
  createVariants: new g_Route<null, ListData<VariantData>, VariantArgs[]>(
    HttpMethod.post,
    'variants/many',
    'Добавить варианты',
  ),
  getVariants: new g_Route<null, ListData<VariantData>>(
    HttpMethod.get,
    'variants',
    'Получить все варианты',
  ),
  getVariantsMany: new g_Route<null, ListData<VariantData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'variants/many',
    'Получить варианты',
  ),
  getVariant: new g_Route<{ id: number }, VariantData>(
    HttpMethod.get,
    'variants/:id',
    'Получить вариант',
  ),
  editVariant: new g_Route<{ id: number }, VariantData, Partial<VariantArgs>>(
    HttpMethod.put,
    'variants/:id',
    'Изменить вариант',
  ),
  editVariants: new g_Route<null, ListData<VariantData>, VariantEditionArgs[]>(
    HttpMethod.put,
    'variants/many',
    'Изменить варианты',
  ),
  deleteVariant: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'variants/:id',
    'Удалить вариант',
  ),
  deleteVariants: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'variants/many',
    'Удалить варианты',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_VariantsRoute;
