import {ViewElement} from '@ckeditor/ckeditor5-engine';
import type {NodeName} from './NodeName';
import {NodeData} from './NodeData';
import {ConverterError} from '#includes/error';
import {TextData} from './TextData';
import {toNodeData} from './toNodeData';

type SpecialElementName = 'input' | 'option' | 'img';
type ElementName = Exclude<NodeName, 'text'>;
type SimpleElementName = Exclude<ElementName, SpecialElementName>;

type SimpleElementData<N extends SimpleElementName> = ElementData & {
  name: N,
  value: never,
  checked: never,
  selected: never,
  disabled: never,
  src: never,
};

type InputData = ElementData & {
  name: Extract<SpecialElementName, 'input'>,
  value: string,
  checked: boolean,
  disabled: boolean,
};

type OptionData = ElementData & {
  name: Extract<SpecialElementName, 'option'>,
  selected: boolean,
  disabled: boolean,
};

type ImgData = ElementData & {
  name: Extract<SpecialElementName, 'img'>,
  src: string,
};

type SpecialMap = {
  input: InputData,
  option: OptionData,
  img: ImgData,
};

type ElementDataByName<N extends ElementName> =
  N extends SpecialElementName
    ? SpecialMap[N]
    : SimpleElementData<Exclude<N, SpecialElementName>>;

export class ElementData extends NodeData {
  protected readonly element: Element | ViewElement;
  public readonly name: ElementName;

  public constructor(node: Element | ViewElement) {
    super();

    this.element = node;

    const name = NodeData.getName(node);
    if (name === 'text') throw new ConverterError(
      'ElementData.constructor',
      `node с этим именем (${name}) не может быть ElementData`,
      {node},
    );
    this.name = name;
  }

  public nameIs<N extends ElementName>(name: N): this is ElementDataByName<N> {
    return this.name === name;
  }

  public get classes(): Iterable<string> {
    return this.element instanceof Element
      ? this.element.classList.values()
      : this.element.getClassNames();
  }

  public hasClass(className: string): boolean {
    return this.element instanceof Element
      ? this.element.classList.contains(className)
      : this.element.hasClass(className);
  }

  public get children(): Iterable<ElementData | TextData> {
    return {
      [Symbol.iterator]: (): Iterator<ElementData | TextData, undefined> => {
        const iterator = this.element instanceof Element
          ? this.element.childNodes[Symbol.iterator]()
          : this.element.getChildren();

        return {
          next: () => {
            const result = iterator.next();

            return result.done ? {
              done: true,
              value: undefined,
            } : {
              value: toNodeData(result.value),
            };
          },
        };
      },
    };
  }

  public get childCount(): number {
    return this.element instanceof Element
      ? this.element.childElementCount
      : this.element.childCount;
  }

  public getChild(index: number): ElementData | TextData | undefined {
    const child = this.element instanceof Element
      ? this.element.childNodes[index]
      : this.element.getChild(index);

    return child && toNodeData(child);
  }

  public getAttribute(name: string): string | null | undefined {
    return this.element.getAttribute(name);
  }

  public get src(): string {
    const element = this.element as { src?: string };
    if (typeof element.src === 'string') return element.src;
    return this.element.getAttribute('src') || '';
  }

  public get disabled(): boolean {
    const element = this.element as { disabled?: boolean };
    if (typeof element.disabled === 'boolean') return element.disabled;
    return this.element.hasAttribute('disabled');
  }

  public get value(): string {
    const element = this.element as { value?: string };
    if (typeof element.value === 'string') return element.value;
    return this.element.getAttribute('value') || '';
  }

  public get checked(): boolean {
    const element = this.element as { checked?: boolean };
    if (typeof element.checked === 'boolean') return element.checked;
    return this.element.hasAttribute('checked');
  }

  public get selected(): boolean {
    const element = this.element as { selected?: boolean };
    if (typeof element.selected === 'boolean') return element.selected;
    return this.element.hasAttribute('selected');
  }
}