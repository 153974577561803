// noinspection ES6PreferShortImport

import {lightTheme} from './light';

export type ThemeType = typeof lightTheme;

export const themes = {
  light: lightTheme,
} as const;

export type ThemeName = keyof typeof themes;

// noinspection JSUnusedLocalSymbols
const security: { [key in ThemeName]: ThemeType } = themes;

(globalThis as any).themes = themes;

export * from './types';
