// noinspection ES6ConvertVarToLetConst

import {SuperArray} from '#global';
import {hash32} from '#includes/hash32';

export class Random {
  public constructor(seed?: string) {
    this.next = this.mulberry32(seed === undefined ? seed : hash32(seed));
  }

  public readonly next: () => number;

  // Создаёт массив индексов в случайном порядке, отличающийся от beDifferentFrom
  public createRandomizedIndexes(length: number, beDifferentFrom?: number[]): number[] {
    if (length < 2) {
      throw new Error(
        `${this.constructor.name}.${this.createRandomizedIndexes.name}` +
        `length (${length}) должен быть 2 или больше`,
      );
    }

    const inOrderArray = new SuperArray(length, index => index).array;

    if (beDifferentFrom === undefined)
      beDifferentFrom = inOrderArray;

    if (beDifferentFrom.length !== length) {
      throw new Error(
        `${this.constructor.name}.${this.createRandomizedIndexes.name}` +
        `${beDifferentFrom} должен иметь размер равный length (${length})`,
      );
    }

    if (length === 2) return [beDifferentFrom[1], beDifferentFrom[0]];
    if (length === 3) return this.next() > 0.5
      ? [beDifferentFrom[2], beDifferentFrom[0], beDifferentFrom[1]]
      : [beDifferentFrom[1], beDifferentFrom[2], beDifferentFrom[0]];

    const randomized = [...inOrderArray].sort(() => this.next() - 0.5);

    let first = 0;
    let second = length - 1;
    while (first < second) {
      while (first < second && randomized[first] !== beDifferentFrom[first]) {
        first++;
      }
      while (first < second && randomized[second] !== beDifferentFrom[second]) {
        second--;
      }
      if (
        randomized[first] !== beDifferentFrom[first]
        && randomized[second] !== beDifferentFrom[second]
      ) break;
      const tmp = randomized[second];
      randomized[second] = randomized[first];
      randomized[first] = tmp;
      first++;
      second--;
    }

    return randomized;
  }

  //

  // Получить генератор псевдослучайных чисел с помощью 32-битного сида
  private mulberry32(seed: number = Math.random() * 10000) {
    seed = Math.floor(seed);

    return (): number => {
      var t = seed += 0x6D2B79F5;
      t = Math.imul(t ^ t >>> 15, t | 1);
      t ^= t + Math.imul(t ^ t >>> 7, t | 61);
      return ((t ^ t >>> 14) >>> 0) / 4294967296;
    };
  }
}

(globalThis as any).Random = Random;