import {HttpMethod, IpData, ListData} from '../../';
import {g_Route} from '../../utils';

export const g_IpsRoute = {
  getIps: new g_Route<null, ListData<IpData>>(
    HttpMethod.get,
    'ips',
    'Получить все IP адреса',
  ),
  getIpsMany: new g_Route<null, ListData<IpData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'ips/many',
    'Получить IP адреса',
  ),
  getIp: new g_Route<{ id: number }, IpData>(
    HttpMethod.get,
    'ips/:id',
    'Получить IP адрес',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_IpsRoute;
