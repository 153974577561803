// noinspection CssUnknownTarget

import {HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {AnswerData, AnswerModelName, BadCode, ParagraphData} from '#global';
import {Audio, Book, Video} from '#components/atoms';
import {styles} from './styles';
import {modelConverter} from '#includes/content';
import {ContentConverter} from '#includes/content/Converter';
import {ConverterError} from '#includes/error';
import {notifications} from '#store';
import {Keyword} from '#includes/content/editor/Keyword';
import {useEffectOnce} from 'usehooks-ts';
import {Global} from '@emotion/react';
import {Insertable} from '#includes/Interactive';
import {useAxios} from '#hooks';
import {ApiRoute} from '#includes/ApiRoute';
import {createRoot} from 'react-dom/client';
import {render} from '#includes/content/editor/Views/Formula/render';

type ContentViewerPropsMin = {
  children?: Readonly<ParagraphData> | null,
  onPageChange?: (page: number, lastPage: number) => void,
};

type ContentViewerProps =
  Omit<HTMLAttributes<HTMLElement>, keyof ContentViewerPropsMin>
  & ContentViewerPropsMin;

type ClickOrKeydownEvent = {
  preventDefault: () => void,
  keyCode?: number,
  currentTarget?: (EventTarget & { name?: string | null }) | null,
};

type Answer = {
  container: Element,
  exerciseIndex: number,
  model: AnswerModelName,
};

const toAnswer = (container: Element, view: HTMLElement): Answer | Error => {
  const name = container.getAttribute('name');
  if (!name) return new ConverterError(
    'ContentViewer.useEffectOnce.answers',
    'Не установлено имя',
    {container},
  );

  const split = name.split('-');
  const model = split[0] as AnswerModelName;
  const exerciseIndex = Number(split[1]);

  const activated = modelConverter[model].activateView(container, view);
  if (activated instanceof Error) return activated;

  return {container: activated, model, exerciseIndex};
};

export const ContentViewer = observer<ContentViewerProps>(props => {
  const {
    className,
    children: paragraph,
    onPageChange,
    ...otherProps
  } = props;

  const apiHelpExercise = useAxios(ApiRoute.getExerciseHelp, {
    success: ({help}) => {
      notifications.addInfo(`Подсказка: ${help}`);
    },
    fail: ({message}) => {
      notifications.addError(message);
    },
  });

  const apiCheckExercise = useAxios(ApiRoute.checkExercise, {
    success: () => {
      notifications.addInfo('Верно!');
    },
    fail: ({message}) => {
      notifications.addError(message);
    },
  });

  const ref = useRef<HTMLDivElement>(null);

  const pages = useMemo<string[]>(
    () => {
      const viewer = ContentConverter.dataToViewer(paragraph?.content);
      if (viewer instanceof Error) {
        notifications.addError(viewer);
        return [];
      }

      return viewer;
    },
    [paragraph],
  );

  const viewContent = useMemo(() => (
    pages.length < 2 ? (
      <section
        className={'page cv-content'}
        dangerouslySetInnerHTML={{__html: pages[0]}}
      />
    ) : (
      <Book css={styles.book} onPageChange={onPageChange}>
        {pages.map((page, index) => (
          <section
            key={index}
            className={'page cv-content'}
            dangerouslySetInnerHTML={{__html: page}}
          />
        ))}
      </Book>
    )
  ), [onPageChange, pages]);

  const [answers, setAnswers] = useState<Answer[]>([]);

  useEffectOnce(() => {
    const view = document.querySelector('.cv-content');
    if (!(view instanceof HTMLElement))
      throw new Error('.cv-content не является HTMLElement');

    const videos = [...document.getElementsByClassName(Keyword.videoClass)];
    for (const video of videos) {
      if (!(video instanceof HTMLDivElement)) continue;

      const videoElement = video.firstChild;
      if (!(videoElement instanceof HTMLVideoElement)) continue;

      const src = videoElement.getAttribute('src');

      video.innerHTML = '';

      createRoot(video).render(<Video key={src} url={src}/>);
    }

    const audios = [...document.getElementsByClassName(Keyword.audioClass)];
    for (const audio of audios) {
      if (!(audio instanceof HTMLDivElement)) continue;

      const audioElement = audio.firstChild;
      if (!(audioElement instanceof HTMLAudioElement)) continue;

      const src = audioElement.getAttribute('src') || '';

      audio.innerHTML = '';

      createRoot(audio).render(<Audio key={src} src={src}/>);
    }

    const formulas = [...document.getElementsByClassName(Keyword.formulaClass)];
    for (const formula of formulas) {
      if (!(formula instanceof HTMLSpanElement)) continue;

      const value = formula.getAttribute('data-value');
      if (!value) continue;

      const container = formula.firstChild;
      if (!(container instanceof HTMLSpanElement)) continue;

      render(container, value);
      container.onclick = () => {
        navigator.clipboard.writeText(value);
        notifications.addInfo('Формула скопирована');
      };
    }

    const answers: Answer[] = [];

    const containers = [...document.getElementsByClassName(Keyword.answerClass)];
    for (let i = 0; i < containers.length; i++) {
      const container = containers[i];

      const answer = toAnswer(container, view);
      if (answer instanceof Error) {
        notifications.addError(answer);
        return void setAnswers([]);
      }

      answers.push(answer);
    }

    setAnswers(answers);

    return () => {
      Insertable.deactivateAll();
      setAnswers([]);
    };
  });

  const helpHandler = useCallback((e: ClickOrKeydownEvent) => {
    const target = e.currentTarget;
    if (!(target instanceof HTMLElement)) return;

    const name = target.getAttribute('name');
    if (!name) return;

    const exerciseIndex = name.split('-')[1] as string | undefined;
    if (!exerciseIndex) return;

    if (!paragraph) return;
    const exerciseId = paragraph.ExercisesId[Number(exerciseIndex)];

    apiHelpExercise.request({id: exerciseId});
  }, [paragraph]);

  const sendHandler = useCallback(async (exerciseIndex: number) => {
    if (!paragraph) return;

    const answersForExercise = answers
      .filter(answer => answer.exerciseIndex === exerciseIndex);

    const exerciseId = paragraph.ExercisesId[exerciseIndex];

    const answersData: AnswerData[] = [];
    for (const {model, container} of answersForExercise) {
      const value = modelConverter[model].toAnswerValue(container, true);
      if (value instanceof Error)
        return void notifications.addError(value);

      answersData.push({value, model} as AnswerData);
    }

    const response = await apiCheckExercise.request({id: exerciseId}, answersData);
    if (response.code === BadCode.falsy)
      await apiHelpExercise.request({id: exerciseId});
  }, [paragraph, answers]);

  const submitHandler = useCallback((e: ClickOrKeydownEvent) => {
    if (e.keyCode !== undefined && e.keyCode !== 13) return;
    if (!e.currentTarget || !e.currentTarget.name) return;

    const exerciseIndex = e.currentTarget.name.split('-')[1] as string | undefined;
    if (!exerciseIndex) return;

    sendHandler(Number(exerciseIndex));
  }, [helpHandler, sendHandler]);

  useEffect(() => {
    if (!ref.current) return;

    const answers = ref.current.getElementsByClassName(Keyword.textClass);
    const submits = ref.current.getElementsByClassName('exercise-submit');
    const helps = ref.current.getElementsByClassName('exercise-meta-help');

    for (let answerIndex = 0; answerIndex < answers.length; answerIndex++)
      answers[answerIndex].addEventListener('keydown', submitHandler);
    for (let submitIndex = 0; submitIndex < submits.length; submitIndex++)
      submits[submitIndex].addEventListener('click', submitHandler);
    for (let helpIndex = 0; helpIndex < helps.length; helpIndex++)
      helps[helpIndex].addEventListener('click', helpHandler);

    return () => {
      for (let answerIndex = 0; answerIndex < answers.length; answerIndex++)
        answers[answerIndex].removeEventListener('keydown', submitHandler);
      for (let submitIndex = 0; submitIndex < submits.length; submitIndex++)
        submits[submitIndex].removeEventListener('click', submitHandler);
      for (let helpIndex = 0; helpIndex < helps.length; helpIndex++)
        helps[helpIndex].removeEventListener('click', helpHandler);
    };
  }, [ref.current, submitHandler]);

  return (
    <div
      css={styles.base}
      className={className ? (className + ' cv') : 'cv'}
      {...otherProps}
      ref={ref}
    >
      <Global styles={styles.global}/>
      {paragraph?.name && (
        <h1>
          {paragraph?.name}
        </h1>
      )}
      {viewContent}
    </div>
  );
});