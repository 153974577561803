import {isProduction, urls} from '#config';

export enum ConverterPattern {
  pageSeparator = '$PAGE_SEPARATOR$',
  media = '/media/',
}

class Converter {
  private readonly editorPattern: string;
  private readonly dataPattern: ConverterPattern;
  private readonly getWhetherFillShouldBeSkipped: () => boolean;

  public constructor(
    editorPattern: string,
    dataPattern: ConverterPattern,
    getWhetherFillShouldBeSkipped: () => boolean = () => false,
  ) {
    this.editorPattern = editorPattern;
    this.dataPattern = dataPattern;
    this.getWhetherFillShouldBeSkipped = getWhetherFillShouldBeSkipped;
  }

  public clearEditorContent(editorContent: string): string {
    return editorContent.replaceAll(
      this.editorPattern,
      this.dataPattern,
    );
  }

  public fillDataContent(dataContent: string): string {
    if (this.getWhetherFillShouldBeSkipped())
      return dataContent;

    return dataContent.replaceAll(
      this.dataPattern,
      this.editorPattern,
    );
  }
}

export const stringsConverters: Converter[] = [
  new Converter(
    (
      '<div class="page-break" style="page-break-after:always;">' +
      '<span style="display:none;">&nbsp;</span>' +
      '</div>'
    ),
    ConverterPattern.pageSeparator,
  ),
  new Converter(
    `${urls.backend}/media/`,
    ConverterPattern.media,
    () => isProduction,
  ),
];