import type {Locale} from '@ckeditor/ckeditor5-utils';
import {ButtonView, ContainerView, FormulaView, InputView} from '#includes/content/editor/Views';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseForm} from '#includes/content/editor/Forms';

export class Form extends BaseForm<[ContainerView<ButtonView[]>, InputView, FormulaView], string> {
  private readonly buttons: ContainerView<ButtonView[]>;

  private readonly input: InputView;
  private readonly preview: FormulaView;

  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.formulaFormClass, cancelCallback);

    this.buttons = ContainerView.create<ButtonView[]>(locale, []);

    this.input = InputView.create(locale);

    this.preview = FormulaView.create(locale);

    this.input.ckView.fieldView.on('input', () => {
      this.preview.setValue(this.input.getValue());
    });

    const sqrt = ButtonView.create(locale, [], '√');
    sqrt.setHandler(() => this.insertText('sqrt(', ')'));

    const pow = ButtonView.create(locale, [], '^');
    pow.setHandler(() => this.insertText('', '^2'));

    this.buttons.setChildren([pow, sqrt]);

    this.form.setChildren([
      this.buttons,
      this.input,
      this.preview,
    ]);
  }

  public getData(): string {
    return this.input.getValue();
  }

  public override setData(data: string = ''): void {
    this.input.setValue(data);
    this.preview.setValue(data);

    super.setData(data);
  }

  //

  private insertText(start: string, end: string): void {
    this.input.focus();

    const element = this.input.ckView.fieldView.element;
    if (!element) return;

    const value = this.input.getValue();

    const {selectionStart, selectionEnd} = element;

    if (selectionStart === null || selectionEnd === null) return;

    const startText = value.slice(0, selectionStart);
    const selectionText = value.slice(selectionStart, selectionEnd);
    const endText = value.slice(selectionEnd, value.length);

    const result = `${startText}${start}${selectionText}${end}${endText}`;

    this.setData(result);

    element.setSelectionRange(
      startText.length + start.length,
      result.length - (endText.length + end.length),
    );
  }
}