import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {breadcrumbs} from '#store';
import {Link, List} from '#components/atoms';
import {getEm} from '#includes/utils';
import {MdKeyboardArrowRight} from '@react-icons/all-files/md/MdKeyboardArrowRight';
import {Fragment} from 'react';

const styles = {
  base: () => css({
    width: '100%',
    padding: `${getEm(10)} ${getEm(14)}`,
    backgroundColor: '#0000000F',
    flexWrap: 'wrap',
  }),
  link: () => css({
    display: 'inline',
  }),
};

export const Breadcrumbs = observer(() => {
  return (
    <List css={styles.base()} horizontal={true}>
      {breadcrumbs.items.map(({content, url}, index) => (
        <Fragment key={index}>
          {index !== 0 && <MdKeyboardArrowRight/>}
          {index === breadcrumbs.items.length - 1 || !url ? (
            <div css={styles.link()}>
              {content}
            </div>
          ) : (
            <Link css={styles.link()} to={url}>
              {content}
            </Link>
          )}
        </Fragment>
      ))}
    </List>
  );
});