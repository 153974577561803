import {css} from '@emotion/react';
import {Keyword} from '#includes/content/editor/Keyword';
import {palette} from '#config';
import {getEm} from '#includes/utils';
import {theme} from '#store';

export const forms = css`
  // Вертикальные списки
  .${Keyword.dropListFormClass},
  .${Keyword.checkListFormClass},
  .${Keyword.sortingFormClass} > .${Keyword.listClass} > .${Keyword.itemClass} > .${Keyword.contentClass},
  .${Keyword.connectionsFormClass},
  .${Keyword.metaFormClass},
  .${Keyword.orderedFormClass} {
    & > .${Keyword.listClass} {
      display: flex;
      flex-direction: column;

      & > .${Keyword.itemClass} {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: ${getEm(2)};

        & > .${Keyword.contentClass} {
          grid-column: 1 / 2;

          display: flex;

          & > .${Keyword.inputClass} {
            flex-grow: 1;
          }

          & > .${Keyword.switchClass} {
            margin-left: ${getEm(10)};
          }
        }

        & > .${Keyword.removeClass} {
          grid-column: 2 / 3;
        }

        & > .${Keyword.beforeClass},
        & > .${Keyword.insertClass} {
          grid-column: 1 / 3;

          font-size: 0.555em;
        }
      }
    }
  }

  // Сортировка
  .${Keyword.sortingFormClass} {
    & > .${Keyword.listClass} {
      display: flex;
      flex-direction: row;
      gap: ${getEm(10)};

      & > .${Keyword.itemClass} {
        position: relative;
        display: grid;
        grid-template-rows: auto;
        grid-column-gap: ${getEm(10)};
        grid-row-gap: ${getEm(2)};

        &:first-of-type > .${Keyword.removeClass} {
          grid-column: 2 / 3 !important;
        }

        & > .${Keyword.contentClass} {
          grid-row: 1 / 2;

          & > .${Keyword.listClass} > .${Keyword.itemClass}:first-of-type {
            & > .${Keyword.beforeClass} {
              display: none;
            }

            & > .${Keyword.removeClass} {
              opacity: 0;
              pointer-events: none;
            }

            & > .${Keyword.contentClass} {
              grid-column: 1 / 2;

              & > .${Keyword.inputClass} {
                margin-bottom: ${getEm(10)};

                & .ck-input {
                  background-color: ${theme.current.basePalette.white};
                  color: ${theme.current.basePalette.hard};
                  text-align: center;
                  font-weight: bold;
                }
              }
            }
          }
        }

        & > .${Keyword.removeClass} {
          grid-row: 1 / 2;
          grid-column: 1 / 2;

          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
          color: ${theme.current.basePalette.hard};
        }

        & > .${Keyword.beforeClass},
        & > .${Keyword.insertClass} {
          grid-row: 1 / 3;

          font-size: 0.8em;
          color: ${theme.current.basePalette.hard};
        }
      }
    }
  }

  // Соединения
  .${Keyword.connectionsFormClass} {
    & > .${Keyword.containerClass} {
      grid-gap: ${getEm(2)};
    }

    & .${Keyword.contentClass} {
      gap: ${getEm(15)};
    }

    & .${Keyword.beforeClass},
    & .${Keyword.insertClass} {
      padding: 1em;
    }
  }

  // Последовательность
  .${Keyword.orderedFormClass} {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: ${getEm(2)};

    & > .${Keyword.switchClass} {
      border: solid 1px var(--ck-color-input-border) !important;
      order: -1;
    }
  }

  // Вставка видео и аудио
  .${Keyword.videoFormClass},
  .${Keyword.audioFormClass} {
    position: relative;
    display: flex;
    flex-direction: column;

    & .${Keyword.progressClass} {
      border-radius: var(--ck-border-radius);
      position: absolute;
      width: calc(100% - ${getEm(20)});
      height: calc(100% - ${getEm(17)});
      margin-bottom: 0;
      transition: height 300ms;
      opacity: 0.4;

      &::-webkit-progress-bar {
        background-color: transparent;
      }

      &::-webkit-progress-value {
        background-color: ${palette.green.soft};
      }
    }

    & .${Keyword.fileClass} div {
      display: none;
    }
  }
  
  // Вставка формулы
  .${Keyword.formulaFormClass} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    & > .${Keyword.formulaPreviewClass} {
      margin-top: ${getEm(7)};
      margin-bottom: 0;
      overflow: hidden;
    }
  }
`;