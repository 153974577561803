import {observer} from 'mobx-react-lite';
import {GlobalStyle as StyleController, GlobalStyleProps} from '#includes/GlobalStyle';
import {useEffect, useRef} from 'react';

export const GlobalStyle = observer<GlobalStyleProps>(props => {
  const controllerRef = useRef<StyleController>();
  const prevNameRef = useRef<string>(props.name);

  const render = (): void => void (controllerRef.current = StyleController.render(props));
  const destroy = (): void => void controllerRef.current?.destroy();

  useEffect(() => () => destroy(), []);

  prevNameRef.current !== props.name && destroy();
  prevNameRef.current = props.name;

  render();

  return null;
});