import {AnswerData, AnswerModelName, ExerciseData, ParagraphData} from '#global';
import {modelConverter} from '#includes/content';
import {Keyword} from '#includes/content/editor/Keyword';
import {stringsConverters} from './stringsConverters';
import {ConverterError} from '#includes/error';
import {modelClasses} from '#includes/content/editor/plugins';

export type ExerciseTagsData = ExerciseData & { Tags: string[] };

function fillMeta(
  contentBody: Element,
  data: ReadonlyArray<ExerciseTagsData>,
): Element | Error {
  const metaElements = contentBody.getElementsByClassName(Keyword.metaClass);
  if (data.length !== metaElements.length) return new ConverterError(
    'fillMeta',
    'data и metaElements имеют разную длину',
    {data, exercisesTags: metaElements},
  );

  for (let i = 0; i < metaElements.length; i++) {
    const metaElement = metaElements[i];

    const helpValue = data[i].meta.help || 'Нет подсказки';

    const helpElement = metaElement.firstChild;
    if (!(helpElement instanceof HTMLDivElement)) return new ConverterError(
      fillMeta.name,
      'helpElement не является Div',
      {helpElement},
    );

    helpElement.innerHTML = helpValue;

    const tagsValues = data[i].Tags;
    tagsValues.length === 0 && tagsValues.push('Нет меток');

    const tagsElement = metaElement.lastChild;
    if (!(tagsElement instanceof HTMLDivElement)) return new ConverterError(
      fillMeta.name,
      'tagsElement не является Div',
      {tagsElement},
    );

    tagsElement.innerHTML = '';
    for (const value of tagsValues) {
      const exerciseTag = document.createElement('span');
      exerciseTag.innerText = value;
      tagsElement.appendChild(exerciseTag);
    }
  }

  return contentBody;
}

function fillAnswer<Model extends AnswerModelName>(
  modelName: Model,
  className: string,
  contentBody: Element,
  answersData: AnswerData[],
): Element | Error {
  const containers = contentBody.getElementsByClassName(className);

  const data = answersData.filter(
    (answerData): answerData is AnswerData & { model: Model } => (
      answerData.model === modelName
    ),
  );

  if (containers.length !== data.length) return new ConverterError(
    'fillContent',
    'Количество containers и количество data не совпадает',
    {containers, data},
  );

  for (let i = 0; i < containers.length; i++) {
    const editor = modelConverter[modelName].toEditor(
      containers[i],
      data[i].value as any,
    );
    if (editor instanceof Error) return editor;
  }

  return contentBody;
}

export function fillContent(
  content: NonNullable<ParagraphData['content']>,
  exercises: ReadonlyArray<ExerciseTagsData>,
): NonNullable<ParagraphData['content']> | Error {
  const filledStringsContent = stringsConverters.reduceRight(
    (content, converter) => converter.fillDataContent(content),
    content,
  );

  const contentBody = new DOMParser()
    .parseFromString(filledStringsContent, 'text/html')
    .body;

  const filledBody = fillMeta(
    contentBody,
    exercises,
  );
  if (filledBody instanceof Error) return filledBody;

  const answers = exercises
    .flatMap(exercise => exercise.data);

  for (const [modelName, className] of modelClasses) {
    const filled = fillAnswer(
      modelName,
      className,
      filledBody,
      answers,
    );
    if (filled instanceof Error) return filled;
  }

  return filledBody.innerHTML;
}