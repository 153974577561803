import {DowncastWriter, ViewElementAttributes, ViewNode} from '@ckeditor/ckeditor5-engine';
import {ConverterBase} from '#includes/content/editor/plugins/utils';
import {ConverterError} from '#includes/error';
import {AnswerModelName, AnswerTextData} from '#global';
import {Keyword} from '#includes/content/editor/Keyword';

export const Converter = new (class Converter extends ConverterBase<
  AnswerModelName.textModel
> {
  public toAnswerValue(
    container: Element,
    isView: boolean,
  ): AnswerTextData['value'] | Error {
    return this.toModelData(container);
  }

  public toView(
    data: Element,
  ): Element | Error {
    return data;
  }

  public activateView(
    view: Element,
  ): Element | Error {
    if (!(view instanceof HTMLSpanElement)) return new ConverterError(
      `TextConverter.${this.activateView.name}`,
      'view не является Span',
      {view},
    );

    const input = view.firstChild;
    if (!(input instanceof HTMLInputElement)) return new ConverterError(
      `TextConverter.${this.activateView.name}`,
      'input не является Input',
      {input},
    );

    input.setAttribute('size', '3');
    input.addEventListener('input', () => {
      const length = input.value.length;
      const size = Math.max(3, Math.min(length, 20));
      input.setAttribute('size', String(size));
      input.setAttribute('title', input.value);
    });

    // TODO autocomplete не убирается!
    input.setAttribute('autocomplete', 'false');
    input.setAttribute('aria-autocomplete', 'none');

    return view;
  }

  public toData(
    editor: Element,
  ): Element | Error {
    if (!(editor instanceof HTMLSpanElement)) return new ConverterError(
      `TextConverter.${this.toData.name}`,
      'editor не является Span',
      {editor},
    );

    const input = editor.firstChild;
    if (!(input instanceof HTMLInputElement)) return new ConverterError(
      `TextConverter.${this.toData.name}`,
      'input не является Input',
      {input},
    );

    input.value = '';
    input.removeAttribute('value');
    input.removeAttribute('size');

    return editor;
  }

  public toEditor(
    data: Element,
    answer: AnswerTextData['value'],
  ): Element | Error {
    if (!(data instanceof HTMLSpanElement)) return new ConverterError(
      `TextConverter.${this.toEditor.name}`,
      'data не является Span',
      {data},
    );

    const input = data.firstChild;
    if (!(input instanceof HTMLInputElement)) return new ConverterError(
      `TextConverter.${this.toEditor.name}`,
      'input не является Input',
      {input},
    );

    input.setAttribute('value', answer);
    input.setAttribute('size', String(Math.max(answer.length, 3)));
    input.value = answer;

    return data;
  }

  public toModelData(
    editor: Node | ViewNode,
  ): AnswerTextData['value'] | Error {
    const container = this.toNodeData(editor);
    if (container.isText() || !container.nameIs('span')) return new ConverterError(
      `TextConverter.${this.toModelData.name}`,
      'container не является Span',
      {container},
    );

    const input = container.getChild(0);
    if (!input || input.isText() || !input.nameIs('input')) return new ConverterError(
      `TextConverter.${this.toModelData.name}`,
      'input не является Input',
      {input},
    );

    return input.value;
  }

  public toModel(
    writer: DowncastWriter,
    data: AnswerTextData['value'],
    _isEditor: boolean,
  ): { attributes: ViewElementAttributes, children: ViewNode[] } | Error {
    return {
      attributes: {
        class: [Keyword.answerClass, Keyword.textClass].join(' '),
      },
      children: [
        writer.createContainerElement('input', {
          type: 'text',
          autocomplete: 'off',
          value: data,
          size: Math.max(data.length, 3),
        }),
      ],
    };
  }
})();