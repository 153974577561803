import {isProduction, isSureProduction, urls} from '#config';
import {axios} from '#includes/axios';
import {FileData} from '#global';
import {notifications} from '#store';

type FileUploadResponse = FileData & {
  error?: Error,
} | undefined;

export class FileUpload {
  private readonly xhr: XMLHttpRequest;

  public constructor() {
    this.xhr = new XMLHttpRequest();

    this.xhr.open('POST', `${isSureProduction ? '' : urls.backend}/api/files`, true);

    this.xhr.responseType = 'json';
  }

  public abort() {
    this.xhr.abort();
  }

  public send(file: File) {
    const data = new FormData();
    data.append('files', file);

    this.xhr.withCredentials = Boolean(axios.authorization);
    this.xhr.setRequestHeader('Authorization', axios.authorization || '');
    this.xhr.send(data);
  }

  public initListeners(
    load: (data: FileData) => void,
    progress: (total: number, loaded: number) => void,
    error: (error?: string | Error) => void,
  ) {
    const genericErrorText = 'Не удалось загрузить файл';

    this.xhr.addEventListener('error', () => {
      notifications.addError(genericErrorText);
      error(genericErrorText);
    });
    this.xhr.addEventListener('abort', () => error());
    this.xhr.addEventListener('load', () => {
      const response = this.xhr.response as FileUploadResponse;

      if (!response || response.error) {
        notifications.addError(response?.error || genericErrorText);
        return error(response?.error || genericErrorText);
      }

      notifications.addInfo(`Файл ${response.name} сохранён`);

      load(response);
    });
    this.xhr.upload && this.xhr.upload.addEventListener('progress', evt => {
      if (!evt.lengthComputable) return;

      progress(evt.total, evt.loaded);
    });
  }
}