import {HTMLAttributes, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {breadcrumbs, BreadcrumbsItem, device, theme, view, ViewConfig} from '#store';
import {Breadcrumbs} from '#components/organisms';

type MainPropsMin = {
  view?: Partial<ViewConfig>,
  breadcrumbs?: (BreadcrumbsItem | Falsy)[],
};

export type MainProps = Omit<HTMLAttributes<HTMLElement>, keyof MainPropsMin> & MainPropsMin;

const styles = {
  base: () => css({
    width: view.config.autoWidth ? '100%' : device.current.width,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: theme.current.App.background[0],
  }),
  main: () => css({
    width: '100%',
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  }),
};

export const Main = observer<MainProps>(props => {
  const {
    children,
    view: viewConfig,
    breadcrumbs: newBreadcrumbs = [],
    ...otherProps
  } = props;

  useEffect(() => {
    view.setConfig(viewConfig);
    breadcrumbs.set(newBreadcrumbs);
  }, [JSON.stringify(viewConfig), JSON.stringify(newBreadcrumbs)]);

  return (
    <div css={styles.base()}>
      {breadcrumbs.items.length !== 0 && (
        <Breadcrumbs/>
      )}
      <main css={styles.main()} {...otherProps}>
        {children}
      </main>
    </div>
  );
});