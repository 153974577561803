import {ChangePasswordArgs, HttpMethod} from '../';
import {g_Route} from '../utils/Route';

export const g_RootRoute = {
  setWhiteList: new g_Route<null, {}, { message: string }>(
    HttpMethod.put,
    'set-white-list',
    'Включить белый список, установив его сообщение',
  ),
  changePassword: new g_Route<null, {}, ChangePasswordArgs>(
    HttpMethod.put,
    'change-password',
    'Изменить пароль',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any> } = g_RootRoute;