import {ExerciseData, ParagraphData, TagData} from '#global';
import {ExerciseTagsData, fillContent} from './fillContent';
import {ConverterPattern} from './stringsConverters';
import {clearContent} from './clearContent';
import {ExerciseInfo, extractExercisesInfo} from './extractExercisesInfo';
import {toViewContent} from './toViewContent';

export const ContentConverter = new (class ContentConverter {
  public dataToEditor(
    dataContent: ParagraphData['content'],
    exercises: Readonly<ExerciseData[]>,
    tags: Readonly<TagData[]>,
  ): undefined | string | Error {
    if (dataContent === null || !dataContent) return undefined;

    const exercisesWithTags = this
      .getExercisesWithTags(exercises, tags);

    return fillContent(dataContent, exercisesWithTags);
  }

  public dataToViewer(
    dataContent: ParagraphData['content'],
  ): string[] | Error {
    return dataContent ? toViewContent(dataContent) : [];
  }

  public editorToData(
    editorContent: ParagraphData['content'],
  ): ParagraphData['content'] | Error {
    return editorContent && clearContent(editorContent);
  }

  public editorToExercisesInfo(
    editorContent: ParagraphData['content'],
  ): ExerciseInfo[] | Error {
    return editorContent ? extractExercisesInfo(editorContent) : [];
  }

  //

  private getExercisesWithTags(
    exercises: Readonly<ExerciseData[]>,
    tags: Readonly<TagData[]>,
  ): ExerciseTagsData[] {
    return exercises.map(exercise => ({
      ...exercise,
      Tags: exercise.TagsId.map(id => (
        tags.find(tag => tag.id === id)?.name ?? ''
      )),
    }));
  }
})();

export {
  ConverterPattern,
};