import {StyleBase} from '#includes/content/editor/plugins/utils';
import {css} from '@emotion/react';
import {ContainerStyle} from '#includes/ContainerStyle';
import {getEm} from '#includes/utils';
import {theme} from '#store';
import {borders, palette} from '#config';

export const Style: StyleBase = {
  general: css`
    border-collapse: collapse;
    caption-side: bottom;

    & th, & td {
      border: solid ${borders.width.small} ${palette.gray.soft};
      max-width: 0;
    }

    & th {
      background-color: ${theme.current.basePalette.white};
      font-weight: bold;
      color: ${palette.black};
      text-align: center;
      padding: ${getEm(7)};
    }
  `,
  viewer: css`
    & caption, & td {
      & > .draggable-fixed {
        padding: ${getEm(7)};

        width: 100%;
        height: 100%;

        display: flex;
        flex-wrap: wrap;
        gap: ${getEm(7)};

        align-items: flex-start;
        justify-content: flex-start;
        
        & > .draggable-empty {
          min-width: 5em;
        }
      }
    }

    & td {
      vertical-align: top;
    }
    
    & caption {
      border: solid ${borders.width.small} ${palette.gray.soft};
      border-top: none;
    }
  `,
  editor: css`
    & caption {
      display: none;
    }

    & td {
      padding: 0 0 ${getEm(7)} ${getEm(7)};
    }

    & td > * {
      ${ContainerStyle.Clickable};
      color: ${palette.black};

      cursor: grab;
      display: inline-block;
      margin: ${getEm(7)} ${getEm(7)} 0 0;
    }
  `,
};