import {Range} from '@ckeditor/ckeditor5-engine';

export function getRangeText(range: Range) {
  const items = Array.from(range.getItems());

  return items.reduce((rangeText, node) => {
    if (!node.is('$text') && !node.is('$textProxy')) return rangeText;

    return rangeText + node.data;
  }, '');
}