import {observer} from 'mobx-react-lite';
import {Fragment, HTMLAttributes, ReactElement, useMemo} from 'react';
import {css} from '@emotion/react';
import {getEm} from '#includes/utils';
import {Property} from 'csstype';
import {borders} from '#config';
import {theme} from '#store';

type ListPropsMin = {
  horizontal?: boolean,
  separator?: boolean,
  gap?: string | 0,
};

export type ListProps =
  Omit<HTMLAttributes<HTMLDivElement>, keyof ListPropsMin>
  & ListPropsMin;

const styles = {
  base: (
    isHorizontal: boolean,
    separator: boolean,
    gap: Property.Margin,
  ) => css({
    display: 'flex',
    flexDirection: isHorizontal ? 'row' : 'column',
    alignItems: 'center',
    gap: separator ? `calc((${gap} - ${borders.width.base}) / 2)` : gap,
  }),
  separator: (isHorizontal: boolean) => css({
    display: 'inline-block',
    [isHorizontal ? 'height' : 'width']: '100%',
    [isHorizontal ? 'width' : 'height']: borders.width.base,
    backgroundColor: theme.current.basePalette.hard,
  }),
};

export const List = observer<ListProps>(props => {
  const {
    children,
    horizontal = false,
    separator = false,
    gap = getEm(14),
    ...otherProps
  } = props;

  const items = useMemo(() => {
    if (children === null || children === undefined || typeof children === 'boolean') return [];
    if (typeof children === 'string' || typeof children === 'number') return [children];
    if ((children as any)[0]) return Array.from(children as Iterable<ReactElement>) as ReactElement[];
    return [children as ReactElement];
  }, [children]);

  return (
    <div css={styles.base(horizontal, separator, gap)} {...otherProps}>
      {separator ? (
        items.map((item, index) => (
          <Fragment key={index}>
            {item}
            {index !== items.length - 1 && (
              <div css={styles.separator(horizontal)}/>
            )}
          </Fragment>
        ))
      ) : (
        children
      )}
    </div>
  );
});