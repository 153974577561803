import {ContainerView} from '#includes/content/editor/Views/Container';
import {BaseView} from '#includes/content/editor/Views/Base';
import {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {render} from '#includes/content/editor/Views/Formula/render';

export class FormulaView extends BaseView<ContainerView<[]>['ckView']> {
  private container: ContainerView<[]>;

  private constructor(
    locale: Locale,
  ) {
    const container = ContainerView.create<[]>(
      locale,
      Keyword.formulaPreviewClass,
    );

    super(locale, container.ckView);

    this.container = container;
  }

  public static create(locale: Locale): FormulaView {
    return new FormulaView(locale);
  }

  public setValue(value: string): void {
    if (!this.container.ckView.element) return;

    render(this.container.ckView.element, value);
  }
}