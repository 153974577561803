import {HttpMethod, ListData, TagArgs, TagData, TagEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_TagsRoute = {
  createTag: new g_Route<null, TagData, TagArgs>(
    HttpMethod.post,
    'tags',
    'Добавить метку',
  ),
  createTags: new g_Route<null, ListData<TagData>, TagArgs[]>(
    HttpMethod.post,
    'tags/many',
    'Добавить метки',
  ),
  getTags: new g_Route<null, ListData<TagData>>(
    HttpMethod.get,
    'tags',
    'Получить все метки',
  ),
  getTagsMany: new g_Route<null, ListData<TagData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'tags/many',
    'Получить необходимые метки',
  ),
  getTag: new g_Route<{ id: number }, TagData>(
    HttpMethod.get,
    'tags/:id',
    'Получить метку',
  ),
  editTag: new g_Route<{ id: number }, TagData, Partial<TagArgs>>(
    HttpMethod.put,
    'tags/:id',
    'Изменить метку',
  ),
  editTags: new g_Route<null, ListData<TagData>, TagEditionArgs[]>(
    HttpMethod.put,
    'tags/many',
    'Изменить метки',
  ),
  deleteTag: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'tags/:id',
    'Удалить метку',
  ),
  deleteTags: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'tags/many',
    'Удалить метки',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_TagsRoute;