import {HttpMethod, ListData, UserTopicArgs, UserTopicData, UserTopicEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_UserTopicsRoute = {
  createUserTopic: new g_Route<null, UserTopicData, UserTopicArgs>(
    HttpMethod.post,
    'user-topics',
    'Добавить тему для изучения',
  ),
  createUserTopics: new g_Route<null, ListData<UserTopicData>, UserTopicArgs[]>(
    HttpMethod.post,
    'user-topics/many',
    'Добавить темы для изучения',
  ),
  getUserTopics: new g_Route<null, ListData<UserTopicData>>(
    HttpMethod.get,
    'user-topics',
    'Получить все темы для изучения',
  ),
  getUserTopicsMany: new g_Route<null, ListData<UserTopicData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'user-topics/many',
    'Получить темы для изучения',
  ),
  getUserTopic: new g_Route<{ id: number }, UserTopicData>(
    HttpMethod.get,
    'user-topics/:id',
    'Получить тему для изучения',
  ),
  editUserTopic: new g_Route<{ id: number }, UserTopicData, Partial<UserTopicArgs>>(
    HttpMethod.put,
    'user-topics/:id',
    'Изменить тему для изучения',
  ),
  editUserTopics: new g_Route<null, ListData<UserTopicData>, UserTopicEditionArgs[]>(
    HttpMethod.put,
    'user-topics/many',
    'Изменить темы для изучения',
  ),
  deleteUserTopic: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'user-topics/:id',
    'Удалить тему для изучения',
  ),
  deleteUserTopics: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'user-topics/many',
    'Удалить темы для изучения',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_UserTopicsRoute;
