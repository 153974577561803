import {HttpMethod, LevelArgs, LevelData, LevelEditionArgs, ListData} from '../../';
import {g_Route} from '../../utils';

export const g_LevelsRoute = {
  createLevel: new g_Route<null, LevelData, LevelArgs>(
    HttpMethod.post,
    'levels',
    'Добавить уровень обучения',
  ),
  createLevels: new g_Route<null, ListData<LevelData>, LevelArgs[]>(
    HttpMethod.post,
    'levels/many',
    'Добавить уровни обучения',
  ),
  getLevels: new g_Route<null, ListData<LevelData>>(
    HttpMethod.get,
    'levels',
    'Получить все уровни обучения',
  ),
  getLevelsMany: new g_Route<null, ListData<LevelData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'levels/many',
    'Получить необходимые уровни обучения',
  ),
  getLevel: new g_Route<{ id: number }, LevelData>(
    HttpMethod.get,
    'levels/:id',
    'Получить уровень обучения',
  ),
  editLevel: new g_Route<{ id: number }, LevelData, Partial<LevelArgs>>(
    HttpMethod.put,
    'levels/:id',
    'Изменить уровень обучения',
  ),
  editLevels: new g_Route<null, ListData<LevelData>, LevelEditionArgs[]>(
    HttpMethod.put,
    'levels/many',
    'Изменить уровни обучения',
  ),
  deleteLevel: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'levels/:id',
    'Удалить уровень обучения',
  ),
  deleteLevels: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'levels/many',
    'Удалить уровни обучения',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_LevelsRoute;