import {css} from '@emotion/react';
import {getEm} from '#includes/utils';
import {theme, view} from '#store';

export const styles = {
  global: css`
    *, *::before, *::after {
      outline: ${false ? 'solid 0.5px #0999' : 'none'};
      box-sizing: border-box;
    }

    html, body, main,
    div, input, textarea, button,
    table, tbody, thead, tr, td, th,
    h1, h2, h3, h4, a, p, hr, svg, img {
      padding: 0;
      margin: 0;
      border: none;
      text-decoration: none;
      list-style: none;
      background: none;
      font-size: ${getEm(16)};
      line-height: 1.5;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      word-spacing: inherit;
      letter-spacing: inherit;
      text-rendering: inherit;
      transition: inherit;
      word-break: break-word;
    }

    html, body, #root {
      display: grid;
      width: 100%;
      min-height: 100%;
      scroll-behavior: smooth;
      font-family: sans-serif;
      font-weight: 400;
      word-spacing: ${getEm(0.5)};
      letter-spacing: ${getEm(0.5)};
      text-rendering: geometricPrecision;
      transition: color 200ms ease-out, background-color 200ms ease-out;
    }

    textarea {
      display: block;
    }

    ol {
      margin: 0 0 0 1.25em;
    }

    ul {
      margin: 0 0 0 1em;
    }

    ol, ul, li {
      padding: 0;
    }

    h1, h2, h3 {
      font-weight: bold;
    }

    h1 {
      font-size: ${getEm(32)};
    }

    h2 {
      font-size: ${getEm(24)};
    }

    h3 {
      font-size: ${getEm(20)};
    }

    .MathJax,
    .MathJax * {
      pointer-events: none;
    }
  `,
  dynamic: () => css`
    :root {
      --view-top-offset: ${view.topOffset}px;
    }

    *, *::before, *::after {
      accent-color: ${theme.current.basePalette.dark};
      cursor: ${view.grabbing ? 'grabbing !important' : ''};
    }

    html, body, #root {
      color: ${theme.current.App.color[0]};
    }
  `
};