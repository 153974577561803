import {action, computed, makeObservable, observable} from 'mobx';
import {css, SerializedStyles} from '@emotion/react';

class GlobalStyle {
  private map: Map<string, SerializedStyles> = new Map();

  public constructor() {
    makeObservable<typeof this, 'map'>(this, {
      map: observable.shallow,
      current: computed,
      addStyle: action.bound,
      removeStyle: action.bound,
    });
  }

  public get current(): string {
    const styles = css(...this.map.values()).styles;
    console.log({styles});
    return styles;
  }

  public addStyle(name: string, style: SerializedStyles): void {
    console.log('+', name);
    this.map.set(name, style);
  }

  public removeStyle(name: string): void {
    console.log('-', name);
    this.map.delete(name);
  }
}

export const globalStyle = new GlobalStyle();

(globalThis as any).globalStyle = globalStyle;