import {observer} from 'mobx-react-lite';
import {FlexSeparator, List} from '#components/atoms';
import {css} from '@emotion/react';
import {device, theme, user, view} from '#store';
import {HeaderItem} from './HeaderItem';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {RoleId} from '#global';
import {useEventListener} from 'usehooks-ts';
import {header} from '#config';

const styles = {
  offset: () => css({
    display: 'inline-block',
  }),
  base: () => css({
    fontSize: header.fontSize,
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.current.Header.background[0],
    width: '100%',
    whiteSpace: 'nowrap',
    zIndex: 9999,
  }),
  list: () => css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: view.config.autoWidth ? '100%' : device.current.width,
    flexWrap: 'wrap',
  }),
};

export const Header = observer(() => {
  const ref = useRef<HTMLElement>(null);

  const currentHeaderItems = useMemo(() => {
    if (!user.authorized) return header.items.empty;

    const dominatedRoleId = user.current.RolesId.length === 0
      ? RoleId.student
      : Math.min(...user.current.RolesId) as RoleId;

    return header.items[dominatedRoleId];
  }, [user.current]);

  const headerList = useMemo(() => (
    <>
      {currentHeaderItems.left.map((itemPropsMin, index) => (
        <HeaderItem key={index} {...itemPropsMin}/>
      ))}
      <FlexSeparator/>
      {currentHeaderItems.right.map((itemPropsMin, index) => (
        <HeaderItem key={index} {...itemPropsMin}/>
      ))}
    </>
  ), [currentHeaderItems]);

  const updateHeight = useCallback(() => {
    if (!ref.current) return;
    view.setTopOffset(ref.current.getBoundingClientRect().height);
  }, [ref.current]);
  useEffect(updateHeight, [ref.current]);
  useEventListener('resize', updateHeight);

  return (
    <>
      <div css={styles.offset()} style={{paddingBottom: 'var(--view-top-offset)'}}/>
      <header css={styles.base()} ref={ref}>
        <List css={styles.list()} horizontal={true}>
          {headerList}
        </List>
      </header>
    </>
  );
});