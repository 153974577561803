import {FileData, HttpMethod, ListData} from '../../';
import {g_Route} from '../../utils';

export const g_FilesRoute = {
  saveFile: new g_Route<null, FileData>( // Ожидает файл в поле files FormData
    HttpMethod.post,
    'files',
    'Сохранить файл на сервере',
  ),
  saveFiles: new g_Route<null, ListData<FileData>>( // Ожидает массив файлов в поле files FormData
    HttpMethod.post,
    'files/many',
    'Сохранить файлы на сервере',
  ),
  getFiles: new g_Route<null, ListData<FileData>>(
    HttpMethod.get,
    'files',
    'Получить все файлы',
  ),
  getFilesMany: new g_Route<null, ListData<FileData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'files/many',
    'Получить необходимые файлы',
  ),
  getFile: new g_Route<{ id: number }, FileData>(
    HttpMethod.get,
    'files/:id',
    'Получить файл',
  ),
  getFileContent: new g_Route<{ id: number }>(
    HttpMethod.get,
    'files/:id/content',
    'Получить содержимое файла',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_FilesRoute;
