// noinspection ES6PreferShortImport

import {A1, A2, A3, CheckboxTheme, ClickableTheme, ContainerTheme} from './types';
import {ColorName, isColorName, palette} from '../styles/palette';

// region ColorForMe

(globalThis as any).setColorForMe = (color: string) => {
  if (!isColorName(color)) throw `Нет цвета ${color}`;
  window.localStorage.setItem('color-for-me', color);
  window.location.reload();
};

(globalThis as any).restoreColorForMe = () => {
  window.localStorage.removeItem('color-for-me');
  window.location.reload();
};

const rawColorForMe = window.localStorage.getItem('color-for-me');
const colorForMe = rawColorForMe && isColorName(rawColorForMe) ? rawColorForMe : 'green';

// endregion

export const lightTheme = (() => {
  const baseColor: ColorName = colorForMe;
  const basePalette = palette[baseColor];

  const containerThemes: { [key in ColorName]?: ContainerTheme } = {};
  const containerThemeGenerator = (colorName: ColorName): ContainerTheme => ({
    filled: {
      stroke: 'transparent',
      color: palette.white,
      background: palette[colorName].hard,
    },
    outlined: {
      stroke: palette[colorName].hard,
      color: palette[colorName].hard,
      background: 'transparent',
    },
    custom: {
      stroke: undefined,
      color: undefined,
      background: undefined,
    },
  });

  const clickableThemes: { [key in ColorName]?: ClickableTheme } = {};
  const clickableThemeGenerator = (colorName: ColorName): ClickableTheme => {
    const containerTheme = Container(colorName);

    return {
      filled: {
        stroke: [containerTheme.filled.stroke, 'transparent', 'transparent'] as A3,
        color: [containerTheme.filled.color, palette.white, palette.white] as A3,
        background: [containerTheme.filled.background, palette[colorName].dark, palette.gray.soft] as A3,
      },
      outlined: {
        stroke: [containerTheme.outlined.stroke, palette[colorName].black, palette.gray.soft] as A3,
        color: [containerTheme.outlined.color, palette[colorName].black, palette.gray.soft] as A3,
        background: [containerTheme.outlined.background, 'transparent', 'transparent'] as A3,
      },
      custom: {
        stroke: [containerTheme.custom.stroke, undefined, undefined] as A3,
        color: [containerTheme.custom.color, undefined, undefined] as A3,
        background: [containerTheme.custom.background, undefined, undefined] as A3,
      },
    };
  };

  //

  const App = {
    stroke: ['transparent'] as A1,
    color: ['inherit'] as A1,
    background: [palette.white, basePalette.dark] as A2,
  };

  const Preloader = {
    stroke: [
      basePalette.hard,
      basePalette.base,
    ] as A2,
    color: ['inherit'] as A1,
    background: [palette.gray.black] as A1,
  };
  const Container = (colorName: ColorName): ContainerTheme => {
    const theme = containerThemes[colorName];
    if (theme) return theme;
    containerThemes[colorName] = containerThemeGenerator(colorName);
    return Container(colorName);
  };
  const Clickable = (colorName: ColorName): ClickableTheme => {
    const theme = clickableThemes[colorName];
    if (theme) return theme;
    clickableThemes[colorName] = clickableThemeGenerator(colorName);
    return Clickable(colorName);
  };

  const Tabs = {
    head: {
      stroke: ['transparent'] as A1,
      color: [palette.white, palette.gray.light] as A2,
      background: [palette.gray.black] as A1,
    },
    body: {
      stroke: ['transparent'] as A1,
      color: [palette.white, palette.gray.light] as A2,
      background: [palette.gray.black] as A1,
    },
  };

  const Header = {
    stroke: ['transparent'] as A1,
    color: [palette.white, palette.gray.light] as A2,
    background: [palette.gray.black] as A1,
  };

  const Footer = {
    stroke: ['transparent'] as A1,
    color: [palette.white] as A1,
    background: [palette.gray.black, palette.gray.dark] as A2,
  };

  const InputText = {
    stroke: [palette.gray.base] as A1,
    color: [palette.black] as A1,
    background: [palette.white] as A1,
  };

  const InputCheck: CheckboxTheme = {
    filled: {
      stroke: [basePalette.hard, basePalette.dark, palette.gray.base],
      color: [palette.white, palette.white, palette.white],
      background: [basePalette.hard, basePalette.dark, palette.gray.base],
      box: {
        stroke: [basePalette.hard, basePalette.dark, 'transparent'],
        color: ['transparent', palette.white, palette.white],
        background: [palette.white, 'transparent', 'transparent'],
      },
    },
    outlined: {
      stroke: [basePalette.hard, basePalette.dark, palette.gray.base],
      color: [basePalette.dark, basePalette.black, palette.gray.base],
      background: [undefined, undefined, undefined],
      box: {
        stroke: [palette.gray.base, palette.gray.base, palette.gray.base],
        color: ['transparent', basePalette.dark, palette.gray.base],
        background: [undefined, undefined, undefined],
      },
    },
    custom: {
      stroke: ['transparent', 'transparent', 'transparent'],
      color: [undefined, undefined, undefined],
      background: [undefined, undefined, undefined],
      box: {
        stroke: [palette.gray.base, palette.gray.base, palette.gray.soft],
        color: ['transparent', palette.black, palette.white],
        background: [undefined, undefined, palette.gray.soft],
      },
    },
  };

  const links = {
    color: palette.blue.hard,
  };

  return {
    basePalette,
    baseColor,
    App,
    Preloader,
    Container,
    Clickable,
    Tabs,
    Header,
    Footer,
    InputText,
    InputCheck,
    links,
  };
})();