import {HttpMethod, ListData, TopicArgs, TopicData, TopicEditionArgs} from '../../';
import {g_Route} from '../../utils';

export const g_TopicsRoute = {
  createTopic: new g_Route<null, TopicData, TopicArgs>(
    HttpMethod.post,
    'topics',
    'Добавить тему',
  ),
  createTopics: new g_Route<null, ListData<TopicData>, TopicArgs[]>(
    HttpMethod.post,
    'topics/many',
    'Добавить темы',
  ),
  getTopics: new g_Route<null, ListData<TopicData>>(
    HttpMethod.get,
    'topics',
    'Получить все темы',
  ),
  getTopicsMany: new g_Route<null, ListData<TopicData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'topics/many',
    'Получить темы',
  ),
  getTopic: new g_Route<{ id: number }, TopicData>(
    HttpMethod.get,
    'topics/:id',
    'Получить тему',
  ),
  editTopic: new g_Route<{ id: number }, TopicData, Partial<TopicArgs>>(
    HttpMethod.put,
    'topics/:id',
    'Изменить тему',
  ),
  editTopics: new g_Route<null, ListData<TopicData>, TopicEditionArgs[]>(
    HttpMethod.put,
    'topics/many',
    'Изменить темы',
  ),
  deleteTopic: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'topics/:id',
    'Удалить тему',
  ),
  deleteTopics: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'topics/many',
    'Удалить темы',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_TopicsRoute;
