import {observer} from 'mobx-react-lite';
import {css, keyframes} from '@emotion/react';
import {Link, List} from '#components/atoms';
import {getEm} from '#includes/utils';
import {theme} from '#store';
import {HTMLAttributes, useMemo} from 'react';
import {HeaderItemClass} from '#config';

type HeaderItemPropsMin = HeaderItemClass & {
  depth?: number,
};

type HeaderItemProps = Readonly<
  Omit<HTMLAttributes<HTMLElement>, keyof HeaderItemPropsMin | 'children' | 'color'>
  & HeaderItemPropsMin
>;

const hidingAnimation = keyframes({
  'from': {
    opacity: 1,
    pointerEvents: 'all',
  },
  '90%': {
    opacity: 0.1,
    pointerEvents: 'all',
  },
  'to': {
    opacity: 0,
    pointerEvents: 'none',
  },
});

const styles = {
  base: (isLink: boolean, nesting: number) => css({
    position: 'relative',
    padding: getEm(6),
    color: theme.current.Header.color[0],
    '&:hover': {
      color: theme.current.Header.color[1],
      '& > *': {
        opacity: 1,
        pointerEvents: 'all',
        animationName: 'none',
        zIndex: 1,
      },
    },
    width: nesting === 0 ? undefined : '100%',
    cursor: isLink ? undefined : 'default',
  }),
  subItems: (nesting: number) => css({
    position: 'absolute',
    top: nesting === 0 ? '100%' : 0,
    left: nesting === 0 ? 0 : '100%',
    backgroundColor: theme.current.Header.background[0],
    transition: 'none',
    animationName: hidingAnimation,
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',
    alignItems: 'flex-start',
    '& > *': {
      width: '100%',
    },
  }),
  arrow: () => css({
    marginLeft: getEm(4),
  }),
};

export const HeaderItem = observer<HeaderItemProps>(props => {
  const {
    content: Content,
    url,
    subItems,
    depth = 0,
    ...otherProps
  } = props;

  const children = useMemo(() => (
    <>
      {typeof Content === 'string' ? Content : (
        <Content/>
      )}
      {subItems.length > 0 && (
        <List css={styles.subItems(depth)} gap={0}>
          {subItems.map((item, index) => (
            <HeaderItem key={index} depth={depth + 1} {...item}/>
          ))}
        </List>
      )}
    </>
  ), [Content, subItems, depth]);

  return url ? (
    <Link css={styles.base(true, depth)} to={url} {...otherProps}>
      {children}
    </Link>
  ) : (
    <div css={styles.base(false, depth)} {...otherProps}>
      {children}
    </div>
  );
});