import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {AnswerModelName} from '#global';
import {EditingBase, NodeName} from '#includes/content/editor/plugins/utils';
import {
  SchemaCompiledItemDefinition,
  SchemaContext,
} from '@ckeditor/ckeditor5-engine/src/model/schema';
import {ModelData} from './Form';

export class Editing extends EditingBase<ModelData> {
  protected readonly modelName = AnswerModelName.textModel;
  protected readonly modelElement: NodeName = 'span';
  protected readonly isInline = true;
  protected readonly modelClass = Keyword.textClass;
  protected readonly commandsMap = {[Keyword.textCommand]: Command};
  protected readonly uiName = 'Ck_Exercise_Text_Ui';

  protected childCheck(
    context: SchemaContext,
    definition: SchemaCompiledItemDefinition,
  ): false | void {
    return this.childCheckInExercise(context, definition);
  }

  protected getIsEmpty(
    data: ModelData,
  ): boolean {
    return !data;
  }
}