import {observer} from 'mobx-react-lite';
import {Page, Preloader} from '#components/organisms';
import {device, loader} from '#store';
import {useEffectOnce, useEventListener} from 'usehooks-ts';
import {styles} from './styles';
import {Global} from '@emotion/react';
import {MathJaxContext} from 'better-react-mathjax';

export const App = observer(() => {
  let loadTimeout = NaN, loadTimeout1sec = NaN, unloadTimeout = NaN;

  const update_device = () => device.updateByWidth(document.body.clientWidth);

  useEventListener('beforeunload', () => {
    loader.add('beforeunload');

    window.clearTimeout(unloadTimeout);
    unloadTimeout = window.setTimeout(() => loader.remove('beforeunload'), 1000);
  });

  useEventListener('resize', update_device);

  useEffectOnce(() => {
    window.clearTimeout(loadTimeout);
    loadTimeout = window.setTimeout(update_device);

    window.clearTimeout(loadTimeout1sec);
    loadTimeout1sec = window.setTimeout(update_device, 1000);

    loader.remove('load');

    return () => {
      window.clearTimeout(loadTimeout);
      window.clearTimeout(loadTimeout1sec);
      window.clearTimeout(unloadTimeout);
    };
  });

  const mathJaxConfig = {
    loader: { load: ["input/asciimath"] }
  };

  return (
    <MathJaxContext config={mathJaxConfig}>
      <Global styles={styles.global}/>
      <Global styles={styles.dynamic()}/>
      <Preloader/>
      <Page/>
    </MathJaxContext>
  );
});
