import {css} from '@emotion/react';
import {Keyword} from '#includes/content/editor/Keyword';
import {getEm} from '#includes/utils';

export const views = css`
  // Текстовое поле шириной 15 символов
  .${Keyword.inputClass} {
    width: 15em;

    // Текстовое поле по ширине своего label
    .ck-input {
      min-width: 0;
      width: 100%;
    }
  }

  // Все кликабельные элементы с правильным курсором
  .${Keyword.buttonClass},
  .${Keyword.fileClass},
  .${Keyword.switchClass},
  .${Keyword.metaClass} {
    &, & * {
      cursor: pointer;
    }
  }

  // Переключатель по центру
  .${Keyword.switchClass} > .ck-button__toggle {
    margin-right: auto;
  }

  // Кнопка вставки перед элементом видна только в начале списка
  .${Keyword.listClass} > .${Keyword.itemClass}:not(:first-of-type) > .${Keyword.beforeClass} {
    display: none;
  }

  // Поле с файлом
  .${Keyword.fileClass} {
    display: grid;
    grid-template-columns: auto 1fr;
    width: 15em;
    border: solid 1px var(--ck-color-input-border);
    padding: var(--ck-spacing-extra-tiny) var(--ck-spacing-medium) !important;

    & > .ck-button__label {
      display: none !important;
    }

    & > input {
      display: none;
    }

    & > span {
      transform: translateY(${getEm(1.3)});
      margin-left: ${getEm(4)} !important;
    }

    & > div {
      grid-column: 1 / 3;

      aspect-ratio: 4 / 3;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:not(.${Keyword.previewModeClass}) > div {
      display: none;
    }
  }

  // Поле с файлом или текстовое поле кастомизированно
  .${Keyword.textOrFileClass} {
    display: flex;
    width: 100%;
    border: solid 1px var(--ck-color-input-border);

    &:not(.${Keyword.fileModeClass}) > .${Keyword.fileClass},
    &.${Keyword.fileModeClass} > .${Keyword.inputClass} {
      display: none;
    }

    & > .${Keyword.fileClass},
    & > .${Keyword.inputClass} input,
    & > .${Keyword.inputClass} label {
      border: none;
      z-index: 1;
    }

    & > .${Keyword.buttonClass} {
      background-color: white;
      order: 1;
    }
  }

  // Форма
  .${Keyword.formClass} {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${getEm(10)};

    & > .${Keyword.containerClass}:first-of-type {
      position: relative;
      overflow: auto;
      max-width: calc(100vw - ${getEm(40)});
      max-height: calc(100vh - var(--view-top-offset) - ${getEm(40)} - 60px);
      padding: ${getEm(10)};
      padding-bottom: ${getEm(7)};
    }
  }

  // Контейнер подтверждения
  .${Keyword.confirmClass} {
    display: flex;
    width: 100%;
    padding-inline: ${getEm(10)};

    & > * {
      flex-grow: 1;
    }
  }

  // Кнопка внутри кнопки с текстом всегда отодвинута вправо от текста
  .ck-button_with-text {
    & > * {
      margin: 0 !important;
    }

    & > .ck-button__label {
      margin-right: auto !important;
    }
  }
`;