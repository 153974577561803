import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {App} from '#components/App';
import React, {StrictMode} from 'react';

const container = document.getElementById('root');
if (!container) throw new Error('Container is not defined');
const root = createRoot(container);

root.render((
  <StrictMode>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </StrictMode>
));
