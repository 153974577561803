import {Plugin} from '@ckeditor/ckeditor5-core';
import {clickOutsideHandler, ContextualBalloon} from '@ckeditor/ckeditor5-ui';
import {PositionOptions} from '@ckeditor/ckeditor5-utils';
import {Form} from './MetaForm';
import {Keyword} from '#includes/content/editor/Keyword';
import {SuperError} from '#includes/error';

export class Ui extends Plugin {
  public static current: Ui;

  public static getData(element: HTMLElement): [string, string[]] | null {
    if (!(element instanceof HTMLDivElement)) return null;

    const helpElement = element.firstChild;
    if (!(helpElement instanceof HTMLDivElement)) return null;

    const help = helpElement.innerText.trim();

    const tagsElement = element.lastChild;
    if (!(tagsElement instanceof HTMLDivElement)) return null;

    const tags: string[] = [];
    for (const span of tagsElement.childNodes) {
      if (!(span instanceof HTMLSpanElement)) continue;

      const value = span.innerText.trim();
      value && tags.push(value);
    }

    return [help, tags];
  }

  public static showUI(element: HTMLElement) {
    const data = Ui.getData(element);
    if (!data) return;

    Ui.current.showUI(data);
  }

  //

  private balloon!: ContextualBalloon;
  private form!: Form;

  public init() {
    Ui.current = this;

    const editor = this.editor;

    this.balloon = editor.plugins.get(ContextualBalloon);
    this.form = this.createFormView();
  }

  private createFormView() {
    const editor = this.editor;

    const form = new Form(
      editor.locale,
      () => this.hideUI(),
    );
    form.setSubmitHandler(() => {
      editor.execute(
        Keyword.metaCommand,
        form.getData(),
      );

      this.hideUI();
    });
    form.setCancelHandler(() => {
      this.hideUI();
    });

    clickOutsideHandler({
      emitter: form.ckView,
      activator: () => this.balloon.visibleView === form.ckView,
      contextElements: [this.balloon.view.element!],
      callback: () => this.hideUI(),
    });

    return form;
  }

  private showUI(data: [string, string[]]): void {
    this.form.setData(data);

    this.balloon.add({
      view: this.form.ckView,
      position: this.getBalloonPosition(),
    });

    this.form.focus();
  }

  private hideUI() {
    this.balloon.remove(this.form.ckView);

    this.editor.editing.view.focus();
  }

  private getBalloonPosition(): Partial<PositionOptions> {
    const view = this.editor.editing.view;
    const viewDocument = view.document;

    const range = viewDocument.selection.getFirstRange();
    if (!range) throw new SuperError(
      `${this.constructor.name}.${this.getBalloonPosition.name}`,
      'range is empty',
    );

    return {
      target: () => view.domConverter.viewRangeToDom(range),
    };
  }
}

(globalThis as any).Ck_Meta_Ui = Ui;