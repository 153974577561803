import {StyleBase} from '#includes/content/editor/plugins/utils';
import {css} from '@emotion/react';

export const Style: StyleBase = {
  general: css`
    & input {
      line-height: 1em;
      //min-width: 3em;
      //width: 0;
      border-bottom: solid 2px #000;
      text-align: center;
    }
  `,
  viewer: css`

  `,
  editor: css`

  `,
};