import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {Button, ButtonProps} from '#components/atoms';
import {getEm} from '#includes/utils';
import {borders, palette} from '#config';
import {theme} from '#store';

const styles = {
  base: (
    disabled: boolean,
  ) => css({
    position: 'relative',
    display: 'block',
    padding: `${getEm(3)} ${getEm(8)}`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    borderStyle: 'solid',
    borderWidth: `${borders.width.small} 0 0 0`,
    borderColor: palette.black,
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      borderStyle: 'solid',
      borderWidth: `0 ${borders.width.small}`,
      borderColor: palette.black,
    },
  }, disabled ? {
    color: palette.black,
    backgroundColor: 'transparent',
    borderBottomColor: 'transparent',
  } : {
    borderTopColor: 'transparent',
    padding: `${getEm(1)} ${getEm(8)}`,
    '&::after': {
      borderWidth: 0,
      borderBottomWidth: borders.width.small,
    },
    color: palette.white,
    backgroundColor: theme.current.basePalette.hard,
    '&:hover': {
      borderColor: theme.current.basePalette.dark,
      borderBottomColor: theme.current.basePalette.hard,
      color: palette.white,
      backgroundColor: theme.current.basePalette.dark,
    },
  }),
};

export const TabButton = observer<ButtonProps>(props => {
  const {
    children,
    disabled = false,
    ...otherProps
  } = props;

  return (
    <Button
      css={styles.base(disabled)}
      printing={'custom'}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </Button>
  );
});