import type {Locale} from '@ckeditor/ckeditor5-utils';
import {SwitchData, SwitchListForm} from '#includes/content/editor/Forms';
import {InputView, ItemView, SwitchView} from '#includes/content/editor/Views';
import {Keyword} from '#includes/content/editor/Keyword';

export type ModelData = SwitchData;

export class Form extends SwitchListForm {
  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.dropListFormClass, cancelCallback);
  }

  protected switchHandler(
    changedItem: ItemView<[InputView, SwitchView]>,
    enabled: boolean,
  ): void {
    for (const item of this.listChildren) {
      const [, switchView] = item.getChildren();
      switchView.setEnabled(false);
    }

    const [, switchView] = changedItem.getChildren();
    switchView.setEnabled(enabled);
  }
}