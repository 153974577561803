import {HttpMethod, ListData, SureObject, TableData} from '../';
import {g_Route} from '../utils/Route';

export const g_TablesRoute = {
  getTables: new g_Route<null, ListData<TableData>>(
    HttpMethod.get,
    'tables',
    'Получить все таблицы',
  ),
  getTableRows: new g_Route<{ tableName: string }, ListData<SureObject<any>>>(
    HttpMethod.get,
    'tables/:tableName',
    'Получить все строки таблицы',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any> } = g_TablesRoute;