import {HTMLAttributes} from 'react';
import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';

export type FlexSeparatorProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const styles = {
  base: () => css({
    flexGrow: 1,
  }),
};

export const FlexSeparator = observer(({
  ...props
}) => {
  const {...otherProps} = props;

  return (
    <div css={styles.base()} {...otherProps}/>
  );
});