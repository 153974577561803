import {observer} from 'mobx-react-lite';
import {Code} from '#components/atoms';
import {user} from '#store';
import {Main} from '#components/molecules';

export const Home = observer(() => {
  return (
    <Main>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab assumenda cum magnam odit
        pariatur quam quia quidem quo, reiciendis soluta. Architecto doloremque earum nemo quam
        vero? Delectus doloremque fuga officia!
      </p>
      <Code object={user.current}/>
    </Main>
  );
});