import {StyleBase} from '#includes/content/editor/plugins/utils';
import {css} from '@emotion/react';
import {getEm} from '#includes/utils';

export const Style: StyleBase = {
  general: css`
    display: flex;
    flex-direction: column;
    gap: ${getEm(3)};

    & label {
      display: block;
    }

    & input {
      margin-right: ${getEm(7)};
    }
  `,
  viewer: css`

  `,
  editor: css`

  `,
};