import {Collection, Locale} from '@ckeditor/ckeditor5-utils';
import {ItemView, ListView, TextOrFileDatum, TextOrFileView} from '#includes/content/editor/Views';
import {BaseForm} from '#includes/content/editor/Forms';
import {Keyword} from '#includes/content/editor/Keyword';

type Children = [TextOrFileView, TextOrFileView];
type ChildData = [TextOrFileDatum, TextOrFileDatum];
export type ModelData = ChildData[];

class ConnectionsView extends ListView<Children, ChildData> {
  protected readonly columnCollection: Collection<ItemView<Children>>;

  private constructor(locale: Locale) {
    super(locale);
    this.columnCollection = this.getChildrenCollection();
  }

  public static create(locale: Locale) {
    return new ConnectionsView(locale);
  }

  public getData(): ModelData {
    const data: ModelData = [];

    for (const item of this.columnCollection) {
      const [left, right] = item.getChildren();

      const leftDatum = left.getDatum();
      if (!leftDatum.value) continue;

      const rightDatum = right.getDatum();
      if (!rightDatum.value) continue;

      data.push([leftDatum, rightDatum]);
    }

    return data;
  }

  //

  private newEmptyData(): ChildData {
    return [
      new TextOrFileDatum(),
      new TextOrFileDatum(),
    ];
  }

  protected itemChildrenCreation(
    item: ItemView<Children>,
    data?: ChildData,
  ): Children {
    if (!data) data = this.newEmptyData();

    return data.map(datum => {
      const textOrFileView = TextOrFileView.create(
        this.locale,
        [],
        Keyword.formInputLabel,
      );
      textOrFileView.setDatum(datum);

      return textOrFileView;
    }) as Children;
  }

  protected checkEmpty(): void {
    const length = this.getChildrenLength();
    if (length === 0) return void this.setItems([this.newEmptyData(), this.newEmptyData()]);
    if (length === 1) return void this.addChild(this.createItem(this.newEmptyData()));
  }
}

export class Form extends BaseForm<[ConnectionsView], ModelData> {
  private readonly connections: ConnectionsView;
  public readonly getData;

  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.connectionsFormClass, cancelCallback);

    this.connections = ConnectionsView.create(this.locale);
    this.connections.setUpdateHandler(() => {
      this.form.updateFocusTracker();
    });
    this.getData = this.connections.getData.bind(this.connections);

    this.form.setChildren([
      this.connections,
    ]);
  }

  public override setData(data: ModelData): void {
    const convertedData: ModelData = [];

    for (let i = 0; i < data.length; i++) {
      const [left, right] = data[i];
      convertedData.push([left, right]);
    }

    this.connections.setItems(convertedData);

    super.setData(data);
  }
}