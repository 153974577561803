import {observer} from 'mobx-react-lite';
import {Button} from '#components/atoms';
import {useNavigate} from 'react-router-dom';
import {css} from '@emotion/react';
import {Main} from '#components/molecules';
import {palette} from '#config';

const styles = {
  back: () => css({
    display: 'inline',
    color: palette.indigo.base,
  }),
};

export const NotFound = observer(() => {
  const navigate = useNavigate();

  return (
    <Main view={{header: false, footer: false, autoWidth: true}}>
      <span>
        Ошибка 404. Страница не найдена. <></>
        <Button
          printing={'custom'}
          onClick={() => navigate(-1)}
          css={styles.back()}
        >
          Назад
        </Button>
      </span>
    </Main>
  );
});