import {FormEventHandler, FormHTMLAttributes, useCallback} from 'react';
import {observer} from 'mobx-react-lite';

export type FormProps = FormHTMLAttributes<HTMLFormElement>;

export const Form = observer<FormProps>(props => {
  const {children, onSubmit, ...otherProps} = props;

  const submitHandler: FormEventHandler<HTMLFormElement> = useCallback(event => {
    if (!onSubmit) return;

    onSubmit(event);
    event.preventDefault();
    return false;
  }, [onSubmit]);

  return (
    <form onSubmit={submitHandler} {...otherProps}>
      {children}
    </form>
  );
});