import {lazy} from 'react';

export const Admin = {
  Files: lazy(() => import('./preIndex')
    .then(module => ({default: module.Files}))),
  Roles: lazy(() => import('./preIndex')
    .then(module => ({default: module.Roles}))),
};

// noinspection JSUnusedLocalSymbols
const security: LazyComponentsGroup = Admin;