import {createRoot, Root} from 'react-dom/client';
import {MathJax, MathJaxContext} from 'better-react-mathjax';

const config = {loader: {load: ['input/asciimath']}};

const roots: Map<HTMLElement, Root> = new Map<HTMLElement, Root>();
const getRoot = (container: HTMLElement): Root => {
  const root = roots.get(container);
  if (root) return root;

  const newRoot = createRoot(container);
  roots.set(container, newRoot);

  return newRoot;
};

export const render = (container: HTMLElement, value: string) => {
  const root = getRoot(container);

  root.render((
    <MathJaxContext config={config}>
      <MathJax dynamic={true} children={`\`${value}\``}/>
    </MathJaxContext>
  ));
};