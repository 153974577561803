import {View} from '@ckeditor/ckeditor5-ui';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {PriorityString} from '@ckeditor/ckeditor5-utils';

export class BaseView<CkView extends View = View> {
  public readonly ckView: CkView;

  public readonly locale: Locale;

  public constructor(locale: Locale, ckView: CkView) {
    this.ckView = ckView;
    this.locale = locale;
  }

  public setHandler(handler: () => void, priority?: PriorityString): void {
    this.ckView.on('execute', handler, {priority});
  }

  public delegateTo(handler: BaseView, event: string): void {
    this.ckView.delegate('execute').to(handler.ckView, event);
  }
}