export enum ColumnType {
  number = 'NUMBER',
  string = 'STRING',
  hash = 'HASH',
  date = 'DATE',
}

export type ColumnData = Readonly<{
  name: string,
  description: string | null,
  references: string | null,
  type: ColumnType,
}>;

export type TableData = Readonly<{
  name: string,
  description: string | null,
  count: number,
  columns: ColumnData[],
}>;