import {HttpMethod, ListData, RoleData} from '../../';
import {g_Route} from '../../utils';

export const g_RolesRoute = {
  getRoles: new g_Route<null, ListData<RoleData>>(
    HttpMethod.get,
    'roles',
    'Получить все роли',
  ),
  getRolesMany: new g_Route<null, ListData<RoleData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'roles/many',
    'Получить роли',
  ),
  getRole: new g_Route<{ id: number }, RoleData>(
    HttpMethod.get,
    'roles/:id',
    'Получить роль',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_RolesRoute;
