import {roles, UserData} from '#global';
import {FlexSeparator, List, ListProps} from '#components/atoms';
import {memo} from 'react';
import {getEm} from '#includes/utils';

type UserDataInOneLinePropsMin = {
  user: Partial<UserData>,
  fields: (keyof UserData | 'separator' | 'initials')[],
};

export type UserDataInOneLineProps =
  Omit<ListProps, keyof UserDataInOneLinePropsMin | 'horizontal'>
  & UserDataInOneLinePropsMin;

export const UserDataInOneLine = memo<UserDataInOneLineProps>(props => {
  const {user, fields, ...otherProps} = props;

  return (
    <List horizontal={true} gap={getEm(7)} {...otherProps}>
      {fields.map((field, index) => (
        field === 'separator' ? (
          <FlexSeparator key={index}/>
        ) : (
          <div key={index}>
            {field === 'RolesId' ? (
              user.RolesId?.length
                ? user.RolesId
                  .map(roleId => roles[roleId].name)
                  .join(', ')
                : 'Нет роли'
            ) : field === 'id' ? (
              `${user.id}.`
            ) : field === 'initials' ? (
              [user.firstName, user.patronymic]
                .filter(v => v)
                .map(v => `${v![0]}.`)
                .join('')
            ) : (
              user[field]
            )}
          </div>
        )
      ))}
    </List>
  );
});