import {DowncastWriter, ViewElementAttributes, ViewNode} from '@ckeditor/ckeditor5-engine';
import {ConverterBase} from '#includes/content/editor/plugins/utils';
import {ConverterError} from '#includes/error';
import {AnswerConnectionsData, AnswerModelName} from '#global';
import {TextOrFileDatum} from '#includes/content/editor/Views';
import {Keyword} from '#includes/content/editor/Keyword';
import {ModelData} from './Form';
import {Random} from '#includes/Random';
import {Insertable} from '#includes/Interactive';

export const Converter = new (class Converter extends ConverterBase<
  AnswerModelName.connectionsModel,
  ModelData
> {
  public toAnswerValue(
    container: Element,
    isView: boolean,
  ): AnswerConnectionsData['value'] | Error {
    if (isView) {
      if (!(container instanceof HTMLDivElement)) return new ConverterError(
        `ConnectionsConverter.${this.toAnswerValue.name}`,
        'container не является Div',
        {container},
      );

      const center = container.childNodes[1];
      if (!(center instanceof HTMLDivElement)) return new ConverterError(
        `ConnectionsConverter.${this.toAnswerValue.name}`,
        'center не является Div',
        {center},
      );

      const answer: number[] = [];

      for (const container of center.childNodes) {
        if (!(container instanceof HTMLDivElement)) return new ConverterError(
          `ConnectionsConverter.${this.toAnswerValue.name}`,
          'container не является Div',
          {container},
        );

        const child = container.firstChild;

        if (child instanceof HTMLElement && child.classList.contains('draggable-empty'))
          continue;

        if (!(child instanceof HTMLDivElement)) return new ConverterError(
          `ConnectionsConverter.${this.toAnswerValue.name}`,
          'child не является Div',
          {child},
        );

        const value = Number(child.getAttribute('data-draggable-index'));
        if (Number.isNaN(value)) return new ConverterError(
          `OrderedConverter.${this.toAnswerValue.name}`,
          'child не имеет index в атрибуте',
          {child},
        );

        answer.push(value);
      }

      return answer;
    }

    const data = this.toModelData(container);
    if (data instanceof Error) return data;

    const indexes = new Random(JSON.stringify(data))
      .createRandomizedIndexes(data.length);

    return indexes;
  }

  public toView(
    data: Element,
  ): Element | Error {
    if (!(data instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.activateView.name}`,
      'data не является Div',
      {data},
    );

    const right = data.childNodes[2];
    if (!(right instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.activateView.name}`,
      'right не является Div',
      {right},
    );

    const div = document.createElement('div');
    div.append(...right.childNodes);
    right.appendChild(div);

    const rightDiv = right.firstChild;
    if (!(rightDiv instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.activateView.name}`,
      'rightDiv не является Div',
      {rightDiv},
    );

    for (const rightChild of rightDiv.childNodes) {
      if (!(rightChild instanceof HTMLDivElement)) return new ConverterError(
        `ConnectionsConverter.${this.activateView.name}`,
        'rightChild не является Div',
        {rightChild},
      );

      rightChild.classList.add(`${Keyword.connectionsClass}-draggable`);
    }

    return data;
  }

  public activateView(
    view: Element,
    limitContainer: HTMLElement,
  ): Element | Error {
    if (!(view instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.activateView.name}`,
      'view не является Div',
      {view},
    );

    const right = view.childNodes[2];
    if (!(right instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.activateView.name}`,
      'right не является Div',
      {right},
    );

    const rightDiv = right.firstChild;
    if (!(rightDiv instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.activateView.name}`,
      'rightDiv не является Div',
      {rightDiv},
    );

    const insertable = Insertable.create({
      element: rightDiv,
      limitElement: limitContainer,
      childrenLimit: rightDiv.childNodes.length,
    });

    const center = view.childNodes[1];
    if (!(center instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.activateView.name}`,
      'center не является Div',
      {center},
    );

    for (const centerChild of center.childNodes) {
      if (!(centerChild instanceof HTMLDivElement)) return new ConverterError(
        `ConnectionsConverter.${this.activateView.name}`,
        'centerChild не является Div',
        {centerChild},
      );

      Insertable.create({
        element: centerChild,
        group: insertable.group,
        limitElement: limitContainer,
        childrenLimit: 1,
      });
    }

    return view;
  }

  public toData(
    editor: Element,
  ): Element | Error {
    const answer = this.toAnswerValue(editor, false);
    if (answer instanceof Error) return answer;

    if (!(editor instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.toData.name}`,
      'editor не является Div',
      {editor},
    );

    const right = editor.childNodes[2];
    if (!(right instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.toData.name}`,
      'right не является Div',
      {right},
    );

    const confusedRight = this.confuseNested(answer, right);
    if (confusedRight instanceof Error) return confusedRight;

    return editor;
  }

  public toEditor(
    data: Element,
    answer: AnswerConnectionsData['value'],
  ): Element | Error {
    if (!(data instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.toEditor.name}`,
      'data не является Div',
      {data},
    );

    const right = data.childNodes[2];
    if (!(right instanceof HTMLDivElement)) return new ConverterError(
      `ConnectionsConverter.${this.toEditor.name}`,
      'right не является Div',
      {right},
    );

    const arrangedRight = this.arrangeNested(answer, right);
    if (arrangedRight instanceof Error) return arrangedRight;

    return data;
  }

  public toModelData(
    editor: Node | ViewNode,
  ): ModelData | Error {
    const container = this.toNodeData(editor);

    if (container.isText() || !container.nameIs('div')) return new ConverterError(
      `ConnectionsConverter.${this.toModelData.name}`,
      'container не является Div',
      {container},
    );

    const left = container.getChild(0);
    if (!left || left.isText() || !left.nameIs('div')) return new ConverterError(
      `ConnectionsConverter.${this.toModelData.name}`,
      'left не является Div',
      {left},
    );

    const leftValues: TextOrFileDatum[] = [];
    for (const div of left.children) {
      const value = this.nodeToTextOrFileDatum(div);
      if (value instanceof Error) return value;

      leftValues.push(value);
    }

    const right = container.getChild(2);
    if (!right || right.isText() || !right.nameIs('div')) return new ConverterError(
      `ConnectionsConverter.${this.toModelData.name}`,
      'right не является Div',
      {right},
    );

    const rightValues: TextOrFileDatum[] = [];
    for (const div of right.children) {
      const value = this.nodeToTextOrFileDatum(div);
      if (value instanceof Error) return value;

      rightValues.push(value);
    }

    if (leftValues.length !== rightValues.length) return new ConverterError(
      `ConnectionsConverter.${this.toModelData.name}`,
      'длинна leftValues не равна длине rightValues',
      {leftValues, rightValues},
    );

    return rightValues.map((_value, i) => [leftValues[i], rightValues[i]]);
  }

  public toModel(
    writer: DowncastWriter,
    data: ModelData,
    _isEditor: boolean,
  ): { attributes: ViewElementAttributes, children: ViewNode[] } | Error {
    const left: TextOrFileDatum[] = [];
    const right: TextOrFileDatum[] = [];

    data.forEach(row => {
      left.push(row[0]);
      right.push(row[1]);
    });

    if (left.length !== right.length) return new ConverterError(
      `ConnectionsConverter.${this.toModel.name}`,
      'Длина left не равна длине right',
      {left, right},
    );

    return {
      attributes: {
        class: [Keyword.answerClass, Keyword.connectionsClass].join(' '),
      },
      children: [
        writer.createContainerElement('div', {}, (
          left.map(datum => (
            this.textOrFileDatumToModel(writer, datum)
          ))
        )),
        writer.createContainerElement('div', {}, (
          left.map(() => (
            writer.createEmptyElement('div', {})
          ))
        )),
        writer.createContainerElement('div', {}, (
          right.map(datum => (
            this.textOrFileDatumToModel(writer, datum)
          ))
        )), // TODO Сделать элементы в строчку
      ],
    };
  }
})();