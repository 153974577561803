import {Clickable, ClickableProps} from '#components/atoms';
import {css} from '@emotion/react';
import {getEm} from '#includes/utils';
import {observer} from 'mobx-react-lite';
import {FormEventHandler, HTMLAttributes, useEffect, useState} from 'react';
import {FaCheck} from '@react-icons/all-files/fa/FaCheck';
import {borders, ColorName, palette} from '#config';
import {theme} from '#store';

type CheckBoxPropsMin = {
  label?: string,
  checked?: boolean,
} & Pick<HTMLAttributes<HTMLInputElement>, 'onInput' | 'onChange'>;

export type CheckBoxProps =
  Omit<ClickableProps, keyof CheckBoxPropsMin | 'value' | 'children'>
  & CheckBoxPropsMin;

const styles = {
  base: () => css({
    alignItems: 'center',
    color: palette.black,
  }),
  checkbox: (color: ColorName, checked: boolean) => css({
    display: 'flex',
    transition: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: borders.width.small,
    borderRadius: borders.radius.min,
    borderColor: 'currentcolor',
    backgroundColor: checked ? 'transparent' : palette.white,
  }),
  icon: (checked: boolean) => css({
    color: 'currentcolor',
    fontSize: getEm(15),
    transition: `${checked ? 50 : 80}ms ease-out`,
    transform: `scale(${checked ? 1 : 0})`,
    pointerEvents: 'none',
  }),
  label: () => css({
    margin: `0 ${getEm(2)} 0 ${getEm(5)}`,
    color: 'currentcolor',
  }),
  input: () => css({
    display: 'none',
  }),
};

export const CheckBox = observer<CheckBoxProps>(props => {
  const {
    label,
    printing = 'outlined',
    disabled = false,
    checked = false,
    color = theme.current.baseColor,
    onInput,
    onChange,
    ...otherProps
  } = props;

  const [isChecked, setChecked] = useState<boolean>(checked);

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  const inputHandler: FormEventHandler<HTMLInputElement> = e => {
    setChecked(e.currentTarget.checked);
    onInput && onInput(e);
  };

  const changeHandler: FormEventHandler<HTMLInputElement> = e => {
    setChecked(e.currentTarget.checked);
    onChange && onChange(e);
  };

  return (
    <Clickable
      css={styles.base()}
      printing={printing}
      color={color}
      element={'label'}
      {...otherProps}
    >
      <input
        disabled={disabled}
        defaultChecked={checked}
        css={styles.input()}
        type={'checkbox'}
        onInput={inputHandler}
        onChange={changeHandler}
      />
      <div css={styles.checkbox(color, isChecked)}>
        <FaCheck css={styles.icon(isChecked)}/>
      </div>
      {label && (
        <span css={styles.label()}>
          {label}
        </span>
      )}
    </Clickable>
  );
});