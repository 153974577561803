import {
  HttpMethod,
  ListData,
  UserMetadatumArgs,
  UserMetadatumData,
  UserMetadatumEditionArgs,
} from '../../';
import {g_Route} from '../../utils';

export const g_UserMetadataRoute = {
  createUserMetadatum: new g_Route<null, UserMetadatumData, UserMetadatumArgs>(
    HttpMethod.post,
    'user-metadata',
    'Добавить значение метаданных',
  ),
  createUserMetadata: new g_Route<null, ListData<UserMetadatumData>, UserMetadatumArgs[]>(
    HttpMethod.post,
    'user-metadata/many',
    'Добавить значения метаданных',
  ),
  getUserMetadata: new g_Route<null, ListData<UserMetadatumData>>(
    HttpMethod.get,
    'user-metadata',
    'Получить все значения метаданных',
  ),
  getUserMetadataMany: new g_Route<null, ListData<UserMetadatumData>, undefined, {
    ids?: number[]
  }>(
    HttpMethod.get,
    'user-metadata/many',
    'Получить значения метаданных',
  ),
  getUserMetadatum: new g_Route<{ id: number }, UserMetadatumData>(
    HttpMethod.get,
    'user-metadata/:id',
    'Получить значение метаданных',
  ),
  editUserMetadatum: new g_Route<{ id: number }, UserMetadatumData, Partial<UserMetadatumArgs>>(
    HttpMethod.put,
    'user-metadata/:id',
    'Изменить значение метаданных',
  ),
  editUserMetadata: new g_Route<null, ListData<UserMetadatumData>, UserMetadatumEditionArgs[]>(
    HttpMethod.put,
    'user-metadata/many',
    'Изменить значения метаданных',
  ),
  deleteUserMetadatum: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'user-metadata/:id',
    'Удалить значение метаданных',
  ),
  deleteUserMetadata: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'user-metadata/many',
    'Удалить значения метаданных',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_UserMetadataRoute;
