import {Vector2} from '#includes/geometry';

export class Rectangle {
  public static fromElement(element: HTMLElement): Rectangle {
    const {left, top, right, bottom} = element.getBoundingClientRect();
    const topLeft = new Vector2(left, top);
    const bottomRight = new Vector2(right, bottom);
    const rect = new Rectangle(topLeft, bottomRight);

    const {scrollLeft, scrollTop} = document.body.parentElement as HTMLHtmlElement;
    const scroll = new Vector2(scrollLeft, scrollTop);

    return rect.shift(scroll);
  }

  //

  public a: Vector2;
  public b: Vector2;

  public constructor()
  public constructor(a: Vector2, b: Vector2)
  public constructor(a: Vector2 = new Vector2(), b: Vector2 = new Vector2()) {
    this.a = a;
    this.b = b;
  }

  public shift(offset: Vector2): Rectangle {
    return new Rectangle(
      this.a.add(offset),
      this.b.add(offset),
    );
  }

  public getCenter(): Vector2 {
    const halfA = this.a.divide(2);
    const halfB = this.b.divide(2);

    return halfA.add(halfB);
  }

  public isInside(position: Vector2): boolean {
    return this.a.lessOrEqual(position) && position.lessOrEqual(this.b);
  }

  public get left(): number {
    return this.a.x;
  }

  public get top(): number {
    return this.a.y;
  }

  public get right(): number {
    return this.b.x;
  }

  public get bottom(): number {
    return this.b.y;
  }
}

(globalThis as any).Rectangle = Rectangle;