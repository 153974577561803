import {
  HttpMethod,
  ListData,
  UserSectionArgs,
  UserSectionData,
  UserSectionEditionArgs,
} from '../../';
import {g_Route} from '../../utils';

export const g_UserSectionsRoute = {
  createUserSection: new g_Route<null, UserSectionData, UserSectionArgs>(
    HttpMethod.post,
    'user-sections',
    'Добавить раздел для изучения',
  ),
  createUserSections: new g_Route<null, ListData<UserSectionData>, UserSectionArgs[]>(
    HttpMethod.post,
    'user-sections/many',
    'Добавить разделы для изучений',
  ),
  getUserSections: new g_Route<null, ListData<UserSectionData>>(
    HttpMethod.get,
    'user-sections',
    'Получить все разделы для изучений',
  ),
  getUserSectionsMany: new g_Route<null, ListData<UserSectionData>, undefined, { ids?: number[] }>(
    HttpMethod.get,
    'user-sections/many',
    'Получить разделы для изучений',
  ),
  getUserSection: new g_Route<{ id: number }, UserSectionData>(
    HttpMethod.get,
    'user-sections/:id',
    'Получить раздел для изучения',
  ),
  editUserSection: new g_Route<{ id: number }, UserSectionData, Partial<UserSectionArgs>>(
    HttpMethod.put,
    'user-sections/:id',
    'Изменить раздел для изучения',
  ),
  editUserSections: new g_Route<null, ListData<UserSectionData>, UserSectionEditionArgs[]>(
    HttpMethod.put,
    'user-sections/many',
    'Изменить разделы для изучений',
  ),
  deleteUserSection: new g_Route<{ id: number }>(
    HttpMethod.delete,
    'user-sections/:id',
    'Удалить раздел для изучения',
  ),
  deleteUserSections: new g_Route<null, {}, number[]>(
    HttpMethod.delete,
    'user-sections/many',
    'Удалить разделы для изучений',
  ),
} as const;

// noinspection JSUnusedLocalSymbols
const security: { [p: string]: g_Route<any, any, any, any> } = g_UserSectionsRoute;
