import {css} from '@emotion/react';
import {theme} from '#store';

export const textStyles = css`
  .red-line {
    text-indent: 2em;
  }

  .colored-theme {
    background-color: transparent;
    color: ${theme.current.basePalette.hard};
  }

  .colored-red {
    background-color: transparent;
    color: #FF0000;
  }

  .colored-orange {
    background-color: transparent;
    color: #d67100;
  }

  .colored-yellow {
    background-color: transparent;
    color: #b9b900;
  }

  .colored-green {
    background-color: transparent;
    color: #00c800;
  }

  .colored-cyan {
    background-color: transparent;
    color: #00d8d8;
  }

  .colored-blue {
    background-color: transparent;
    color: #4545ff;
  }

  .colored-purple {
    background-color: transparent;
    color: #ff1aff;
  }
`;