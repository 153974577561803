import type {ViewText} from '@ckeditor/ckeditor5-engine';
import type {NodeName} from './NodeName';
import {NodeData} from './NodeData';
import {ConverterError} from '#includes/error';

export class TextData extends NodeData {
  protected readonly element: Text | ViewText;
  public readonly name: Extract<NodeName, 'text'>;

  public constructor(element: Text | ViewText) {
    super();

    this.element = element;

    const name = NodeData.getName(element);
    if (name !== 'text') throw new ConverterError(
      'TextData.constructor',
      `element с этим именем (${name}) не может быть TextData`,
      {element},
    );
    this.name = name;
  }

  public get data(): string {
    return this.element.data;
  }
}