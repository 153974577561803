export class Vector2 {
  public static fromScroll(): Vector2 {
    const {scrollLeft, scrollTop} = document.body.parentElement as HTMLHtmlElement;

    return new Vector2(scrollLeft, scrollTop);
  }

  public static clientFromEvent(event: MouseEvent | TouchEvent): Vector2 {
    const target = event instanceof MouseEvent ? event : event.touches[0];
    if (!target) return new Vector2();

    return new Vector2(target.clientX, target.clientY);
  }

  public static fromEvent(event: MouseEvent | TouchEvent): Vector2 {
    const target = event instanceof MouseEvent ? event : event.touches[0];
    if (!target) return new Vector2();

    return new Vector2(target.pageX, target.pageY);
  }

  public static fromElementSize(element: HTMLElement) {
    const {width, height} = element.getBoundingClientRect();

    return new Vector2(width, height);
  }

  public static fromElementTopLeft(element: HTMLElement) {
    const {left, top} = element.getBoundingClientRect();

    return new Vector2(left, top).add(Vector2.fromScroll());
  }

  public static fromElementCenter(element: HTMLElement) {
    const {left, top, width, height} = element.getBoundingClientRect();

    return new Vector2(left, top)
      .add(new Vector2(width, height).divide(2))
      .add(Vector2.fromScroll());
  }

  //

  public x: number;
  public y: number;

  public constructor()
  public constructor(x: number, y: number)
  public constructor(x: number = 0, y: number = 0) {
    this.x = x;
    this.y = y;
  }

  public set(vector: Vector2): void {
    this.x = vector.x;
    this.y = vector.y;
  }

  public add(vector: Vector2): Vector2 {
    return new Vector2(this.x + vector.x, this.y + vector.y);
  }

  public subtract(vector: Vector2): Vector2 {
    return new Vector2(this.x - vector.x, this.y - vector.y);
  }

  public divide(value: number): Vector2 {
    return new Vector2(this.x / value, this.y / value);
  }

  public multiply(value: number): Vector2 {
    return new Vector2(this.x * value, this.y * value);
  }

  public abs(): Vector2 {
    return new Vector2(Math.abs(this.x), Math.abs(this.y));
  }

  public round(): Vector2 {
    return new Vector2(Math.round(this.x), Math.round(this.y));
  }

  public roundTowardsZero(): Vector2 {
    return new Vector2(~~this.x, ~~this.y);
  }

  public getLength(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  public lessOrEqual(vector: Vector2): boolean {
    return this.x <= vector.x && this.y <= vector.y;
  }
}

(globalThis as any).Vector2 = Vector2;

export type ReadonlyVector2 = Omit<Vector2, 'set' | 'x' | 'y'> & {
  readonly x: number,
  readonly y: number,
};