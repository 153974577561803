import {HTMLAttributes} from 'react';
import {observer} from 'mobx-react-lite';
import {css} from '@emotion/react';
import {borders, palette} from '#config';

type SeparatorProps = Omit<HTMLAttributes<HTMLElement>, 'children'>;

const styles = {
  base: () => css({
    borderWidth: borders.width.base,
    borderTopStyle: 'solid',
    borderColor: palette.black,
  }),
};

export const Separator = observer<SeparatorProps>(props => {
  const {
    ...otherProps
  } = props;

  return (
    <hr css={styles.base()} {...otherProps}/>
  );
});