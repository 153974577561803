import '@ckeditor/ckeditor5-build-classic/build/translations/ru.js';
import {add as addLocalization} from '@ckeditor/ckeditor5-utils/src/translation-service';
import {Keyword} from './Keyword';
import {AnswerModelName} from '#global';

const Labels: {
  [model in AnswerModelName]: [Keyword, string]
} = {
  [AnswerModelName.textModel]: [Keyword.textLabel, 'Вставить текстовое поле'],
  [AnswerModelName.dropListModel]: [Keyword.dropListLabel, 'Вставить выпадающий список'],
  [AnswerModelName.checkListModel]: [Keyword.checkListLabel, 'Вставить список с флажками'],
  [AnswerModelName.sortingModel]: [Keyword.sortingLabel, 'Вставить сортировку'],
  [AnswerModelName.connectionsModel]: [Keyword.connectionsLabel, 'Вставить соединения'],
  [AnswerModelName.orderedModel]: [Keyword.orderedLabel, 'Вставить последовательность'],
};

addLocalization('ru', {
  [Keyword.formHelpLabel]: 'Помощь',
  [Keyword.formSaveLabel]: 'Сохранить',
  [Keyword.formCancelLabel]: 'Отмена',
  [Keyword.formRemoveLabel]: 'Удалить',
  [Keyword.formAddLabel]: 'Добавить',
  [Keyword.formInputLabel]: 'Вариант ответа',
  [Keyword.formCorrectLabel]: 'Правильный ответ',
  [Keyword.formIsCorrectLabel]: 'Правильный ли это ответ',
  [Keyword.formIsHorizontalLabel]: 'Горизонтальный ли',
  [Keyword.formFileLabel]: 'Выберите файл',
  [Keyword.formSwitchTextOrFileLabel]: 'Заменить текст и картину',

  [Keyword.widgetInsertLabel]: 'Вставить упражнение',
  [Keyword.videoLabel]: 'Вставить видео',
  [Keyword.audioLabel]: 'Вставить аудио',
  [Keyword.formulaLabel]: 'Вставить формулу',

  ...Object.fromEntries(Object.values(Labels)),
});