import {SuperError} from '#includes/error';

export class SwitchDatum {
  private readonly id?: string;

  public value: string;
  public enabled: boolean;

  public constructor(value?: string, enabled?: boolean)
  public constructor(id: string, value: string, enabled: boolean)
  public constructor(id?: string, value?: string | boolean, enabled?: boolean) {
    this.value = '';
    this.enabled = false;

    if (id === undefined) return;
    this.value = id;

    if (value === undefined) return;
    if (typeof value === 'boolean') {
      this.enabled = value;
      return;
    }

    if (enabled !== undefined) {
      this.id = id;
      this.value = value;
      this.enabled = enabled;
      return;
    }

    throw new SuperError(
      `${this.constructor.name}.new`,
      'invalid parameters',
    );
  }

  public toString(): string {
    return `${this.value} ${this.enabled}`;
  }
}