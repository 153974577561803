import {ViewElement, ViewText} from '@ckeditor/ckeditor5-engine';
import {NodeName, nodeNames} from './NodeName';
import {ConverterError} from '#includes/error';
import {TextData} from '#includes/content/editor/plugins/utils/Converter/TextData';

export abstract class NodeData {
  protected abstract readonly element: Element | ViewElement | Text | ViewText;
  public abstract readonly name: NodeName;

  private static isValidName(name: string): name is NodeName {
    return nodeNames.includes(name as any);
  }

  public static getName(element: Element | ViewElement | Text | ViewText): NodeName {
    if (element instanceof Text || element instanceof ViewText)
      return 'text';

    const name = element instanceof Element
      ? element.tagName.toLowerCase()
      : element.name.toLowerCase();

    if (!NodeData.isValidName(name)) throw new ConverterError(
      `NodeData.getName`,
      `Неизвестное имя (${name})`,
      {element},
    );

    return name;
  }

  public isText(): this is TextData {
    return this.element instanceof Text || this.element instanceof ViewText;
  }
}