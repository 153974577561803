import {Vector2} from '#includes/geometry';

export type InteractiveProps = {
  element: HTMLElement,
  limitElement: HTMLElement,
};

export abstract class Interactive {
  private static readonly map: Map<HTMLElement, Interactive> = new Map();

  public static getByElement(element: Node): Interactive | undefined {
    if (!(element instanceof HTMLElement)) return;

    return Interactive.map.get(element);
  }

  public static deactivateByElement(element: Node): void {
    const interactive = Interactive.getByElement(element);
    if (!interactive) return;

    interactive.deactivate();
  }

  public static deactivateAll(): void {
    Interactive.map.forEach(interactive => interactive.deactivate());
  }

  //

  protected interval: number = NaN;

  protected readonly limitElement: HTMLElement;
  public readonly element: HTMLElement;

  protected constructor(props: InteractiveProps) {
    const {
      element,
      limitElement,
    } = props;

    this.limitElement = limitElement;

    this.element = element;
    this.element.classList.add('draggable');

    const current = Interactive.map.get(props.element);
    if (current) return current;

    Interactive.map.set(props.element, this);
  }

  public deactivate(): void {
    Interactive.map.delete(this.element);

    this.element.style.opacity = '';
    this.element.classList.remove('draggable');
  }

  public getElementPosition(): Vector2 {
    return Vector2.fromElementCenter(this.element);
  }

  public static createId(): Symbol {
    return Symbol('Draggable.createId');
  }

  //

  protected static setElementSize(element: HTMLElement, size: Vector2 | null | 'max'): void {
    element.style.width = size ? size === 'max' ? 'max-content' : `${size.x}px` : '';
    element.style.height = size ? size === 'max' ? 'max-content' : `${size.y}px` : '';
  }
}