import {Plugin} from '@ckeditor/ckeditor5-core';
import {Box} from './Box';
import {Answer} from './Answers';

export class Exercise extends Plugin {
  public static get requires() {
    return [Box, Answer];
  }
}

export {
  modelClass,
  modelClasses,
  modelStyle,
  modelStyles,
  modelConverter,
  modelConverters,
} from './Answers';