export const nodeNames = [
  'text',
  'input',
  'div',
  'span',
  'table',
  'tr',
  'td',
  'th',
  'tbody',
  'thead',
  'section',
  'caption',
  'option',
  'select',
  'br',
  'label',
  'img',
] as const;

export type NodeName = typeof nodeNames[number];