import {Keyword} from '#includes/content/editor/Keyword';
import {css} from '@emotion/react';
import {modelClass, modelStyles} from '#includes/content/editor/plugins';
import {palette} from '#config';
import {ContainerStyle} from '#includes/ContainerStyle';
import {getEm} from '#includes/utils';

export const plugins = css(
  Object.fromEntries(modelStyles.map(
    ([model, answerStyle]) => [`.${modelClass[model]}`, answerStyle.editor],
  )),
  css`
    // Контейнер упражнения
    .${Keyword.widgetClass} {
      &:after {
        content: 'Отправить';
        ${ContainerStyle.Filled};

        margin-top: ${getEm(7)};
        float: right;
      }
    }

    // Внутренности плагинов некликабельны
    .${Keyword.textClass},
    .${Keyword.checkListClass},
    .${Keyword.connectionsClass},
    .${Keyword.orderedClass},
    .${Keyword.sortingClass},
    .${Keyword.videoClass},
    .${Keyword.audioClass},
    .${Keyword.formulaClass} {
      position: relative;
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: all;
      }
    }

    // Панель упражнения без обводки и теней
    .${Keyword.panelClass}.ck-editor__nested-editable {
      border: none;

      &_focused, &:focus {
        box-shadow: none;
      }
    }

    // Метаданные упражнения
    .${Keyword.metaClass} {
      padding: 0.3em;

      display: flex;
      gap: 2em;

      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-50%);
      max-width: min(80vw, 50em);

      font-size: 0.7em;
      font-weight: bold;
      color: ${palette.gray.base};
      white-space: nowrap;

      pointer-events: none;

      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        pointer-events: all;
      }

      &::before {
        right: 0;
        width: 3.3em;
        border-radius: 10em 0 0 0;
      }

      &::after {
        left: 0;
        right: 3.7em;
        border-radius: 0 10em 0 0;
      }

      &:hover {
        background-color: var(--ck-color-base-background);
      }

      & > div:first-of-type {
        max-width: 15em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > div:last-of-type {
        display: flex;
        gap: 1em;
      }
    }

    // Видео
    .${Keyword.videoClass} {
      width: 100%;
      aspect-ratio: 16 / 9;
      background-color: black;

      & > video {
        display: block;
        height: 100%;
        width: 100%;
        margin: auto;
      }
    }

    // Аудио
    .${Keyword.audioClass} {
      width: 100%;

      & > audio {
        width: 100%;
      }
    }

    // Формула
    .${Keyword.formulaClass} {
    }
  `,
);