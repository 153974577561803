import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Collection} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseForm} from '#includes/content/editor/Forms';
import {InputView, ItemView, SwitchView} from '#includes/content/editor/Views';
import {SwitchDatum} from './SwitchDatum';
import {SwitchListView} from './_List';

type FormChildren = [SwitchListView];
type ItemChildren = [InputView, SwitchView];
export type SwitchData = SwitchDatum[];

export {
  SwitchDatum,
};

export abstract class SwitchListForm extends BaseForm<FormChildren, SwitchData> {
  private readonly list: SwitchListView;
  protected readonly listChildren: Collection<ItemView<ItemChildren>>;

  protected constructor(
    locale: Locale,
    className: Keyword,
    cancelCallback: () => void,
  ) {
    super(locale, className, cancelCallback);

    this.list = SwitchListView.create(
      this.locale,
      this.switchHandler.bind(this),
    );
    this.list.setUpdateHandler(() => {
      this.form.updateFocusTracker();
    });
    this.listChildren = this.list.getChildrenCollection();

    this.form.setChildren([
      this.list,
    ]);
  }

  public override setData(data: SwitchData): void {
    this.list.setItems(data);
    super.setData(data);
  }

  public getData(): SwitchData {
    return this.listChildren.map(item => {
      const [inputView, switchView] = item.getChildren();
      return new SwitchDatum(
        inputView.getValue(),
        switchView.getEnabled(),
      );
    });
  }

  //

  protected abstract switchHandler(item: ItemView<ItemChildren>, enabled: boolean): void;
}