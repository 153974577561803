import {Clickable, ClickableProps} from '#components/atoms';
import {RouteBase} from '#includes/RouteBase';
import {HTMLAttributeAnchorTarget, ReactElement} from 'react';
import {observer} from 'mobx-react-lite';
import {Route, routes} from '#config';

export type LinkPropsMin<
  RouteName extends Route | string,
  RouteObject extends RouteBase<string, any> = RouteName extends string
    ? RouteBase
    : (typeof routes)[RouteName extends string ? any : RouteName],
> = {
  target?: HTMLAttributeAnchorTarget
  to?: RouteName,
} & (RouteObject['_typeK'] extends never ? {
  params?: undefined,
} : {
  params: { [key in RouteObject['_typeK']]: string | number },
});

export type LinkProps<RouteName extends Route | string> =
  Omit<ClickableProps, 'to' | 'params' | 'disabled' | 'element'>
  & LinkPropsMin<RouteName>;

type ILink = <RouteName extends Route | string>(props: LinkProps<RouteName>) => ReactElement | null;
export const Link: ILink = observer((props) => {
  const {
    children,
    color = 'blue',
    printing = 'custom', // TODO сделать тип отображения "text" для цветного текста
    to = '#',
    params,
    ...otherProps
  } = props;

  const route = routes[to as Route];
  const url =
    route ?
      route._typeK
        ? route.createUrl(params as any)
        : String(route)
      : to as string;

  return (
    <Clickable color={color} element={'RouterLink'} printing={printing} to={url} {...otherProps}>
      {children}
    </Clickable>
  );
});