import type {Locale} from '@ckeditor/ckeditor5-utils';
import {InputFileView, ProgressView} from '#includes/content/editor/Views';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseForm} from '#includes/content/editor/Forms';
import {notifications} from '#store';

export class Form extends BaseForm<[ProgressView, InputFileView], string> {
  private readonly progress: ProgressView;
  private readonly file: InputFileView;

  public constructor(locale: Locale, cancelCallback: () => void) {
    super(locale, Keyword.audioFormClass, cancelCallback);

    this.progress = ProgressView.create(locale, []);

    this.file = InputFileView.create(
      locale,
      [],
      Keyword.formFileLabel,
    );
    this.file.setProgressHandler((max, value) => {
      this.progress.setMax(max);
      this.progress.setValue(value);

      if (!this.progress.ckView.element) return;
      this.progress.ckView.element.classList.add(Keyword.loadingClass);
    });
    this.file.setFinallyHandler(file => {
      this.progress.setValue(0);

      if (!this.progress.ckView.element) return;
      this.progress.ckView.element.classList.remove(Keyword.loadingClass);

      if (!file) return;
      if (file.mimetype.startsWith('audio')) return;
      notifications.addError('Файл должен быть видео');
      this.file.clear();
    });

    this.form.setChildren([
      this.progress,
      this.file,
    ]);
  }

  public getData(): string {
    return this.file.getFile() || '';
  }

  public override setData(data: string = ''): void {
    if (!data) return this.file.clear();

    this.file.setFile(data);

    super.setData(data);
  }
}