import {BaseData} from '../../';

export enum DifficultyId {
  easy = 1,
  normal,
  hard,
}

export type DifficultyData = BaseData<{
  id: DifficultyId,
  name: string,
}>;

export const difficulties: { readonly [key in DifficultyId]: DifficultyData } = {
  [DifficultyId.easy]: {
    id: DifficultyId.easy,
    name: 'Лёгкий',
  },
  [DifficultyId.normal]: {
    id: DifficultyId.normal,
    name: 'Обычный',
  },
  [DifficultyId.hard]: {
    id: DifficultyId.hard,
    name: 'Сложный',
  },
};
