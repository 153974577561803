import {SwitchButtonView} from '@ckeditor/ckeditor5-ui';
import type {Locale} from '@ckeditor/ckeditor5-utils';
import {Keyword} from '#includes/content/editor/Keyword';
import {BaseView} from '#includes/content/editor/Views';

export class SwitchView extends BaseView<SwitchButtonView> {
  private constructor(
    locale: Locale,
    className: string | string[],
    label: string,
    showLabel: boolean = false,
  ) {
    super(locale, new SwitchButtonView(locale));

    this.ckView.set({
      withText: showLabel,
      label: showLabel ? locale.t(label) : undefined,
      tooltip: locale.t(label),
      isOn: false,
      class: ['ck', Keyword.switchClass, className].flat().join(' '),
    });
  }

  public static create(
    locale: Locale,
    className: string | string[],
    label: string,
    showLabel?: boolean,
  ): SwitchView {
    return new SwitchView(
      locale,
      className,
      label,
      showLabel,
    );
  }

  public getEnabled(): boolean {
    return this.ckView.isOn;
  }

  public setEnabled(enabled: boolean): void {
    this.ckView.isOn = enabled;
  }

  public focus(): void {
    this.ckView.element?.focus();
  }
}