import {Vector2} from '#includes/geometry';
import {Interactive} from './Interactive';
import {Insertable} from './Insertable';
import {Draggable} from './Draggable';

class Cursor {
  private readonly _position: Vector2 = new Vector2();
  private readonly _prevPosition: Vector2 = new Vector2();
  private _aboveElement: HTMLElement | null = null;
  private _aboveInsertable: Insertable | null = null;
  private _aboveDraggable: Draggable | null = null;

  public constructor() {
    this.bindMethods();
    this.addEventListeners();
  }

  public get position(): Vector2 {
    return this._position;
  }

  public get prevPosition(): Vector2 {
    return this._prevPosition;
  }

  public get aboveInsertable(): Insertable | null {
    return this._aboveInsertable;
  }

  public get aboveDraggable(): Draggable | null {
    return this._aboveDraggable;
  }

  //

  private updateAbove(): void {
    this._aboveInsertable = null;
    this._aboveDraggable = null;

    if (!this._aboveElement) return;

    let interactive = Interactive.getByElement(this._aboveElement);
    if (!interactive && this._aboveElement.parentNode)
      interactive = Interactive.getByElement(this._aboveElement.parentNode);
    if (!interactive) return;

    if (interactive instanceof Draggable) {
      this._aboveDraggable = interactive;
      this._aboveInsertable = this._aboveDraggable.getParent();
    } else if (interactive instanceof Insertable) {
      this._aboveInsertable = interactive;
    }
  }

  private move(event: MouseEvent | TouchEvent): void {
    this._prevPosition.set(this._position);
    this._position.set(Vector2.fromEvent(event));

    const client = Vector2.clientFromEvent(event);
    const target = document.elementFromPoint(client.x, client.y);
    this._aboveElement = target instanceof HTMLElement ? target : null;

    this.updateAbove();
  }

  private addEventListeners(): void {
    window.addEventListener('pointerdown', this.move);
    window.addEventListener('pointermove', this.move);
  }

  private bindMethods(): void {
    this.move = this.move.bind(this);
  }
}

export const cursor = new Cursor();

(globalThis as any).cursor = cursor;