import {Plugin} from '@ckeditor/ckeditor5-core';
import {toWidget, viewToModelPositionOutsideModelElement, Widget} from '@ckeditor/ckeditor5-widget';
import {DowncastWriter, ViewContainerElement} from '@ckeditor/ckeditor5-engine';
import {Command} from './Command';
import {Keyword} from '#includes/content/editor/Keyword';
import {render} from '#includes/content/editor/Views/Formula/render';

export class Editing extends Plugin {
  public static get requires() {
    return [Widget];
  }

  public init() {
    this.defineSchema();
    this.defineConverters();
    this.defineChildCheck();

    this.editor.commands.add(
      Keyword.formulaCommand,
      new Command(this.editor),
    );

    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(
        this.editor.model,
        viewElement => {
          return viewElement.hasClass(Keyword.formulaClass);
        },
      ),
    );
  }

  private defineChildCheck(): void {
    // const schema = this.editor.model.schema;
    //
    // schema.addChildCheck((context, childDefinition) => {
    //   if (childDefinition.name !== Keyword.formulaModel) return;
    //
    //   return;
    // });
  }

  private defineSchema() {
    const schema = this.editor.model.schema;

    schema.register(Keyword.formulaModel, {
      inheritAllFrom: '$inlineObject',
      allowAttributes: ['data'],
    });
  }

  private defineConverters() {
    const conversion = this.editor.conversion;

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: [Keyword.formulaClass],
      },
      model: (view, {writer}) => {
        const data = view.getAttribute('data-value');

        if (!data) return null;

        return writer.createElement(Keyword.formulaModel, {data});
      },
    });

    conversion.for('editingDowncast').elementToElement({
      model: Keyword.formulaModel,
      view: (model, {writer}) => {
        const data = model.getAttribute('data');
        if (typeof data !== 'string') return null;

        const container = this.createContainer(writer, data);

        container._setAttribute('ondblclick', 'Ck_Formula_Ui.showUI(this)');

        return toWidget(container, writer);
      },
    });

    conversion.for('dataDowncast').elementToElement({
      model: Keyword.formulaModel,
      view: (model, {writer}) => {
        const data = model.getAttribute('data');
        if (typeof data !== 'string') return null;

        return this.createContainer(writer, data);
      },
    });
  }

  private createContainer(
    writer: DowncastWriter,
    data: string,
  ): ViewContainerElement {
    return writer.createContainerElement('span', {
      class: Keyword.formulaClass,
      'data-value': data,
    }, [
      writer.createRawElement(
        'span',
        {},
        domElement => render(domElement, data),
      ),
    ], {
      renderUnsafeAttributes: ['ondblclick'],
    });
  }
}