import {css} from '@emotion/react';
import {modelClass, modelStyles} from '#includes/content/editor/plugins';
import {Keyword} from '#includes/content/editor/Keyword';

export const plugins = css(
  Object.fromEntries(modelStyles.map(
    ([model, answerStyle]) => [`.${modelClass[model]}`, answerStyle.general],
  )),
  css`
    .${Keyword.widgetClass} {
      position: relative;
    }

    .${Keyword.panelClass} {
      display: grid;
    }

    .${Keyword.formulaClass} {
      cursor: pointer;

      & > * > * {
        display: inline-block !important;
      }
    }
  `,
);