import {DowncastWriter, ViewElementAttributes, ViewNode} from '@ckeditor/ckeditor5-engine';
import {ConverterBase} from '#includes/content/editor/plugins/utils';
import {ConverterError} from '#includes/error';
import {AnswerModelName, AnswerOrderedData} from '#global';
import {Keyword} from '#includes/content/editor/Keyword';
import {ModelData} from './Form';
import {TextOrFileDatum} from '#includes/content/editor/Views';
import {Random} from '#includes/Random';
import {isTruthy} from '#includes/isTruthy';
import {Insertable} from '#includes/Interactive';

export const Converter = new (class Converter extends ConverterBase<
  AnswerModelName.orderedModel,
  ModelData
> {
  public toAnswerValue(
    container: Element,
    isView: boolean,
  ): AnswerOrderedData['value'] | Error {
    if (isView) {
      if (!(container instanceof HTMLDivElement)) return new ConverterError(
        `OrderedConverter.${this.toAnswerValue.name}`,
        'container не является Div',
        {container},
      );

      const answer: number[] = [];

      for (const child of container.childNodes) {
        if (child instanceof HTMLElement && child.classList.contains('draggable-empty'))
          continue;

        if (!(child instanceof HTMLDivElement)) return new ConverterError(
          `OrderedConverter.${this.toAnswerValue.name}`,
          'child не является Div',
          {child},
        );

        const value = Number(child.getAttribute('data-draggable-index'));
        if (Number.isNaN(value)) return new ConverterError(
          `OrderedConverter.${this.toAnswerValue.name}`,
          'child не имеет index в атрибуте',
          {child},
        );

        answer.push(value);
      }

      return answer;
    }

    const data = this.toModelData(container);
    if (data instanceof Error) return data;

    const answer = new Random(JSON.stringify(data))
      .createRandomizedIndexes(data.values.length);

    return answer;
  }

  public toView(
    data: Element,
  ): Element | Error {
    if (!(data instanceof HTMLDivElement)) return new ConverterError(
      `OrderedConverter.${this.activateView.name}`,
      'data не является Div',
      {data},
    );

    for (const child of data.childNodes) {
      if (!(child instanceof HTMLDivElement)) return new ConverterError(
        `OrderedConverter.${this.activateView.name}`,
        'child не является Div',
        {child},
      );

      child.classList.add(`${Keyword.orderedClass}-draggable`);
    }

    return data;
  }

  public activateView(
    view: Element,
    limitContainer: HTMLElement,
  ): Element | Error {
    if (!(view instanceof HTMLDivElement)) return new ConverterError(
      `OrderedConverter.${this.activateView.name}`,
      'view не является Div',
      {view},
    );

    Insertable.create({
      element: view,
      limitElement: limitContainer,
      childrenLimit: view.childNodes.length,
    });

    return view;
  }

  public toData(
    editor: Element,
  ): Element | Error {
    const answer = this.toAnswerValue(editor, false);
    if (answer instanceof Error) return answer;

    if (!(editor instanceof HTMLDivElement)) return new ConverterError(
      `OrderedConverter.${this.toData.name}`,
      'editor не является Div',
      {editor},
    );

    const confusedEditor = this.confuseNested(answer, editor);
    if (confusedEditor instanceof Error) return confusedEditor;

    return editor;
  }

  public toEditor(
    data: Element,
    answer: AnswerOrderedData['value'],
  ): Element | Error {
    if (!(data instanceof HTMLDivElement)) return new ConverterError(
      `OrderedConverter.${this.toEditor.name}`,
      'data не является Div',
      {data},
    );

    const arrangedData = this.arrangeNested(answer, data);
    if (arrangedData instanceof Error) return arrangedData;

    return data;
  }

  public toModelData(
    editor: Node | ViewNode,
  ): ModelData | Error {
    const container = this.toNodeData(editor);

    if (container.isText() || !container.nameIs('div')) return new ConverterError(
      `OrderedConverter.${this.toModelData.name}`,
      'container не является Div',
      {container},
    );

    const values: TextOrFileDatum[] = [];

    for (const div of container.children) {
      // Редактор добавляет свои элементы в container
      if (!div.isText() && div.hasClass('ck')) continue;

      const value = this.nodeToTextOrFileDatum(div);
      if (value instanceof Error) return value;

      values.push(value);
    }

    const isHorizontal = container.hasClass(Keyword.horizontalClass);

    return new ModelData(values, isHorizontal);
  }

  public toModel(
    writer: DowncastWriter,
    data: ModelData,
    isEditor: boolean,
  ): { attributes: ViewElementAttributes, children: ViewNode[] } | Error {
    return {
      attributes: {
        class: [
          Keyword.answerClass,
          Keyword.orderedClass,
          data.isHorizontal && Keyword.horizontalClass,
        ].filter(isTruthy).join(' '),
      },
      children: data.values.map(datum => (
        this.textOrFileDatumToModel(writer, datum)
      )),
    };
  }
})();