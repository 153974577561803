import {DowncastWriter, ViewElementAttributes, ViewNode} from '@ckeditor/ckeditor5-engine';
import {ConverterBase} from '#includes/content/editor/plugins/utils';
import {ConverterError} from '#includes/error';
import {AnswerModelName, AnswerSortingData} from '#global';
import {Keyword} from '#includes/content/editor/Keyword';
import {ColumnData, ModelData} from './Form';
import {Random} from '#includes/Random';
import {Insertable} from '#includes/Interactive';

export const Converter = new (class Converter extends ConverterBase<
  AnswerModelName.sortingModel,
  ModelData
> {
  public toAnswerValue(
    container: Element,
    isView: boolean,
  ): AnswerSortingData['value'] | Error {
    if (!(container instanceof HTMLTableElement)) return new ConverterError(
      `SortingConverter.${this.toAnswerValue.name}`,
      'container не является Table',
      {container},
    );

    const tbody = container.tBodies[0];
    if (!tbody) return new ConverterError(
      `SortingConverter.${this.toAnswerValue.name}`,
      'container.tBodies[0] отсутствует',
      {tbody},
    );

    const tr = tbody.rows[1];
    if (!tr) return new ConverterError(
      `SortingConverter.${this.toAnswerValue.name}`,
      'tbody.rows[1] отсутствует',
      {tr},
    );

    const data: string[][] = [];

    const cells = tr.cells;
    for (const cell of cells) {
      const dataItem: string[] = [];

      if (isView) {
        const div = cell.firstChild;
        if (!(div instanceof HTMLDivElement)) return new ConverterError(
          `SortingConverter.${this.toAnswerValue.name}`,
          'div не является Div',
          {div},
        );

        for (const span of div.childNodes) {
          // Первый элемент является пунктирной обводкой
          if (span instanceof HTMLElement && span.classList.contains('draggable-empty')) continue;

          if (!(span instanceof HTMLSpanElement)) return new ConverterError(
            `SortingConverter.${this.toAnswerValue.name}`,
            'span не является Span',
            {span},
          );

          const text = span.firstChild;
          if (!(text instanceof Text)) return new ConverterError(
            `SortingConverter.${this.toAnswerValue.name}`,
            'text не является Text',
            {text},
          );

          dataItem.push(text.data);
        }
      } else {
        for (const span of cell.childNodes) {
          if (!(span instanceof HTMLSpanElement)) return new ConverterError(
            `SortingConverter.${this.toAnswerValue.name}`,
            'span не является Span',
            {span},
          );

          const text = span.firstChild;
          if (!(text instanceof Text)) return new ConverterError(
            `SortingConverter.${this.toAnswerValue.name}`,
            'text не является Text',
            {text},
          );

          dataItem.push(text.data);
        }
      }

      data.push(dataItem);
    }

    return data;
  }

  public toView(
    data: Element,
  ): Element | Error {
    if (!(data instanceof HTMLTableElement)) return new ConverterError(
      `SortingConverter.${this.toView.name}`,
      'data не является Table',
      {data},
    );

    const tbody = data.tBodies[0];
    if (!(tbody instanceof HTMLTableSectionElement)) return new ConverterError(
      `SortingConverter.${this.toView.name}`,
      'tbody не является TableSection',
      {tbody},
    );

    const row = tbody.lastChild;
    if (!(row instanceof HTMLTableRowElement)) return new ConverterError(
      `SortingConverter.${this.toView.name}`,
      'row не является TableRow',
      {row},
    );

    for (const cell of row.cells) {
      if (!(cell instanceof HTMLTableCellElement)) return new ConverterError(
        `SortingConverter.${this.toView.name}`,
        'cell не является TableCell',
        {cell},
      );

      cell.innerHTML = '<div></div>';
    }

    const caption = data.caption;
    if (!(caption instanceof HTMLTableCaptionElement)) return new ConverterError(
      `SortingConverter.${this.toView.name}`,
      'caption не является TableCaption',
      {caption},
    );

    caption.innerHTML = `<div>${caption.innerHTML}</div>`;

    return data;
  }

  public activateView(
    view: Element,
    limitContainer: HTMLElement,
  ): Element | Error {
    if (!(view instanceof HTMLTableElement)) return new ConverterError(
      `SortingConverter.${this.activateView.name}`,
      'view не является Table',
      {view},
    );

    const caption = view.caption;
    if (!(caption instanceof HTMLTableCaptionElement)) return new ConverterError(
      `SortingConverter.${this.activateView.name}`,
      'caption не является TableCaption',
      {caption},
    );

    const div = caption.firstChild;
    if (!(div instanceof HTMLDivElement)) return new ConverterError(
      `SortingConverter.${this.activateView.name}`,
      'div не является Div',
      {div},
    );

    for (const span of div.childNodes) {
      if (!(span instanceof HTMLSpanElement)) return new ConverterError(
        `SortingConverter.${this.activateView.name}`,
        'span не является Span',
        {span},
      );

      span.classList.add(`${Keyword.sortingClass}-draggable`);
    }

    const insertable = Insertable.create({
      element: div,
      limitElement: limitContainer,
      childrenLimit: div.childNodes.length,
    });

    const tbody = view.tBodies[0];
    if (!(tbody instanceof HTMLTableSectionElement)) return new ConverterError(
      `SortingConverter.${this.activateView.name}`,
      'tbody не является TableSection',
      {tbody},
    );

    const body = tbody.lastChild;
    if (!(body instanceof HTMLTableRowElement)) return new ConverterError(
      `SortingConverter.${this.activateView.name}`,
      'body не является TableRow',
      {body},
    );

    for (const cell of body.childNodes) {
      if (!(cell instanceof HTMLTableCellElement)) return new ConverterError(
        `SortingConverter.${this.activateView.name}`,
        'cell не является TableCell',
        {cell},
      );

      const div = cell.firstChild;
      if (!(div instanceof HTMLDivElement)) return new ConverterError(
        `SortingConverter.${this.activateView.name}`,
        'div не является Div',
        {div},
      );

      Insertable.create({
        element: div,
        group: insertable.group,
        limitElement: limitContainer,
        childrenLimit: insertable.childrenLimit,
      });
    }

    return view;
  }

  public toData(
    editor: Element,
  ): Element | Error {
    if (!(editor instanceof HTMLTableElement)) return new ConverterError(
      `SortingConverter.${this.toData.name}`,
      'editor не является Table',
      {editor},
    );

    const tbody = editor.tBodies[0];
    if (!(tbody instanceof HTMLTableSectionElement)) return new ConverterError(
      `SortingConverter.${this.toData.name}`,
      'tbody не является TableSection',
      {tbody},
    );

    const body = tbody.lastChild;
    if (!(body instanceof HTMLTableRowElement)) return new ConverterError(
      `SortingConverter.${this.toData.name}`,
      'body не является TableRow',
      {body},
    );

    const caption = editor.createCaption();

    const spans: HTMLSpanElement[] = [];
    for (const cell of body.childNodes) {
      if (!(cell instanceof HTMLTableCellElement)) return new ConverterError(
        `SortingConverter.${this.toData.name}`,
        'cell не является TableCell',
        {cell},
      );

      for (const span of cell.childNodes) {
        if (!(span instanceof HTMLSpanElement)) return new ConverterError(
          `SortingConverter.${this.toData.name}`,
          'span не является Span',
          {span},
        );

        spans.push(span);
      }
    }

    const indexes = new Random().createRandomizedIndexes(spans.length);
    indexes.forEach(index => caption.append(spans[index]));

    return editor;
  }

  public toEditor(
    data: Element,
    answer: AnswerSortingData['value'],
  ): Element | Error {
    if (!(data instanceof HTMLTableElement)) return new ConverterError(
      `SortingConverter.${this.toEditor.name}`,
      'data не является Table',
      {data},
    );

    data.deleteCaption();

    const tbody = data.firstChild;
    if (!(tbody instanceof HTMLTableSectionElement)) return new ConverterError(
      `SortingConverter.${this.toEditor.name}`,
      'tbody не является TableSection',
      {tbody},
    );

    const body = tbody.lastChild;
    if (!(body instanceof HTMLTableRowElement)) return new ConverterError(
      `SortingConverter.${this.toEditor.name}`,
      'body не является TableRow',
      {body},
    );

    const cells = body.childNodes;
    if (answer.length !== cells.length) return new ConverterError(
      `SortingConverter.${this.toEditor.name}`,
      'Данные и контент имеют разную длину',
      {data},
    );

    for (let i = 0; i < cells.length; i++) {
      const spansData = answer[i];

      const cell = cells[i];
      if (!(cell instanceof HTMLTableCellElement)) return new ConverterError(
        `SortingConverter.${this.toEditor.name}`,
        'cell не является TableCell',
        {cell},
      );

      for (let j = 0; j < spansData.length; j++) {
        const span = document.createElement('span');
        span.innerHTML = spansData[j];
        cell.appendChild(span);
      }
    }

    return data;
  }

  public toModelData(
    editor: Node | ViewNode,
  ): ModelData | Error {
    const container = this.toNodeData(editor);
    if (container.isText() || !container.nameIs('table')) return new ConverterError(
      `SortingConverter.${this.toModelData.name}`,
      'container не является Table',
      {container},
    );

    const tbody = container.getChild(0);
    if (!tbody || tbody.isText() || !tbody.nameIs('tbody')) return new ConverterError(
      `SortingConverter.${this.toModelData.name}`,
      'tbody не является TableSection',
      {tbody},
    );

    const head = tbody.getChild(0);
    if (!head || head.isText() || !head.nameIs('tr')) return new ConverterError(
      `SortingConverter.${this.toModelData.name}`,
      'head не является TableRow',
      {head},
    );

    const groupsNames = this.getNestedTexts(head, 'th');
    if (groupsNames instanceof Error) return groupsNames;

    const modelData: ModelData = groupsNames.map(name => new ColumnData(name));

    const body = tbody.getChild(1);
    if (!body || body.isText() || !body.nameIs('tr')) return new ConverterError(
      `SortingConverter.${this.toModelData.name}`,
      'body не является TableRow',
      {body},
    );

    if (body.childCount !== modelData.length) return new ConverterError(
      `SortingConverter.${this.toModelData.name}`,
      'Длинна данных modelData не совпадает с количеством потомков body',
      {modelData, body},
    );

    let index = 0;
    for (const name of body.children) {
      if (name.isText() || !name.nameIs('td')) return new ConverterError(
        `SortingConverter.${this.toModelData.name}`,
        'name не является TableCell',
        {name},
      );

      const values = this.getNestedTexts(
        name,
        'span',
        ['br'],
      );
      if (values instanceof Error) return values;

      modelData[index].values.push(...values);
      index++;
    }

    return modelData;
  }

  public toModel(
    writer: DowncastWriter,
    data: ModelData,
    isEditor: boolean,
  ): { attributes: ViewElementAttributes, children: ViewNode[] } | Error {
    return {
      attributes: {
        class: [Keyword.answerClass, Keyword.sortingClass].join(' '),
      },
      children: [
        writer.createContainerElement('tbody', {}, [
          writer.createContainerElement('tr', {}, (
            data.map(({name}) => (
              writer.createContainerElement('th', {}, [
                writer.createText(name),
              ])
            ))
          )),
          writer.createContainerElement('tr', {}, (
            data.map(({values}) => (
              writer.createContainerElement('td', {}, (
                values.map(value => (
                  writer.createContainerElement('span', {}, [
                    writer.createText(value),
                  ])
                ))
              ))
            ))
          )),
        ]),
      ],
    };
  }
})();