import {ColumnData} from './Form';
import {AnswerModelName} from '#global';
import {CommandBase} from '#includes/content/editor/plugins/utils';

export class Command extends CommandBase {
  public override execute(data: ColumnData[]) {
    if (data.length === 0) return;

    const model = this.editor.model;

    model.change(writer => {
      const element = writer.createElement(AnswerModelName.sortingModel, {
        'data': data,
      });

      const {end: positionAfter} = model.insertObject(
        element,
        null,
        null,
        {setSelection: 'on'},
      );

      writer.setSelection(positionAfter);
    });
  }

  public override refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const position = selection.getFirstPosition();
    const allowedIn = position && model.schema.findAllowedParent(
      position,
      AnswerModelName.sortingModel,
    );

    this.isEnabled = allowedIn !== null;
  }
}