import {css} from '@emotion/react';
import {ContainerStyle} from '#includes/ContainerStyle';
import {GeneralStyle, ViewerStyle} from '#includes/content';
import {plugins} from '#includes/content/styles/viewer/plugins';
import {palette} from '#config';

export const styles = {
  base: css(
    GeneralStyle,
    ViewerStyle,
    css`
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    `,
  ),
  book: css`
    flex-grow: 1;
  `,
  global: css(
    css`
      .draggable {
        &:not(.draggable-empty):not(.draggable-fixed) {
          ${ContainerStyle.Clickable};
          text-align: center;
          color: ${palette.black};
          cursor: grab;
        }

        &-empty {
          ${ContainerStyle.Dashed};

          display: inline-block;
          width: 100%;
          height: 100%;
          pointer-events: none;

          &::after {
            content: "!";
            user-select: none;
            color: transparent;
          }
        }

        &-parent > .draggable-empty {
          width: max-content;
          height: max-content;
        }

        &-panel {
          position: absolute;
          pointer-events: none;

          & > * {
            background-color: #FFF !important;
            position: absolute !important;
            transform: translate(-50%, -50%) !important;
          }
        }
      }
    `,
    plugins,
  ),
};